import { getBgColorOrDefault } from "../../../features/utils";
import { NotesRecommendationTextArea } from "./componentes/NotesRecommendationTextArea";

export default function Page22Perspective({
  pageNumber,
  sectionNumber,
  pageTitle,
  level,
  levelColor,
  percentage = 0,
  summary,
  perspectiveExpertNotes,
}) {
  return (
    <div
      className="p-5 font-diodrum-arabic rtl relative "
      style={{
        pageBreakInside: "avoid",
        height: "296.86mm",
      }}
    >
      <div className="mt-4 mr-2">
        <div className=" flex items-center gap-1 text-lg mt-7 font-bold mb-[2rem]">
          <span className="text-[#7C32C9] ">{sectionNumber}</span>
          <span className="text-[#3F3578]">{pageTitle}</span>
        </div>

        <div className="mt-2">
          <div className="p-2.5  flex items-center justify-center gap-4 border-2 border-[#7C32C9] rounded-lg mb-[2rem]">
            <p className="text-right text-xs text-[#3F3578] break-words  leading-7 font-medium">
              {summary}
            </p>
          </div>

          <div className="w-full text-right mt-5 mb-7 ">
            <div className="flex flex-col items-center justify-center gap-3 relative">
              <div className="flex items-center justify-center text-center w-[45%] pb-4 text-red-700 font-bold border-2 border-[#382F71] rounded-lg">
                <span className="py-5 text-base">
                  {Number(percentage)?.toFixed(2)}%
                </span>
              </div>
              <div
                className="absolute bottom-0 translate-y-1/2 flex items-center justify-center text-center w-[230px]  border-4 rounded-2xl"
                style={{
                  backgroundColor: getBgColorOrDefault(levelColor),
                  borderColor: getBgColorOrDefault(levelColor),
                }}
              >
                <span className="text-sm text-white py-2">{level}</span>
              </div>
            </div>
          </div>

          <div className="mt-[5rem]">
            <NotesRecommendationTextArea expertNotes={perspectiveExpertNotes} />
          </div>
        </div>
      </div>
    </div>
  );
}
