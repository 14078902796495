import React, { useState } from 'react';
import CountryFlag from 'react-country-flag';
import { DropdownMultiSelect } from '../../../../../../../components';
import { countries } from '../constants';

const ArabicCountryDropdown = ({
    inputs,
    setInputs
}) => {
    const defaultCountry = inputs?.country?.length ? countries.find(el => el.value === inputs?.country) : countries[0];
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

    const handleSelectChange = (val) => {
        if (val) {
            setSelectedCountry(val);
            setInputs({
                ...inputs,
                country: val?.value
            })
        }
    };

    return (
        <div style={{ textAlign: 'right', fontFamily: 'DiodrumArabic' }}>
            <DropdownMultiSelect
                label={"اختر الدولة"}
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedCountry}
                handleChange={handleSelectChange}
                options={countries || []}
                isMultiple={false}
                isRequired={false}
                placeholder={"اختر دولة معينة"}
            />

            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <CountryFlag
                    countryCode={selectedCountry.value}
                    svg
                    style={{ width: '32px', height: '24px', marginRight: '10px' }}
                    aria-label={selectedCountry.label}
                />
                <span>{selectedCountry.label}</span>
            </div>
        </div>
    );
};

export default ArabicCountryDropdown;
