import classNames from "classnames";
import { Spinner } from "flowbite-react";
import { t } from "i18next";
import { twMerge } from 'tailwind-merge';


const AppButton = ({
  variant = "primary",
  fullwidth,
  children,
  onClick,
  className,
  loading,
  loadingLabel = "جارى الحفظ",
  disabled = false,
  type = "button",
  size,
  form,
}) => {
  const btnClasses = twMerge(classNames(
    className,
    {
      "opacity-50": loading,
      "bg-green hover:bg-green_hover transition-all": variant === "secondary",
      "bg-danger": variant === "danger",
      "bg-purple": variant === "purple",
      "block w-full": fullwidth,
      "cursor-pointer": !disabled,
      "cursor-not-allowed opacity-50": disabled,
      "bg-blue hover:bg-blue_hover transition-all": variant === "primary",
    },
    "text-white py-2 pb-4  px-4 min-w-[140px] pt-1 mt-0 rounded-md flex items-center justify-center",
  ), size === "small" && "text-white pt-0 pb-3 min-w-[80px] mt-0 rounded-md flex items-center justify-center");

  return (
    <button
      type={type}
      disabled={loading || disabled}
      className={btnClasses}
      onClick={onClick}
      form={form}
    >
      {loading ? (
        <div className="flex flex-row  justify-center items-center">
          <Spinner aria-label="Info spinner example" color="info" />
          <span className="pl-3 pr-3 text-sm text-white">{loadingLabel}</span>
        </div>
      ) : (
        <div
          className={classNames(
            "mt-[5px] mb-[-5px]",
            size === "small" && "mt-[0] mb-[-2px]"
          )}
        >
          {children}
        </div>
      )}
    </button>
  );
};

export default AppButton;
