import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import environment from "../../../../../../services/environment";
import { axiosGetRequest, axiosPostRequest } from "../../../../../../services/Request";
import { toast } from "react-toastify";
import AppRadio from "../../../../../../components/form/appRadio";
import NewSwitch from "../../../../../../components/NewSwitch";
import NewTable from "../../../../../../components/NewTable";
import AppButton from "../../../../../../components/buttons/appButton";
import { surveyId } from "../../../../../../helpers/isPermission";
import AppLoader from "../../../../../../components/loader";
import { ifNotDefinedReturnZero } from "../../../../../../helpers/utils";

const FinalReportSettingPage = () => {

  const [inputs, setInputs] = useState({
    reportTypeValue: 3
  });

  const [keyword, setKeyword] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "5", value: 5 });

  const {
    data: AllAgenciesSettings,
    isLoading: isGettingPaginatedAgencies,
    isFetching,
    refetch: refetchPaginatedAgencies,
  } = useQuery({
    queryKey: ["GetAllAgenciesSettings", page, maxResult.value, keyword],
    queryFn: () =>
      axiosGetRequest(environment.GetAllAgenciesSettings, {
        surveyId: surveyId(),
        skipCount: page * maxResult.value,
        maxResultCount: maxResult.value,
        keyword: keyword,
      }),
    select: (res) => res?.result,
  });

  const paginatedAgencies = AllAgenciesSettings?.items;
  const totalCountOfAllAgencies = ifNotDefinedReturnZero(AllAgenciesSettings?.totalCount);
  const totalPages = Math.ceil(AllAgenciesSettings?.totalCount / maxResult?.value);


  useEffect(() => {
    setFilteredData(paginatedAgencies);

    if (selectAll) {
      setSelectedRows(paginatedAgencies?.map((row) => row.id));
    }

    paginatedAgencies?.length && paginatedAgencies[0]?.reportType && setInputs({
      reportTypeValue: Number(paginatedAgencies[0]?.reportType)
    })
  }, [paginatedAgencies]);

  const handleFilterData = (value) => {
    setKeyword(value);
    setPage(0);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(paginatedAgencies?.map((row) => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = (row) => {
    let slectedRows = [];
    if (selectedRows?.includes(row.id)) {
      slectedRows = selectedRows.filter((id) => id !== row.id);
      setSelectedRows(slectedRows);
    } else {
      slectedRows = [...selectedRows, row.id];
      setSelectedRows(slectedRows);
    }

    setSelectAll(slectedRows?.length === totalCountOfAllAgencies)
  };

  const handleAddReportToAgencies = async (
    showFinalReport = true,
    updateOnlySelectedAgencies = true
  ) => {
    const res = await axiosPostRequest(
      environment.SendReportToAgency,
      {
        sendReportToAgency: showFinalReport,
        isSelectAll: !!selectAll,
        agencyIds: selectedRows,
        surveyId: surveyId(),
      }
    );

    if (res?.success) {
      toast.success(
        showFinalReport
          ? "تم إضافة التقرير للجهات بنجاح"
          : "تم إزالة التقرير من الجهات بنجاح"
      );
      refetchPaginatedAgencies();
      // setSelectAll(false)
    } else {
      toast.error("حدث خطاء");
    }
  };

  const handleAddReportToAgency = async (agencyId, showFinalReport = true) => {
    const res = await axiosPostRequest(
      environment.SendReportToAgency,
      {
        sendReportToAgency: showFinalReport,
        agencyIds: [agencyId],
        surveyId: surveyId(),
      }
    );

    if (res?.success) {
      toast.success(
        showFinalReport
          ? "تم إضافة التقرير للجهة بنجاح"
          : "تم حذف التقرير من الجهة بنجاح"
      );
      refetchPaginatedAgencies();
    } else {
      toast.error("حدث خطاء");
    }
  };

  const handleSetReportAutomatic = async (agencyId, isAutomaticReport = true) => {
    const res = await axiosPostRequest(
      environment.SetReportAutomatic,
      {
        isAutomaticReport,
        surveyId: surveyId(),
        agencyIds: [agencyId]
      }
    );

    if (res?.success) {
      toast.success(
        "تم تعديل اولوية التقرير للجهة بنجاح"
      );
      refetchPaginatedAgencies();
    } else {
      toast.error("حدث خطاء");
    }
  };

  const downloadFile = async (file) => {
    const queryParams = new URLSearchParams();

    queryParams.append("id", file.id);

    const resp = await axiosPostRequest(
      environment.downloadFile,
      null,
      queryParams,
      true,
      true,
      true
    );

    if (resp) {
      const blob = new Blob([resp], { type: "application/octet-stream" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = [
    {
      name: (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        </div>
      ),
      accessorKey: "select",
      selector: (row) => row.select,
      center: true,
      width: '50px',
      cell: (row) => (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectedRows?.includes(row.id)}
            onChange={() => handleRowSelection(row)}
          />
        </div>
      ),
    },
    {
      name: "اسم الجهة",
      selector: (row) => row.nameInArabic,
      header: "nameInArabic",
      accessorKey: "nameInArabic",
      sortable: true,
      cell: (row) => row.nameInArabic || row.name || "-",
    },
    {
      name: "كود الجهة",
      selector: (row) => row.agencyCode,
      header: "agencyCode",
      accessorKey: "agencyCode",
      sortable: true,
      center: true,
      width: '100px',
      cell: (row) => row.agencyCode || "-",
    },
    {
      name: "نوع الجهة",
      selector: (row) => row.isConfidentialAgency,
      header: "isConfidentialAgency",
      accessorKey: "isConfidentialAgency",
      sortable: true,
      center: true,
      width: '120px',
      cell: (row) =>
        (row.isConfidentialAgency ? "جهة سرية" : "جهة غير سرية") || "-",
    },
    {
      name: "نشر التقرير النهائي",
      selector: (row) => row.sendReportToAgency,
      header: "sendReportToAgency",
      accessorKey: "sendReportToAgency",
      center: true,
      width: '180px',
      cell: (row) => (
        <div>
          <NewSwitch
            value={row.sendReportToAgency}
            onsubmit={() => handleAddReportToAgency(row.id, true)}
            onFalseClick={() => handleAddReportToAgency(row.id, false)}
            setValue={() => { }}
          />
        </div>
      ),
    },
    {
      name: "مصدر التقرير النهائي",
      selector: (row) => row.sendReportToAgency,
      header: "sendReportToAgency",
      accessorKey: "sendReportToAgency",
      center: true,
      width: '180px',
      cell: (row) => (
        <div>
          <NewSwitch
            value={row.isAutomaticReport}
            onsubmit={() => handleSetReportAutomatic(row.id, true)}
            onFalseClick={() => handleSetReportAutomatic(row.id, false)}
            setValue={() => { }}
            falseText={'يدوي'}
            trueText={'اوتوماتك'}
          />
        </div>
      ),
    },
    {
      name: "التقرير اليدوي",
      selector: (row) => row.attachmentId,
      accessorKey: "attachmentId",
      footer: (props) => props.column.id,
      width: '130px',
      cell: (info) => (
        <div>
          <span
            className="underline cursor-pointer"
            title={info?.attachmentTitle || ''}
            onClick={() =>
              info?.attachmentId &&
              downloadFile({
                id: info?.attachmentId,
                name: info?.attachmentTitle,
              })
            }
          >
            {info?.attachmentTitle?.slice(0, 30)}
          </span>
        </div>
      ),
    },

  ];

  function handleRadioBtnChange(e) {
    setInputs({ ...inputs, reportTypeValue: Number(e.target.value) });

    handleSubmitSettingOption(Number(e.target.value))
  }

  const handleSubmitSettingOption = async (reportTypeValue) => {
    const res = await axiosPostRequest(
      environment.SetReportType,
      {
        id: surveyId(),
        reportType: reportTypeValue
      }
    );
    if (res?.success) {
      toast.success("تم الحفظ بنجاح");
    }
  }

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setPage(newOffset);

    if (selectAll) {
      setSelectedRows(paginatedAgencies?.map((row) => row.id));
    }
    else {
      setSelectedRows([]);
    }
  };

  const isLoadingPage = (isGettingPaginatedAgencies || isFetching);
  return (
    <div className="bg-white rounded-[30px] m-5 relative">
      <div className={`flex justify-center items-center min-h-[400px] ${!isLoadingPage ? 'hidden' : 'visible'}`}>
        <AppLoader />
      </div>

      <div className={`${isLoadingPage ? 'opacity-0' : 'opacity-100'}`}>
        <div className="flex flex-col gap-3">
          <h2> برجاء تحديد مستوى عرض النتائج في النظام و التقرير النهائي </h2>
          <div className="flex flex-col mt-3">
            <AppRadio
              key={"reportTypeValue"}
              name={"reportTypeValue"}
              id={"1"}
              value={1}
              checked={inputs?.reportTypeValue === 1}
              label={"على مستوى القدرات"}
              onChange={handleRadioBtnChange}
            />

            <AppRadio
              key={"reportTypeValue"}
              name={"reportTypeValue"}
              id={"2"}
              value={2}
              checked={inputs?.reportTypeValue === 2}
              label={"على مستوى العناصر"}
              onChange={handleRadioBtnChange}
            />

            <AppRadio
              key={"reportTypeValue"}
              name={"reportTypeValue"}
              id={"3"}
              value={3}
              checked={inputs?.reportTypeValue === 3}
              label={"على مستوى المعايير"}
              onChange={handleRadioBtnChange}
            />
          </div>

        </div>

        <div className="relative w-full py-3">
          {selectedRows?.length > 0 && (
            <div className="absolute top-3 left-0 flex gap-5">
              <AppButton onClick={() => handleAddReportToAgencies(true)}>
                إضافة التقرير للجهات المحددة ({selectAll ? totalCountOfAllAgencies : ifNotDefinedReturnZero(selectedRows?.length)})
              </AppButton>
              <AppButton
                onClick={() => handleAddReportToAgencies(false)}
                variant="danger"
              >
                إزالة التقرير للجهات المحددة ({selectAll ? totalCountOfAllAgencies : ifNotDefinedReturnZero(selectedRows?.length)})
              </AppButton>
            </div>
          )}
          <NewTable
            columns={columns}
            data={filteredData}
            pagination={true}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={page}
            loading={isGettingPaginatedAgencies}
            withMaxResultChange={true}
            maxResultValue={maxResult}
            setMaxResultValue={(val) => {
              setMaxResult(val);
              setPage(0);
            }}
            withExcel={false}
            withSearch
            onSearch={handleFilterData}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalReportSettingPage;