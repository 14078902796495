export default function Page35({ page = "4", styles, SuggestedActivities }) {
  const pageStyles = {
    page: {
      backgroundColor: "#fff",
      padding: "50px",
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    container: {
      // marginTop: "80px",
      padding: '20px',
      marginRight: "10px",
    },
    header: {
      textAlign: "right",
      display: "flex",
      gap: "4px",
      fontSize: "20px",
    },
    headerTextNum1: {
      color: "#30B880",
    },
    headerTextNum2: {
      color: "#7C32C9",
    },
    headerText: {
      color: "#3F3578",
    },
    subjectText: {
      color: "#2B206A",
      width: "100%",
      padding: "20px 0",
      textAlign: "right",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      flexWrap: "wrap",
    },
  };

  const pageClasses = {
    sectionTitle:
      "text-[#2A2069] text-3xl gap-2  flex  items-center font-bold mx-3 pt-4 mb-12",
  };

  return (
    <section>
      <div style={pageStyles.container}>
        {/* Header Section */}
        <div style={{ ...styles.mainSectionTitle, paddingTop: "1.25rem" }}>
          <span style={styles.sectionTitleNumber}>6.1</span>
          <span>الخطط المقترحة لتطوير القدرات لدى الجهة</span>
        </div>
        {/* section content */}
        {/* ----------------------------------------------- */}
        {SuggestedActivities?.length ?
          SuggestedActivities?.map((item, index) => (
            <ContentWrapper data={item} />
          )) : <div></div>
        }
        {/* ----------------------------------------------- */}
      </div>
    </section>
  );
}

function ContentWrapper({ data }) {
  return (
    <>
      <div
        className="mx-4 pt-2 pb-2"
        style={{
          pageBreakInside: "avoid",
        }}
      >
        <h2 className="bg-[#555]/20  px-2 py-2 mt-5 mb-2 text-lg font-semibold rounded-lg text-[#7C32C9]">
          {data?.perspectiveTitle || "البحث"}
        </h2>
        <span className="text-[14px] mt-3 inline-block">الأنشطة المقترحة</span>
        {/* content box  */}
        <div className=" border-[#555]/30 my-3 p-3 border-2 min-h-[90px]">
          <p className=" whitespace-pre-wrap">{data?.note}</p>
        </div>
      </div>
    </>
  );
}
