import React, { useContext, useEffect, useState } from "react";
// import Card from "../Card";
import NewCard from "../../../components/dashboard/NewCard";
import NewTable from "../../../components/NewTable";
import environment from "../../../services/environment";
import { StageContext } from "../../../contexts/stageContext";
import { formateDate } from "../../../helpers/dateFormat";
import { Badge } from "flowbite-react";
import { axiosGetRequest } from "../../../services/Request";

const StanderedCard = ({ title }) => {
  const { currentStage, stageForDashboard } = useContext(StageContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const [answeredStandards, setAnsweredStandards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState({ label: "5", value: 5 });

  const agencyId = localStorage.getItem("agencyId");

  const getAnsweredStandards = async (page, perPage) => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getAnsweredStandardForAgency, {
      surveyStageId:
        stageForDashboard || localStorage?.getItem("stageForDashboard") || 0,
      agencyId: agencyId,
      MaxResultCount: perPage,
      SkipCount: page * perPage,
    });

    if (res?.success) {
      setAnsweredStandards(res?.result?.items);
      setTotalPages(res?.result?.totalCount / perPage);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAnsweredStandards(0, 5);
  }, [stageForDashboard]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAnsweredStandards(newOffset, perPage.value);
  };

  const columns = [
    {
      name: "رقم المعيار",
      selector: (row) => row.standardCode,
      header: "رقم المعيار",
      accessorKey: "standardCode",
      footer: (props) => props.column.standardCode,
    },
    {
      name: "اسم المعيار",
      selector: (row) => row.standardTitle,
      header: "اسم المعيار",
      accessorKey: "standardTitle",
      footer: (props) => props.column.standardTitle,
    },

    {
      name: "الأسئلة المطلوبة",
      selector: (row) => row.requirementsCount,
      header: "الأسئلة المطلوبة",
      accessorKey: "requirementsCount",
      footer: (props) => props.column.requirementsCount,
      center: true,
    },
    {
      name: "المستندات المطلوبة",
      selector: (row) => row.proofsCount,
      header: "المستندات المطلوبة",
      accessorKey: "proofsCount",
      footer: (props) => props.column.proofsCount,
      center: true,
    },
    {
      name: "تاريخ الإجابة",
      selector: (row) => row.answerDate,
      header: "تاريخ الإجابة",
      accessorKey: "answerDate",
      footer: (props) => props.column.answerDate,
      center: true,
      cell: (info) => formateDate(info.answerDate),
    },
    {
      name: "درجة الجهة ملخصة",
      selector: (row) => row.commitmentLevelTitle,
      header: "درجة الجهة ملخصة",
      accessorKey: "commitmentLevelTitle",
      footer: (props) => props.column.commitmentLevelTitle,
      center: true,
      cell: (row) => (
        <Badge
          style={{
            backgroundColor: row.commitmentLevelColor
              ? row.commitmentLevelColor
              : "#575757",
          }}
          className="text-white font-medium pb-3 px-5 rounded-xl"
          size="md"
        >
          {row.donotApply ? "لا ينطبق" : row.commitmentLevelTitle}
        </Badge>
      ),
    },
  ];

  return (
    <NewCard title={title || "نسبة الانجاز"}>
      <NewTable
        loading={loading}
        columns={columns}
        data={answeredStandards || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default StanderedCard;
