import { ErrorMessage } from "formik";
import { InputLabel } from "../../../../components";
import { NewForm } from "../../../../components/form/NewForm";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";

function EditAgencySectorModal({
  innerRef,
  initialValues,
  validate,
  inputs,
  onChange,
  onSubmit,
  allAgenciesOptions,
  sectorAgenceisIds,
  setAgenceisIds,
  handleAgenciesChange,
}) {

  // -----------------------------------------------------
  return (
    <>
      <NewForm
        initialValues={initialValues}
        validate={validate}
        innerRef={innerRef}
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-3">
          <div className="space-y-1">
            <InputLabel
              label={"اسم القطاع"}
              name={"nameInArabic"}
              type={"text"}
              value={inputs.nameInArabic}
              onChange={onChange}
            />

            <ErrorMessage
              className="text-red-700"
              name="nameInArabic"
              component="div"
            />
          </div>

          <div className="space-y-1">
            <NewDropdownMultiSelect
              isClearable={true}
              withoutLabel={false}
              isSearchable={true}
              value={sectorAgenceisIds}
              handleChange={handleAgenciesChange}
              placeholder="ادخل اسم الجهات"
              label="الجهات الخاصة بهذا القطاع"
              options={allAgenciesOptions || []}
              isMultiple={true}
            />

            <ErrorMessage
              className="text-red-700"
              name="agencies"
              component="div"
            />
          </div>
        </div>
      </NewForm>

{/* =========================================================== */}
    </>
  );
}

export default EditAgencySectorModal;
