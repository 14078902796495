export default function Page37() {
  return (
    <div className="relative text-center w-full h-full bg-white">
      {/* <!-- <img src="Slide37.png" alt="Background" className="absolute w-full h-full object-cover" /> --> */}

      <div className="absolute w-full top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className=" ">
          <div className="flex flex-col items-center ">
            <h1 className="text-[#2A2069] text-[2rem]  flex  items-center font-bold">
              <span className="text-[#1DC183] mr-2"> 7. </span> خدمات داعمة
              مقدمة من هيئة الحكومة الرقمية
            </h1>
          </div>

          <div className="text-2xl font-medium p-3 mb-10 mt-20">
            <p>
              تقدم هيئة الحكومة الرقمية مجموعة شاملة من الخدمات، تهدف إلى دعم
              وتسهيل تبني التقنيات الناشئة وتطبيقات الحكومة الرقمية
            </p>
          </div>

          <div className="text-center w-full font-semibold text-2xl text-[#7C32C9]">
            <p>خدمات هيئة الحكومة الرقمية</p>
          </div>
        </div>

        <div className=" mt-8">
          <a
            href="https://dga.gov.sa/"
            // target="_blank"
            className="bg-[#7C32C9] rounded-lg no-underline border-b-4 border-black text-white text-center text-2xl font-bold  px-12 my-4 py-4 pt-2 inline-block"
          >
            للإطلاع
          </a>
        </div>
      </div>
    </div>
  );
}
