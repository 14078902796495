import CheckListIconPDF from "../../../features/agencyAnswerReport/assets/svgs/CheckListIconPDF";
import SearchIconPDF from "../../../features/agencyAnswerReport/assets/svgs/SearchIconPDF";
import { getBgColorOrDefault } from "../../../features/utils";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";

export default function Page21({
  agencyTitle,
  surveyPercentagesAvg,
  surveyCommitmentLevelTitle,
  surveyPercentageCommitmentLevelColor,
  surveyReports,
  agencyLogoUrl,
  page = "21",
}) {
  const surveyExpertNotes = surveyReports?.surveyExpertNotes || [];
  const Err = `نتائج ${agencyTitle} حسب القدرات الرئيسية`;

  const cardWidth = 88 / surveyReports?.perspectives?.length + "%";
  const style = {
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    body: {
      paddingTop: 80,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    heading: {
      marginBottom: "50px",
    },
    title: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: 20,
      textAlign: "right",
      color: "#21C284",
    },
    titleSpan: {
      color: "#C00000",
    },
    main: {
      width: "100%",
      position: "relative",
      margin: "20px 0",
      textAlign: "center",
      fontSize: "11px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    logo: {
      position: "absolute",
      right: 0,
      top: -10,
      backgroundColor: "white",
      width: "110px",
      height: "110px",
      borderRadius: "50%",
      border: "2px solid purple", // Circle border
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textLogo: {
      backgroundColor: "white",
      color: "#C00000",
      paddingHorizontal: 5,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    textBoadyBorder: {
      width: 'calc(100% - 85px)',
      minHeight: "90px",
      borderRadius: "15px",
      border: "2px solid purple",
      borderRightColor: "transparent",
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      alignSelf: 'end',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textBody: {
      backgroundColor: "transparent",
      color: "#000",
      fontSize: 16,
      fontWeight: "light",
      textAlign: "center",
      padding: 10,
      paddingRight: 50,
    },
    section: {
      margin: "40px 0",
    },
    sectionText: {
      color: "#382F71",
      fontSize: 16,
      marginBottom: 5,
    },
    cards: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
      gap: 3,
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    card: {
      fontSize: "12px",
      width: cardWidth,
      marginTop: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
    },
    cardTitleText: {
      color: "#382F71",
      fontSize: 16,
    },
    cardSubjectText: {
      color: "#C00000",
      fontSize: 16,
    },
    cardTextContainer: {
      color: "#C00000",
      width: "83%",
      borderRadius: "10px",
      paddingBottom: "5px",
      backgroundColor: "purple",
      border: "2px solid purple",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardLevel: {
      lineHeight: 0.9,
      padding: "5px",
      // backgroundColor: "#FFFF00",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    resultSection: {
      width: "100%",
      textAlign: "right",
      marginTop: "50px",
    },
    resultTitle: {
      color: "#1CC182",
      fontSize: 16,
      textAlign: "right",
      marginBottom: 10,
    },
    resultBody: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#C00000",
      fontSize: 16,
      marginTop: "20px",
    },
    resultBodyContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "45%",
      border: "2px solid #382F71",
      borderRadius: "15px",
      padding: "10px",
    },
    resultData: {
      padding: "15px 0",
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    resultLevels: {
      position: "absolute",
      top: 60,
      border: "3px solid #382F71",
      backgroundColor: "#382F71",
      borderRadius: "30px",
      width: "42%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    resultTextLevel: {
      fontSize: 14,
      color: "#fff",
      lineHeight: 1,
      padding: "10px 0",
    },
    NotesTextAreaContainer: {
      position: "relative",
      width: "100%",
      textAlign: "right",
      color: "#3F3578",
      margin: "30px 0",
      fontSize: 16,
      fontWeight: "medium",
    },
    NotesTextAreaTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 10,
      borderBottom: "1px solid #03ABAF",
      paddingBottom: "10px",
      marginBottom: "5px",
    },
    innerFace: {
      height: 170,
      borderRadius: "30px",
      backgroundColor: "#F1F1F1",
      padding: "15px",
      top: 30,
      left: 5.5,
      zIndex: 1,
    },
    outerFace: {
      width: "100%",
      minHeight: 180,
      paddingHorizontal: 50,
      border: "1px solid #fefcfc",
      borderRadius: "10%",
      borderTopColor: "#03ABAF",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  };

  return (
    <div style={style.page}>
      <div style={style.heading}>
        <div style={style.title}>
          <span>أبرز المشاهدات للوضع الحالي في</span>
          <span> {agencyTitle} </span>
        </div>
      </div>
      <div style={style.main}>
        <div style={style.logo}>
          {agencyLogoUrl && (
            <img
              style={{ borderRadius: "100%", width: "100%", height: "100%" }}
              src={agencyLogoUrl?.replace("application/octet-stream", "image/png") ?? ""}
              alt=""
            />
          )}
        </div>
        <div style={style.textBoadyBorder}>
          <span style={style.textBody}>
            تمكنت {agencyTitle} من تحقيق مستوى {surveyCommitmentLevelTitle} في
            تقييم مؤشر جاهزية تبني التقنيات الناشئة
          </span>
        </div>
      </div>

      {/* القدرات */}
      <div>
        <div style={style.section}>
          <span style={style.sectionText}>{Err}</span>
        </div>
        <div style={style.cards}>
          {surveyReports?.perspectives?.map((card, indx) => (
            <div key={card.perspectiveTitle} style={style.card}>
              <span style={style.cardTitleText}>{card.perspectiveTitle}</span>
              <span style={style.cardSubjectText}>
                {Number(
                  ifNotDefinedReturnZero(card.userPercentagesAvg)
                )?.toFixed(2)}
                %
              </span>
              <div
                style={{
                  ...style.cardTextContainer,
                  backgroundColor: getBgColorOrDefault(card?.commitmentLevelColor),
                  borderColor: getBgColorOrDefault(card?.commitmentLevelColor),
                }}
              >
                <span style={style.cardLevel}>{card.commitmentLevelTitle}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* النتيجة العامة */}
      <div style={style.resultSection}>
        <div style={style.resultTitle}>
          <span>النتيجة العامة {agencyTitle}</span>
        </div>
        <div style={style.resultBody}>
          <div style={style.resultBodyContainer}>
            <span style={style.resultData}>
              {Number(ifNotDefinedReturnZero(surveyPercentagesAvg))?.toFixed(2)}
              %{" "}
            </span>
          </div>
          <div
            style={{
              ...style.resultLevels,
              backgroundColor: getBgColorOrDefault(surveyPercentageCommitmentLevelColor),
              borderColor: getBgColorOrDefault(surveyPercentageCommitmentLevelColor),
            }}
          >
            <span style={style.resultTextLevel}>
              {surveyCommitmentLevelTitle}
            </span>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "60px" }}>
        {/* الوضع الراهن و التوصيات  */}
        <div style={style?.NotesTextAreaContainer}>
          <div style={style?.NotesTextAreaTitle}>
            <span style={{ position: "relative" }}>الوضع الراهن</span>
            <div style={{ position: "relative" }}>
              <svg width={16} height={16} viewBox="0 0 512 512">
                <path
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6 .1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  fill="#3F3678"
                />
              </svg>
            </div>
          </div>
          {/* الوضع الراهن و التوصيات  */}
          <div style={style?.innerFace}>
            {surveyExpertNotes.map((item) => {
              return <span> {item.note} -</span>;
            })}
          </div>
        </div>
        <div style={style?.NotesTextAreaContainer}>
          <div style={style?.NotesTextAreaTitle}>
            <span style={{ position: "relative" }}>أبرز التوصيات</span>
            <div style={{ position: "relative" }}>
              <svg width={20} height={20} viewBox="0 0 256 256">
                <path d="M224,128a8,8,0,0,1-8,8H128a8,8,0,0,1,0-16h88A8,8,0,0,1,224,128ZM128,72h88a8,8,0,0,0,0-16H128a8,8,0,0,0,0,16Zm88,112H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16ZM82.34,42.34,56,68.69,45.66,58.34A8,8,0,0,0,34.34,69.66l16,16a8,8,0,0,0,11.32,0l32-32A8,8,0,0,0,82.34,42.34Zm0,64L56,132.69,45.66,122.34a8,8,0,0,0-11.32,11.32l16,16a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Zm0,64L56,196.69,45.66,186.34a8,8,0,0,0-11.32,11.32l16,16a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path>
              </svg>
            </div>
          </div>
          <div style={style?.innerFace}>
            {surveyExpertNotes.map((item) => {
              return <span> {item.recommendation} -</span>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
