import { Page, Image, StyleSheet, View } from "@react-pdf/renderer";
import Slide9 from "../assets/images/Slide9.jpg";
import { Footer } from "../components/Footer";
export default function Page9({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Image src={Slide9} style={styles.imagePage} />

        <Footer />
      </View>
    </Page>
  );
}
