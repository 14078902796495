
import { surveyId } from "../../helpers/isPermission";
import { ifNotDefinedReturnZero } from "../../helpers/utils";

export const A4Width = 595.28;
export const A4Height = 841.89;


export const PageData = (surveyReports, surveyCommitmentLevelTitle, agencyTitle) => {
  const perspectiveData = [];
  const data = [];
  let startPageNumber = 21;


  surveyReports?.perspectives?.map((perspect, perspectIdx) => {

    const perspectiveNum = perspectIdx + 1;
    startPageNumber++;

    const interlocListTitle = [...perspect?.interlocutors]?.map(item => `"${item?.interlocutorTitle}"`)?.join(' و ');

    perspectiveData.push({
      pageNumber: startPageNumber,
      sectionNumber: "5.1",
      pageTitle: `القدرة : ${perspectiveNum}. ${perspect.perspectiveTitle}`,
      agencyTitle: agencyTitle,
      level: perspect.commitmentLevelTitle,
      commitmentLevelColor: perspect.commitmentLevelColor,
      percentage: ifNotDefinedReturnZero(perspect.userPercentagesAvg)?.toFixed(2),
      property: perspect.perspectiveTitle,
      summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${surveyCommitmentLevelTitle}  في قدرة "${perspect.perspectiveTitle}" ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "${perspect.perspectiveTitle}" تمركزت حول عناصر فرعية هم: ${interlocListTitle}, ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
      perspectiveExpertNotes: perspect.perspectiveExpertNotes,

      interlocutors: perspect?.interlocutors.map((interloc, interlocIdx) => {
        const interlocutorNum = interlocIdx + 1;
        startPageNumber++;

        return {
          pageNumber: startPageNumber,
          sectionNumber: "5.1",
          pageTitle: `القدرة : ${perspectiveNum}. ${perspect.perspectiveTitle}`,
          agencyTitle: agencyTitle,
          level: interloc.commitmentLevelTitle,
          commitmentLevelColor: interloc.commitmentLevelColor,
          percentage: ifNotDefinedReturnZero(interloc.userPercentagesAvg)?.toFixed(2),
          property: perspect.perspectiveTitle,
          summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${surveyCommitmentLevelTitle}  في قدرة "${perspect.perspectiveTitle}" ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "${perspect.perspectiveTitle}" تمركزت حول عناصر فرعية هم: ${interlocListTitle}, ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
          elementsCode: `${perspectiveNum}.${interlocutorNum} `,
          elementsTitle: interloc.interlocutorTitle,
          elementsSubject: interloc?.interlocutorDescription ||
            "يُعنى هذا العنصر بتكثيف الاستكشاف والتحليل لفرص التقنيات الناشئة من خلال توثيق مجموعة واسعة من الفوائد والمخاطر المحتملة، ويشتمل على المعايير الفرعية التالية​",
          standardData: interloc.standards,
          interlocutorExpertNotes: interloc.interlocutorExpertNotes,
        }
      })
    });
  })

  return perspectiveData;
}

export const evaluationResult = (data) => {

  const YEARS = ['2023', '2024', '2025'];

  const isShow2023 = !!data?.surveyEvaluation?.surveyDegree2023;
  const isShow2024 = !!data?.surveyEvaluation?.surveyDegree2024;
  // const isShow2025 = !!data?.surveyEvaluation?.surveyDegree2025;

  return {
    ...data,
    isShow2023,
    isShow2024,
    totalEvaluation: [
      {
        title: YEARS[0],
        value: ifNotDefinedReturnZero(data?.surveyEvaluation?.surveyDegree2023)?.toFixed(2),
        isShow: isShow2023,
      },
      {
        title: YEARS[1],
        value: ifNotDefinedReturnZero(data?.surveyEvaluation?.surveyDegree2024)?.toFixed(2),
        isShow: isShow2024,
      },
      {
        title: YEARS[2],
        value: ifNotDefinedReturnZero(data?.surveyEvaluation?.surveyDegree2025)?.toFixed(2),
        isShow: true,
      },
    ],
    perspectiveCategoryEvaluation: data?.perspectiveCategoryEvaluation
  }
}

