import { useEffect, useState } from "react";
import { Button, Modal, Select } from "flowbite-react";
import AppButton from "../../../../components/buttons/appButton";
import NewTable from "../../../../components/NewTable";
import { FModal } from "../../../../components";
import { axiosPostRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const ApproveSectorWithAgencies = ({
    agencies,
    open,
    setOpen,
    handleSubmit,
}) => {

    const queryClient = useQueryClient();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [alreadyAddedAgencies, setAlreadyAddedAgencies] = useState([]);
    const [loadingAll, setLoadingAll] = useState(false);


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedRows(agencies?.map((row) => row.agencyId));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (row) => {
        let slectedRows = [];
        if (selectedRows?.includes(row.agencyId)) {
            slectedRows = selectedRows.filter((agencyId) => agencyId !== row.agencyId);
            setSelectedRows(slectedRows);
        } else {
            slectedRows = [...selectedRows, row.agencyId];
            setSelectedRows(slectedRows);
        }

        setSelectAll(slectedRows?.length === agencies?.length)
    };

    const columns = [
        {
            name: (
                <div className="flex justify-center items-center">
                    <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                </div>
            ),
            accessorKey: "select",
            selector: (row) => row.select,
            center: true,
            width: '50px',
            cell: (row) => (
                <div className="flex justify-center items-center">
                    <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedRows?.includes(row.agencyId)}
                        onChange={() => handleRowSelection(row)}
                    />
                </div>
            ),
        },
        {
            name: "اسم الجهة",
            selector: (row) => row.agencyName,
            header: "agencyName",
            accessorKey: "agencyName",
            sortable: true,
            cell: (row) => row.agencyName || row.name || "-",
        },
        {
            name: "اسم القطاع الحالي",
            selector: (row) => row.currentAgencySectorName,
            header: "currentAgencySectorName",
            accessorKey: "currentAgencySectorName",
            footer: (props) => props.column.currentAgencySectorName,
        },
        {
            name: "الاجراءات",
            selector: (row) => row.actions,
            header: "actions",
            accessorKey: "actions",
            width: '120px',
            footer: (props) => props.column.actions,
            cell: (row) => {
                return (
                  <AppButton
                    size={"small"}
                    onClick={() => {
                      
                      handleUpdate([row.agencyId]);
                    }}
                  >
                    {" "}
                    تحديث{" "}
                  </AppButton>
                );
            },
        },
    ];

    

    const handleUpdate = async (selectedRows = []) => {
        const res = await axiosPostRequest(environment.assignAgencyToSector, {
          agencySectorId: agencies?.[0]?.agencySectorId,
          agencySectorName: agencies?.[0]?.newAgencySectorName,
          agenceisIds: selectedRows,
        });
        setLoadingAll(false);
        if (res?.success) {
          toast.success("تم تحديث الجهات بنجاح");
          setAlreadyAddedAgencies([...alreadyAddedAgencies, ...selectedRows]);
          const isAllAgencisAdded =
            (agencies || [])?.filter((item) => {
              return ![...alreadyAddedAgencies, ...selectedRows].includes(
                item.agencyId
              );
            }).length === 0;

          setSelectedRows([]);
          selectAll && setSelectAll(false);
          queryClient.invalidateQueries("getAllAgencySectors");
          if (isAllAgencisAdded) {
            setOpen(false);
            setAlreadyAddedAgencies([]);
            setSelectedRows([]);
          }

        } else {
          toast.error("حدث خطأ اثناء تحديث الجهات");
        }
    };


    return (
      <div>
        <FModal
          content={
            <>
              <div className="">
                هذة {getAgencyWordPluralAndSingular(agencies.length)} تابعة
                لقطاعات اخرى يمكنك تحديثها لقطاع (
                {agencies?.[0]?.newAgencySectorName})
              </div>
              <NewTable
                fixedHeaderScrollHeight="280px"
                columns={columns}
                data={(agencies || [])?.filter((item) =>{
                    return !alreadyAddedAgencies.includes(item.agencyId)
                })}
                pagination={false}
                withMaxResultChange={false}
              />
            </>
          }
          open={open}
          submitLabel={"تحديث الكل"}
          disableSubmit={selectedRows.length === 0}
          withoutButton={false}
          width={160}
          setOpen={() => setOpen(false)}
          headerTitle={"تحديث الجهات"}
          action={() => {
            setLoadingAll(true);
            handleUpdate(selectedRows)
            }}
          loading={loadingAll}
          modelSize="5xl"
          isLargeBody
        />
      </div>    
    );
};

export default ApproveSectorWithAgencies;



function getAgencyWordPluralAndSingular(num){
    if(num === 1){
        return "الجهة";
    }
    return "الجهات";
}
