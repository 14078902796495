import { Page, Image, StyleSheet, Link, Text, View } from "@react-pdf/renderer";
import Slide37 from "../assets/images/Slide37.jpg";
import { Footer } from "../components/Footer";
export default function Page19({ styles, page }) {

  const pageStyles = StyleSheet.create({
    clickableButton: {
      position: "absolute",
      top: "55%",
      right: "35%",
      width: 170,
      height: 50,
    },
    buttonShadow: {
      width: '100%',
      height: '100%',
      borderRadius: 8,
      backgroundColor: '#1B191E',
      position: 'absolute',
      top: 3,
      zIndex: -1,

    },
    mainSection: {
      position: 'absolute',
      top: 200,
      right: 20,
      textAlign: "center",
      direction: "rtl",
    },
    mainSectionTitle: {
      color: "#2A2069",
      fontSize: 27,
      display: "flex",
      flexWrap: 'wrap',
      fontWeight: 500,
      flexDirection: "row-reverse",
      textAlign: "right",
      direction: "rtl",
      marginBottom: '10%',
    },
    sectionTitleNumber: {
      color: "#1DC183",
    },
    subSection: {
      width: '95%',
      fontSize: 14,
      padding: 10,
      marginBottom: 30
    }
  })

  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}>
        {/* <Image src={Slide37} style={styles.imagePage} /> */}

        <View style={pageStyles.mainSection}>
          <View style={pageStyles.mainSectionTitle}>
            <Text style={pageStyles.sectionTitleNumber}> .7</Text>
            <Text>خدمات داعمة مقدمة من هيئة الحكومة الرقمية</Text>
          </View>

          <View style={pageStyles.subSection}>
            <Text>
              تقدم هيئة الحكومة الرقمية مجموعة شاملة من الخدمات، تهدف إلى دعم وتسهيل تبني التقنيات الناشئة وتطبيقات الحكومة الرقمية
            </Text>
          </View>

          <View style={{ textAlign: 'center', width: '100%', fontSize: 18, color: '#7C32C9' }}>
            <Text>
              خدمات هيئة الحكومة الرقمية
            </Text>
          </View>
        </View>

        <View style={pageStyles.clickableButton}>
          <View style={{ position: 'relative', width: '100%', height: '100%' }}>
            <View
              style={styles.button}
            >
              <Link
                src="https://dga.gov.sa/ar/listing-services"
                style={{
                  textDecoration: 'none', color: '#fff', width: '100%', height: '100%',
                }}
              >
                <Text> للـإطلاع </Text>
              </Link>
            </View>
            <View style={pageStyles.buttonShadow}></View>
          </View>
        </View>

        <Footer />
      </View>

    </Page>
  );
}
