import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import environment from "../../../../../../../services/environment";
import { axiosDeleteRequest, axiosGetRequest, axiosPostRequest, axiosPutRequest } from "../../../../../../../services/Request";
import { toast } from "react-toastify";
import AppRadio from "../../../../../../../components/form/appRadio";
import NewSwitch from "../../../../../../../components/NewSwitch";
import NewTable from "../../../../../../../components/NewTable";
import AppButton from "../../../../../../../components/buttons/appButton";
import { agencyId, expertId, reviewerId, surveyId } from "../../../../../../../helpers/isPermission";
import AppLoader from "../../../../../../../components/loader";
import { ifNotDefinedReturnZero } from "../../../../../../../helpers/utils";
import { DropdownMultiSelect } from "../../../../../../../components";
import AppModal from "../../../../../../../components/Models/appModal";
import AddEditSuggestedActivities from "./AddEditSuggestedActivities";
import DeleteIcon from "../../../../../../../components/DeleteIcon";
import NewTooltip from "../../../../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { countries } from "../constants";

const SuggestedActivities = () => {

  const initialInputsState = {
    agencyId: null,
    perspectiveList: []
  }
  const [inputs, setInputs] = useState({
    agencyId: null,
    perspectiveList: []
  });

  const addFormRef = useRef(null);
  const editFormRef = useRef(null);

  const [selectedAgency, setSelectedAgency] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "5", value: 5 });

  // Get all agency data
  const { data: allAgenciesOptions, isLoading: isLoadingAgencies, isFetching } = useQuery({
    queryKey: ["getAllAgencyWithoutPagination", surveyId()],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyWithoutPagination, {
        surveyId: surveyId(),
        expertId: expertId() || reviewerId(),
      }),
    select: (res) =>
      res?.result
        ?.filter((agency) => agency.id && agency.nameInArabic)
        .map((item) => {
          return {
            label: item.nameInArabic || "",
            value: item.id,
            name: item.name || "",
          }
        }),
    staleTime: (5 * 60 * 1000)  // Cache data for 5 minutes
  });

  // Get all perspectives data
  const { data: getPerspectiveList, isLoading: isLoadingPerspectiveList } = useQuery({
    queryKey: ["getPerspectiveList", surveyId()],
    queryFn: () =>
      axiosGetRequest(environment.getPerspectiveList, {
        surveyId: surveyId(),
      }),
    select: (res) => res?.result?.items || [],
    staleTime: (5 * 60 * 1000)  // Cache data for 5 minutes
  });


  // get all activites table data
  const {
    data: getSuggestedActivity,
    isLoading: isLoadingSuggestedActivity,
    isFetching: isFetchingSuggestedActivity,
    refetch: refetchGetAllSuccessStories,
  } = useQuery({
    queryKey: ["GetAllSuggestedActivity", selectedAgency?.value, page, maxResult.value],
    queryFn: () =>
      axiosGetRequest(environment.GetAllSuggestedActivity, {
        surveyId: surveyId(),
        agencyId: selectedAgency?.value ?? null,
        skipCount: page * maxResult.value,
        maxResultCount: maxResult.value,
      }),
    select: (res) => res?.result,
  });

  const allSuggestedActivity = getSuggestedActivity?.items || [];
  const totalPages = Math.ceil(getSuggestedActivity?.totalCount / maxResult?.value);

  useEffect(() => {
    setInputs({
      ...inputs,
      agencyTitle: allAgenciesOptions?.[0]?.label || '',
      agencyId: allAgenciesOptions?.[0]?.value || '',
      perspectiveList: getPerspectiveList || [],
    });
  }, [getPerspectiveList, allAgenciesOptions])

  const handleSubmitAddEdit = (isAdd) => {
    if (isAdd && addFormRef.current) {
      addFormRef.current?.handleSubmit();
    }

    if (!isAdd && editFormRef.current) {
      editFormRef.current?.handleSubmit();
    }
  };

  const AddSuccessStories = async () => {
    const reqBody = {
      suggestedActivities: inputs.perspectiveList.map(item => {
        return {
          surveyId: surveyId(),
          agencyId: inputs.agencyId,
          perspectiveId: item?.id,
          perspectiveTitle: item?.title,
          note: item?.note
        }
      })
    }

    const res = await axiosPostRequest(environment.CreateSuggestedActivity, reqBody);

    if (res?.success) {
      toast.success("تم إضافة النشاط بنجاح");
      setOpenAddModal(false);
      setInputs({ ...initialInputsState })
      refetchGetAllSuccessStories();
    } else {
      toast.error(res?.response?.data?.error?.message);
    }

    // Reset Fields
    setInputs({ ...initialInputsState });
    getPerspectiveList?.map(el => {
      el.note = ''
      return el
    });
  };

  const EditSuccessStories = async (values) => {
    const reqBody = {
      surveyId: surveyId(),
      ...inputs.selectedRow
    }


    const res = await axiosPutRequest(environment.UpdateSuggestedActivity, reqBody)

    if (res?.success) {
      toast.success("تم تعديل النشاط بنجاح");
      setOpenEditModal(false);
      setInputs({ ...initialInputsState })
      refetchGetAllSuccessStories();
    } else {
      toast.error(res?.response?.data?.error?.message);
    }

    // Reset Fields
    setInputs({ ...initialInputsState });
    getPerspectiveList?.map(el => {
      el.note = ''
      return el
    });

  };

  const deleteSuggestedActivity = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.DeleteSuggestedActivity, {
      id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      refetchGetAllSuccessStories();
    }
  };


  const columns = [
    {
      name: "اسم الجهة",
      selector: (row) => row.agencyTitle,
      header: "agencyTitle",
      accessorKey: "agencyTitle",
      width: '300px',
      cell: (row) => row.agencyTitle,
    },
    {
      name: "اسم القدرة",
      selector: (row) => row.perspectiveTitle,
      header: "perspectiveTitle",
      accessorKey: "perspectiveTitle",
      width: '300px',
      cell: (row) => row.perspectiveTitle,
    },
    {
      name: "النشاط المقترح",
      selector: (row) => row.note,
      header: "note",
      accessorKey: "note",
      cell: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row?.note?.replace(/\n/g, '<br>') }} style={{ maxHeight: '50px', overflow: 'auto', width: '100%' }} />
      ),
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      width: '100px',
      footer: (props) => props.column.actions,
      cell: (row) =>
        <div className="flex gap-2">
          <NewTooltip content={"تعديل"}>
            <FontAwesomeIcon
              icon={faPen}
              className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setInputs({
                  ...inputs,
                  agencyId: row?.agencyId,
                  selectedRow: row,
                  perspectiveList: [{ ...row }]
                })
                setOpenEditModal(row);
              }}
            />
          </NewTooltip>
          <DeleteIcon
            id={row.id}
            loading={deleteLoading}
            deleteFunction={() => deleteSuggestedActivity(row.id)}
            deletedName={'هذا النشاط المقترح'}
          />
        </div>
      ,
    },

  ];

  const handleChangeAgency = (val) => {
    setSelectedAgency(val);
    setPage(0);
  }

  const validate = (values) => {
    const errors = {};

    const checkIfFieldIsValid = (key) => {
      if (!inputs[key]) {
        errors[key] = "هذا الحقل مطلوب";
      }
    };

    if (openEditModal) {
      checkIfFieldIsValid("agencyId");
      if (!inputs.selectedRow.note?.length) {
        errors.note = "هذا الحقل مطلوب";
      }
    }
    else {
      checkIfFieldIsValid("agencyId");
      getPerspectiveList.forEach(item => {
        checkIfFieldIsValid(`activityNote_${item?.id}`);
      })
    }

    return errors;
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setPage(newOffset);
  };


  return (
    <div className="bg-white rounded-[30px] m-5 relative">
      {
        (isLoadingAgencies || isFetching) ?
          <div className="flex justify-center items-center min-h-[400px]">
            <AppLoader />
          </div>
          :
          <>
            <div className="flex-1 max-w-[500px] mb-4">
              <DropdownMultiSelect
                placeholder={"اختر جهة حكومية"}
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgency}
                handleChange={handleChangeAgency}
                options={allAgenciesOptions || []}
                isMultiple={false}
                isRequired={false}
                label={"اختر جهة حكومية"}
                loading={isLoadingAgencies}
              />
            </div>

            <div className="flex justify-end absolute left-0">
              <AppButton
                onClick={() => {
                  setOpenAddModal(true)
                  setInputs({
                    ...initialInputsState,
                    agencyId: Number(allAgenciesOptions?.[0]?.value),
                    selectedRow: null,
                    perspectiveList: getPerspectiveList
                  })
                }}
                variant="primary"
              >
                إضافة نشاط جديد
              </AppButton>
            </div>

            <NewTable
              columns={columns}
              data={allSuggestedActivity}
              loading={isLoadingSuggestedActivity || isFetchingSuggestedActivity}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={page}
              withMaxResultChange={true}
              maxResultValue={maxResult}
              setMaxResultValue={(val) => {
                setMaxResult(val);
                setPage(0);
              }}
              withExcel={false}
              withSearch={false}
            />

            {/* Open Add new Activity modal */}
            <AppModal
              loading={false}
              handleSubmit={() => handleSubmitAddEdit(true)}
              type={"submit"}
              headerTitle={"إضافة  نشاط جديد"}
              open={openAddModal}
              setOpen={setOpenAddModal}
              onClose={() => {
                setOpenAddModal(false);
                setInputs({ ...initialInputsState });
                getPerspectiveList?.map(el => {
                  el.note = ''
                  return el
                });
              }}
              submitLabel={"إضافة"}
            >
              <AddEditSuggestedActivities
                formRef={addFormRef}
                initialInputsState={initialInputsState}
                inputs={inputs}
                setInputs={setInputs}
                handleSubmit={AddSuccessStories}
                validate={validate}
                selectedAgencyId={selectedAgency?.value || allAgenciesOptions?.[0]?.value}
                allAgenciesOptions={allAgenciesOptions}
                perspectiveList={getPerspectiveList?.length ? [...getPerspectiveList] : []}
              />
            </AppModal>

            {/* Open Edit Activity modal */}
            <AppModal
              loading={false}
              handleSubmit={() => handleSubmitAddEdit(false)}
              type={"submit"}
              headerTitle={"تعديل  نشاط جديد"}
              open={openEditModal}
              setOpen={setOpenEditModal}
              onClose={() => {
                setOpenEditModal(false);
                setInputs({ ...initialInputsState });
                getPerspectiveList?.map(el => {
                  el.note = ''
                  return el
                });
              }}
              submitLabel={"تعديل"}
            >
              <AddEditSuggestedActivities
                isEdit={true}
                formRef={editFormRef}
                initialInputsState={initialInputsState}
                inputs={inputs}
                setInputs={setInputs}
                handleSubmit={EditSuccessStories}
                validate={validate}
                allAgenciesOptions={allAgenciesOptions}
                perspectiveList={getPerspectiveList?.length ? [...getPerspectiveList] : []}
              />
            </AppModal>

          </>

      }
    </div>
  );
};

export default SuggestedActivities;