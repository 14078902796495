import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import SearchIconPDF from "../assets/svgs/SearchIconPDF";
import CheckListIconPDF from "../assets/svgs/CheckListIconPDF";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";
import { Footer } from "../components/Footer";
import { NotesRecommendationTextArea } from "../components/NotesRecommendationTextArea";
import { getBgColorOrDefault } from "../../utils";
export default function Page22Perspective({
  pageNumber,
  sectionNumber,
  pageTitle,
  styles,
  level,
  levelColor,
  percentage = 0,
  summary,
  perspectiveExpertNotes,
}) {
  const style = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    container: {
      marginTopTop: 80,
      marginRight: 10,
    },
    header: {
      textAlign: "right",
      flexDirection: "row-reverse",
      gap: 4,
      fontSize: 20,
      marginTop: 30,
    },
    headerTextNum: {
      color: "#7C32C9",
    },
    headerText: {
      color: "#3F3578",
    },
    main: {
      marginTop: 10,
    },

    resultSection: {
      width: "100%",
      textAlign: "right",
      marginBottom: 30,
      marginTop: 20,
    },
    resultTitle: {
      color: "#1CC182",
      fontSize: 11,
      textAlign: "right",
      marginBottom: 10,
    },
    resultBody: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#C00000",
      fontSize: 14,
      position: "relative",
    },
    resultBodyContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "45%",
      border: "2px solid #382F71",
      borderRadius: "30%",
    },
    resultData: {
      paddingVertical: 15,
      fontSize: 16,
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
    resultLevels: {
      position: "absolute",
      top: 50,
      border: "3px solid #382F71",
      backgroundColor: "#382F71",
      borderRadius: "50%",
      width: "230px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    resultTextLevel: {
      fontSize: 14,
      // backgroundColor: "#FFFF00",
      color: '#fff',
      lineHeight: 1,
      paddingVertical: 10
    },
    reportInfo: {
      paddingVertical: 10,
      paddingHorizontal: 15,
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      gap: 15,
      //   height: 150,
      border: "2px solid #7C32C9",
      borderRadius: 12,
      lineHeight: 2.5,
    },
    reportText: {
      // width: "80%",
      textAlign: "right",
      fontSize: 9,
      color: "#3F3578",
      flexWrap: "wrap",
    },
    reportData: {
      fontSize: 16,
      width: "15%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
    },
    reportLevelText: {
      textAlign: 'center',
      paddingBottom: 5,
      fontSize: 12,
      lineHeight: 1
    },

    percentageContainer: {
      width: 90,
      //   height: 30,
      textAlign: "center",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid #8341C9",
      borderRadius: 8,
      backgroundColor: "#8341C9",
      paddingVertical: 6,
    },
    reportPrecentageText: {
      lineHeight: 0.9,
    },
  });
  return (
    <>
      <Page style={style?.page}>
        <View style={style?.container}>
          <View style={style?.header}>
            <Text style={style?.headerTextNum}>{sectionNumber}</Text>
            <Text style={style?.headerText}>{pageTitle}</Text>
          </View>
          <View style={style?.main}>
            <View style={style?.reportInfo}>
              <Text style={style?.reportText}>{summary}</Text>
              {/* <View style={{ ...style?.reportData }}>
                <Text
                  style={{
                    ...style?.reportLevelText,
                    color: "#C00000",
                  }}
                >
                  {level}
                </Text>
                <View style={style?.percentageContainer}>
                  <Text
                    style={{
                      ...style?.reportPrecentageText,
                      color: "#fff",
                    }}
                  >
                    {percentage}%
                  </Text>
                </View>
              </View> */}
            </View>

            {/* النتيجة العامة */}
            <View style={style.resultSection}>
              {/* <View style={style.resultTitle}>
                <Text>النتيجة العامة {agencyTitle}</Text>
              </View> */}
              <View style={style.resultBody}>
                <View style={style.resultBodyContainer}>
                  <Text style={style.resultData}> {Number(ifNotDefinedReturnZero(percentage))?.toFixed(2)}% </Text>
                </View>
                <View style={{...style.resultLevels, backgroundColor: getBgColorOrDefault(levelColor), borderColor: getBgColorOrDefault(levelColor)}}>
                  <Text style={style.resultTextLevel}> {level} </Text>
                </View>
              </View>
            </View>

            {/* الوضع الراهن و التوصيات  */}
            <NotesRecommendationTextArea
              expertNotes={perspectiveExpertNotes}
            />
          </View>
        </View>

        <Footer />
      </Page>
    </>
  );
}
