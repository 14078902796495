import { evaluationResult } from "../../../features/agencyAnswerReport/PageData";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";

export default function Page20({
  styles,
  agencyTitle,
  agencySector,
  page = 20,
  evaluationData,
  surveyDifference, }) {
  const evCardWidth = `${90 / 4}%`;

  const pageStyles = {
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: "5px",
      textAlign: "right",
    },
    cardHeader: {
      backgroundColor: "#7C32C9",
      fontSize: "15px",
      paddingTop: "7px",
      color: "#fff",
      marginBottom: "2px",
    },
    cardItemContainer: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      gap: "2px",
    },
    cardItemWrapper: {
      flexGrow: "1",
      fontSize: "15px",
      textAlign: "center",
    },
    cardItemHeader: {
      backgroundColor: "#7C32C9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    },
    cardItemBody: {
      minHeight: "70px",
      backgroundColor: "#F5F5F5",
    },
    tabelContainer: {
      width: "100%",
      fontSize: "15px",
      marginTop: "50px",
    },
    tableHeader: {
      color: "#1DC183",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1px",
      textAlign: "center",
    },
    tableHeaderCell: {
      padding: "30px",
      direction: 'ltr',
      // fontWeight: "700",
    },
    tableBodyCell: {
      textAlign: "center",
      padding: "30px",
      direction: 'ltr',
      // fontWeight: "700",
    },
    tableBody: {
      color: "#2A2069",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1px",
    },
    resultSection: {
      width: "100%",
      marginBottom: "20px",
      marginTop: "30px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      textAlign: "center",
    },
    resultBody: {
      fontSize: "15px",
      position: "relative",
      width: evCardWidth,
    },
    resultBodyContainer: {
      textAlign: "center",
      border: "2px solid #382F71",
      borderRadius: "10px",
      padding: "25px",
    },
    resultData: {
      paddingTop: "30px",
      fontSize: "15px",
      alignItems: "center",
      justifyContent: "center",
    },
    resultLevels: {
      position: "absolute",
      top: "-20px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "3px solid #1DC183",
      backgroundColor: "#1DC183",
      borderRadius: "15px",
      textAlign: "center",
      width: "90%",
      paddingBottom: "10px",
    },
    resultTextLevel: {
      fontSize: "15px",
      color: "#fff",
    },
  };

  const isShow2023 = evaluationResult(evaluationData)?.isShow2023;
  const isShow2024 = evaluationResult(evaluationData)?.isShow2024;
  const isNewAgency = !isShow2023 && !isShow2024;


  const EvaluationTable = ({ data }) => {
    return (
      <table style={pageStyles.tabelContainer}>
        <tr style={pageStyles.tableHeader}>
          <td style={pageStyles.tableHeaderCell}>
            <span>القدرات</span>
          </td>
          {isShow2023 && (
            <td style={pageStyles.tableHeaderCell}>
              <span>الدورة الاولي 2023م</span>
            </td>
          )}
          {isShow2024 && (
            <td style={pageStyles.tableHeaderCell}>
              <span>الدورة الثانية 2024م</span>
            </td>
          )}
          <td style={pageStyles.tableHeaderCell}>
            <span>الدورة الثالثة 2025م</span>
          </td>
          <td
            style={{
              ...pageStyles.tableHeaderCell,
              backgroundColor: "rgb(28 192 129 / 20%)",
              width: '140px',
            }}
          >
            <span>نسبة التغير علي القدرة</span>
          </td>
        </tr>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} style={pageStyles.tableBody}>
              <td style={pageStyles.tableBodyCell}>
                <span>{item?.perspectiveTitle}</span>
              </td>
              {isShow2023 && (
                <td style={pageStyles.tableBodyCell}>
                  <span>
                    {ifNotDefinedReturnZero(
                      item?.persPectiveDegree2023
                    )?.toFixed(2)}
                  </span>
                </td>
              )}
              {isShow2024 && (
                <td style={pageStyles.tableBodyCell}>
                  <span>
                    {ifNotDefinedReturnZero(
                      item?.persPectiveDegree2024
                    )?.toFixed(2)}
                  </span>
                </td>
              )}
              <td style={pageStyles.tableBodyCell}>
                <span>
                  {ifNotDefinedReturnZero(
                    item?.persPectiveDegree2025
                  )?.toFixed(2)}
                </span>
              </td>
              <td
                style={{
                  ...pageStyles.tableBodyCell,
                  backgroundColor: "rgb(28 192 129 / 20%)",
                  width: '140px',
                }}
              >
                <span>
                  {isNewAgency
                    ? "N/A"
                    : ifNotDefinedReturnZero(
                      item?.persPectiveDifference
                    )?.toFixed(2)?.replace('-', '- ')}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div style={{ ...styles.page, position: "relative", height: "296.86mm" }}>
      <div style={styles.mainSectionTitle}>
        <span style={styles.sectionTitleNumber}>5.</span>
        <span>{`نتائج تقييم ${agencyTitle}`}</span>
      </div>

      <div>
        <div
          style={{
            ...styles.secondarySectionTitle,
            fontSize: 20,
            margin: "50px 0",
          }}
        >
          <span>مقارنة نتائج جاهزية تبنّي التقنيات الناشئة في</span>
          <span style={{ ...styles.agencyTitle, fontSize: 20 }}>
            {agencySector}
          </span>
        </div>

        <div style={pageStyles.resultSection}>
          {evaluationResult(evaluationData)?.totalEvaluation?.map(
            (item, index) =>
              item?.isShow && (
                <div key={index} style={pageStyles.resultBody}>
                  <div style={pageStyles.resultBodyContainer}>
                    <span style={pageStyles.resultData}>
                      %{Number(ifNotDefinedReturnZero(item?.value))?.toFixed(2)}
                    </span>
                  </div>
                  <div style={pageStyles.resultLevels}>
                    <span style={pageStyles.resultTextLevel}>
                      {item?.title}م
                    </span>
                  </div>
                </div>
              )
          )}
          <div style={pageStyles.resultBody}>
            <div style={pageStyles.resultBodyContainer}>
              <span style={pageStyles.resultData}>
                {isNewAgency
                  ? "N/A"
                  : Number(ifNotDefinedReturnZero(surveyDifference))?.toFixed(
                    2
                  ) + "%"}
              </span>
            </div>
            <div style={pageStyles.resultLevels}>
              <span style={pageStyles.resultTextLevel}>
                مقدار التغيير الكلي
              </span>
            </div>
          </div>
        </div>
      </div>

      <EvaluationTable
        data={evaluationResult(evaluationData)?.perspectiveCategoryEvaluation}
      />
    </div>
  );
}
