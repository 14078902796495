import { useQuery } from "@tanstack/react-query";
import { Badge } from "flowbite-react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { plusLight, programsIcon } from "../../../../../assets/icons/Index";
import { Title } from "../../../../../components";
import AppBadge from "../../../../../components/AppBadge";
import NewTable from "../../../../../components/NewTable";
import FilePreview from "../../../../../components/upload/filePreview";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import { errorToast } from "../../../../../helpers/toast";
import useFileDownloaderAsZip from "../../../../../hooks/useDownloadZipFile";
import NewHeader from "../../../../../layouts/header/NewHeader";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { useGetAnswersAnalysis } from "./useGetAnswersAnalysis";
import { isDgaUsers } from "../../../../../helpers/isPermission";
import { unique } from "../../../../../helpers/utils";

const AnswerAnalysis = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "50", value: 50 });

  const { agencyDetails, isGettingAnswersAnalysis, totalPages, agencyAnswers, allStandards } =
    useGetAnswersAnalysis({
      currentPage,
      maxResult: maxResult.value,
      agencyId: id,
    });

  const allStandardsColumns = [
    {
      name: "كود المعيار",
      selector: (row) => "code",
      header: "code",
      accessorKey: "code",
      cell: (row) => (
        <Link
          to={`/programs/${localStorage.getItem("surveyId")}/program/${agencyDetails?.agencyId
            }/entity/${row?.id}/standard`}
          className="whitespace-nowrap font-bold truncate hover:text-sky-600"
        >
          {row.code}
        </Link>
      ),
      width: "100px",
    },
    {
      name: "اسم المعيار",
      selector: (row) => "title",
      header: "title",
      accessorKey: "title",
      cell: (row) => (
        row.title
      ),
      center: true,
    },
    {
      name: "عرض الإجابة",
      selector: (row) => "standardCode",
      header: "standardCode",
      accessorKey: "standardCode",
      cell: (row) => (
        <Link
          to={`/programs/${localStorage.getItem("surveyId")}/program/${agencyDetails?.agencyId
            }/entity/${row?.id}/standard`}
          className="whitespace-nowrap font-bold pb-2 px-4 rounded text-white bg-indigo-600 truncate hover:bg-indigo-800"
        >
          عرض الإجابة
        </Link>
      ),
      width: "150px",
    },
  ];

  const columns = [
    {
      name: "كود المعيار",
      selector: (row) => "standardCode",
      header: "standardCode",
      accessorKey: "standardCode",
      cell: (row) => (
        <Link
          to={`/programs/${localStorage.getItem("surveyId")}/program/${agencyDetails?.agencyId
            }/entity/${row?.standardId}/standard`}
          className="whitespace-nowrap font-bold truncate hover:text-sky-600"
        >
          {row.standardCode}
        </Link>
      ),
      width: "100px",
    },
    {
      name: "اجابة الجهة",
      selector: (row) => "selfEvaluationDegree",
      header: "selfEvaluationDegree",
      accessorKey: "selfEvaluationDegree",
      cell: (row) => (
        <AppBadge badgeColor={row.finalCommitmentLevelColor}>
          {row.finalCommitmentLevelTitle}
        </AppBadge>
      ),
      center: true,
      width: "150px",
    },
    {
      name: "الدرجة المقترحة",
      selector: (row) => "finalDegree",
      header: "finalDegree",
      accessorKey: "finalDegree",
      cell: (row) => (
        <AppBadge badgeColor={row.selfEvaluationCommitmentLevelColor}>
          {row.selfEvaluationCommitmentLevelTitle}
        </AppBadge>
      ),
      center: true,
      width: "150px",
    },
    {
      name: "المستندات",
      selector: (row) => "agencyProofAnswers",
      header: "agencyProofAnswers",
      accessorKey: "agencyProofAnswers",
      cell: (row) => (
        <div className="flex-wrap my-2 flex gap-2">
          {row?.agencyProofAnswers?.length > 0 &&
            row?.agencyProofAnswers?.map((ele) => {
              return ele?.standardProofAttachments?.length > 0 ? (
                ele?.standardProofAttachments?.map((item) => (
                  <div className=" ">
                    <Badge
                      className="text-indigo-700 pt-0 font-medium bg-indigo-100 pb-0 px-2  rounded-xl"
                      size="sm"
                    >
                      <div className="flex items-center gap-3 pr-1">
                        <FilePreview
                          fileName={item?.attachment?.name}
                          id={item?.attachment?.id}
                        >
                          <p className="mb-2 cursor-pointer">
                            {item?.attachment?.name}
                          </p>
                        </FilePreview>
                        <FilePreview
                          fileName={item?.attachment?.name}
                          id={item?.attachment?.id}
                        />
                      </div>
                    </Badge>
                  </div>
                ))
              ) : (
                <p></p>
              );
            })}
        </div>
      ),
    },
    {
      name: "عرض الإجابة",
      selector: (row) => "standardCode",
      header: "standardCode",
      accessorKey: "standardCode",
      cell: (row) => (
        <Link
          to={`/programs/${localStorage.getItem("surveyId")}/program/${agencyDetails?.agencyId
            }/entity/${row?.standardId}/standard`}
          className="whitespace-nowrap font-bold pb-2 px-4 rounded text-white bg-indigo-600 truncate hover:bg-indigo-800"
        >
          عرض الإجابة
        </Link>
      ),
      width: "150px",
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // excel
  const [excelLoading, setExcelLoading] = useState(false);
  const [downloadFileLoading, setDownloadFileLoading] = useState(false);

  const { downloadFileAsZip } = useFileDownloaderAsZip();
  const surveyId = localStorage.getItem("surveyId");

  const { data: allAttachedFiles, isLoading: isAllAttachmentsLoading } = useQuery({
    queryKey: [
      "GetFinalSurveyAnswersDetailsForAgency",
      surveyId,
      id,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetFinalSurveyAnswersDetailsForAgency, {
        surveyId: surveyId,
        AgencyId: id,
        MaxResultCount: 2000
      }),
    enabled: !isDgaUsers()
  });

  const handleDownloadAllFilesAsZip = async () => {
    if (allAttachedFiles?.result.attachedFiles?.length) {
      const filesIds = unique(allAttachedFiles?.result.attachedFiles?.map((file) => file.fileId));
      await downloadFileAsZip(`ملفات جهة ${agencyDetails?.agencyNameInArabic}`, filesIds);
    } else {
      errorToast("لا يوجد ملفات للتحميل");
    }
  };

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.GetAgencyAnswerByAgencyId, {
      AgencyId: id,
      SkipCount: 0,
      MaxResultCount: 100000,
      surveyId: surveyId,
    });

    setExcelLoading(false);

    downloadExcel(
      `اجابات جهة ${agencyDetails?.agencyNameInArabic}`,
      res?.result?.items
    );
  };

  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={"إجابات الجهة"}
        subTitle={agencyDetails?.agencyNameInArabic}
        textStyle={{ color: "white", fontSize: 18 }}
        seconed={true}
        titleButton={"modules.main.users.entities.titleAddButton"}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969", width: 200 }}
      />
      <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
        <h2 className="text-xl mb-3">
          إجابــات {agencyDetails?.agencyNameInArabic}
        </h2>
        <NewTable
          withMaxResultChange
          maxResultValue={maxResult}
          setMaxResultValue={setMaxResult}
          withDownloadAllFiles={!isDgaUsers()}
          downloadFilesText={"تحميل جميع الملفات ( ZIP File )"}
          onDownloadFiles={handleDownloadAllFilesAsZip}
          downloadFileLoading={downloadFileLoading}
          withExcel={false}
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          loading={isGettingAnswersAnalysis}
          columns={isDgaUsers() ? allStandardsColumns : columns}
          data={isDgaUsers() ? allStandards : agencyAnswers}
          pagination
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default AnswerAnalysis;
