import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { A4Width } from "../PageData";

export function Footer({ light = false }) {
    const styles = StyleSheet.create({
        footerContainer: {
            position: "absolute",
            bottom: 15,
            width: A4Width,
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
        },
        footerItem: {
            color: light ? "#fff" : "#666",
            fontSize: 8,
            fontWeight: 300,

            position: "absolute",
            bottom: 10,
        },
    });

    return (
        // <View style={styles.footerContainer}>
        <>
            <Text
                style={{ ...styles.footerItem, right: 20 }}
                render={({ pageNumber }) => pageNumber}
                fixed
            />
            <Text style={{ ...styles.footerItem, left: 20 }} fixed>
                تقرير جاهزية تبني التقنيات الناشئة 2025م
            </Text>
        </>
        // </View>
    );
}
