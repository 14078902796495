import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";

const AppInput = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  min = null,
  maxLength = null,
  placeholder,
  id,
  errorMessage = "",
  variant = "white",
  className = "",
  style = {},
  isRequired = true,
  disabled = false,
}) => {
  const inputClasses = classNames(
    className,
    "py-2 border-gray-200 w-full h-12 rounded-full",
    {
      "border-red-600": errorMessage,
      " bg-gray_light": variant === "gray",
      " bg-white": variant === "white",
    }
  );

  const [maxLengthError, setMaxLengthError] = useState(null);

  return (
    <div>
      {label && (
        <label
          className="label_input mb-2 block text-lg text-[#292069]"
          htmlFor={id}
        >
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}
      <input
        className={inputClasses}
        style={style}
        type={type}
        name={name}
        min={min}
        value={value}
        onChange={(e) => {
          const value = e.target.value?.trimStart();
          if (maxLength) {
            if (value?.length <= maxLength) {
              onChange(e);
              setMaxLengthError(null);
            } else {
              setMaxLengthError(`لا يمكن ادخال اكثر من ${maxLength} حرف`);
            }
          } else {
            onChange(e);
          }
        }} placeholder={placeholder}
        id={id}
        disabled={disabled}
      />
      {errorMessage && <p className="text-error">{errorMessage}</p>}
      {maxLengthError && <p className="text-[#ce8602]">{maxLengthError}</p>}
    </div>
  );
};

export default AppInput;
