import { ifNotDefinedReturnZero } from "../../../../../../helpers/utils";

export const perspectiveColumns = [
    {
        name: "القدرة",
        selector: (row) => row.perspectiveTitle,
        header: "القدرة",
        accessorKey: "perspectiveTitle",
        sortable: true,
        center: true,
    },
    {
        name: "مستوى جاهزية التبني",
        selector: (row) => row.col2,
        header: "مستوى جاهزية التبني",
        accessorKey: "percentageCommitmentLevelTitle",
        sortable: true,
        center: true,
        cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
        name: "النتيجة",
        selector: (row) => Number(ifNotDefinedReturnZero(row.perspectiveUserPercentagesAvg))?.toFixed(2),
        header: "النتيجة",
        accessorKey: "perspectiveUserPercentagesAvg",
        sortable: true,
        center: true,
    },
];

export const interlocutorColumns = [
    {
        name: "العنصر",
        selector: (row) => row.interlocutorTitle,
        header: "العنصر",
        accessorKey: "interlocutorTitle",
        sortable: true,
        center: true,
    },
    {
        name: "مستوى جاهزية التبني",
        selector: (row) => row.col2,
        header: "مستوى جاهزية التبني",
        accessorKey: "percentageCommitmentLevelTitle",
        sortable: true,
        center: true,
        cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
        name: "النتيجة",
        selector: (row) => Number(ifNotDefinedReturnZero(row.interlocutorUserPercentagesAvg))?.toFixed(2),
        header: "النتيجة",
        accessorKey: "interlocutorUserPercentagesAvg",
        sortable: true,
        center: true,
    },
];

export const standardColumns = [
    {
        name: "المعيار",
        selector: (row) => row.standardTitle,
        header: "المعيار",
        accessorKey: "standardTitle",
        sortable: true,
        center: true,
    },
    {
        name: "مستوى جاهزية التبني",
        selector: (row) => row.col2,
        header: "مستوى جاهزية التبني",
        accessorKey: "percentageCommitmentLevelTitle",
        sortable: true,
        center: true,
        cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
        name: "النتيجة",
        selector: (row) => Number(ifNotDefinedReturnZero(row.standardUserPercentagesAvg))?.toFixed(2),
        header: "النتيجة",
        accessorKey: "standardUserPercentagesAvg",
        sortable: true,
        center: true,
    },
];

// List of countries (ISO 3166-1 alpha-2 codes with Arabic names)
export const countries = [
    // دول الخليج العربي
    { value: "SA", label: "المملكة العربية السعودية" },
    { value: "AE", label: "الإمارات العربية المتحدة" },
    { value: "KW", label: "الكويت" },
    { value: "QA", label: "قطر" },
    { value: "BH", label: "البحرين" },
    { value: "OM", label: "عُمان" },
    // دول شمال أفريقيا
    { value: "EG", label: "مصر" },
    { value: "LY", label: "ليبيا" },
    { value: "TN", label: "تونس" },
    { value: "DZ", label: "الجزائر" },
    { value: "MA", label: "المغرب" },
    { value: "MR", label: "موريتانيا" },
    // دول الهلال الخصيب
    { value: "JO", label: "الأردن" },
    { value: "LB", label: "لبنان" },
    { value: "SY", label: "سوريا" },
    { value: "IQ", label: "العراق" },
    { value: "PS", label: "فلسطين" },
    // دول القرن الأفريقي
    { value: "SD", label: "السودان" },
    { value: "SS", label: "جنوب السودان" },
    { value: "DJ", label: "جيبوتي" },
    { value: "SO", label: "الصومال" },
    // جزر القمر
    { value: "KM", label: "جزر القمر" },
    // دول شرق اسيا
    { value: "CN", label: "الصين" },
    { value: "JP", label: "اليابان" },
    { value: "KR", label: "كوريا الجنوبية" },
    { value: "KP", label: "كوريا الشمالية" },
    { value: "TW", label: "تايوان" },
    { value: "HK", label: "هونغ كونغ" },
    { value: "MO", label: "ماكاو" },
    { value: "MN", label: "منغوليا" },

    // أوروبا الغربية
    { value: "US", label: "امريكا" },
    { value: "FR", label: "فرنسا" },
    { value: "DE", label: "ألمانيا" },
    { value: "IT", label: "إيطاليا" },
    { value: "ES", label: "إسبانيا" },
    { value: "PT", label: "البرتغال" },
    { value: "BE", label: "بلجيكا" },
    { value: "NL", label: "هولندا" },
    { value: "LU", label: "لوكسمبورغ" },
    { value: "AT", label: "النمسا" },
    { value: "CH", label: "سويسرا" },
    // أوروبا الشمالية
    { value: "GB", label: "المملكة المتحدة" },
    { value: "IE", label: "أيرلندا" },
    { value: "DK", label: "الدنمارك" },
    { value: "SE", label: "السويد" },
    { value: "NO", label: "النرويج" },
    { value: "FI", label: "فنلندا" },
    { value: "IS", label: "آيسلندا" },
    { value: "EE", label: "إستونيا" },
    { value: "LV", label: "لاتفيا" },
    { value: "LT", label: "ليتوانيا" },
    // أوروبا الشرقية
    { value: "PL", label: "بولندا" },
    { value: "CZ", label: "تشيكيا" },
    { value: "SK", label: "سلوفاكيا" },
    { value: "HU", label: "هنغاريا" },
    { value: "RO", label: "رومانيا" },
    { value: "BG", label: "بلغاريا" },
    { value: "RU", label: "روسيا" },
    { value: "UA", label: "أوكرانيا" },
    { value: "BY", label: "بيلاروسيا" },
    { value: "MD", label: "مولدوفا" },
    // أوروبا الجنوبية
    { value: "GR", label: "اليونان" },
    { value: "MT", label: "مالطا" },
    { value: "CY", label: "قبرص" },
    { value: "HR", label: "كرواتيا" },
    { value: "SI", label: "سلوفينيا" },
    { value: "RS", label: "صربيا" },
    { value: "ME", label: "الجبل الأسود" },
    { value: "BA", label: "البوسنة والهرسك" },
    { value: "MK", label: "مقدونيا الشمالية" },
    { value: "AL", label: "ألبانيا" },
    // دول صغيرة
    { value: "VA", label: "الفاتيكان" },
    { value: "MC", label: "موناكو" },
    { value: "SM", label: "سان مارينو" },
    { value: "AD", label: "أندورا" },
];
