import { Page, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import Slide2 from "../assets/images/Slide2.jpg";
import { Footer } from "../components/Footer";

export default function Page2({ styles, agencyTitle, agencySector }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Image src={Slide2} style={styles.imagePage} />
        <Text
          style={{
            position: "absolute",
            top: 465,
            right: 227,
            color: "#f2f2f2",
            fontWeight: 600,
            fontSize: 10,
          }}
        >
          {agencySector}
        </Text>
        <Text
          style={{
            position: "absolute",
            top: 490,
            right: 125,
            color: "#f2f2f2",
            fontWeight: 600,
            fontSize: 10,
          }}
        >
          {agencyTitle}
        </Text>
      </View>

      <Footer light={true} />
    </Page>
  );
}
