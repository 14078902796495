export function PageWrapper({
  children,
  breakBefore = false,
  breakAfter = false,
  fullHeight = false,
  style = {},
}) {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#222",
        width: "210mm", // A4 width
        height: fullHeight ? "296.86mm" : "auto", // A4 height
        boxSizing: "border-box",
        direction: "rtl", // Right-to-left for Arabic text
        fontFamily: "DiodrumArabic",
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        pageBreakBefore: breakBefore ? "always" : "auto",
        pageBreakAfter: breakAfter ? "always" : "auto",
        ...style,
      }}
    >
      {children}
    </div>
  );
}
