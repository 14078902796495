import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import StandardEntitiesTable from "./StandardEntitiesTable";
import { DropdownMultiSelect, Title } from "../../../../components";
import { checkGreenCircle, drawLeftGreen, programsIcon } from "../../../../assets/icons/Index";
import { useGetStandardsByAgencyId } from "./useGetStandardsByAgencyId";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import { expertId, isExpertManager, reviewerId, surveyId } from "../../../../helpers/isPermission";
import { removeCircleDarkRed } from "../../../../assets/icons/dashboard";
import IconBasedOnRevieweStatus from "../../features/standerds/standardEntities/IconBasedOnRevieweStatus";
import NewTooltip from "../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AppPagination from "../../../../components/Pagination";
import useQueryParams from "../hooks/useQueryParams ";
import { set } from "date-fns";

const AgenciesTab = () => {
  const { getParam, setParam } =  useQueryParams();
  const defaultSelectedAgency = getParam("agencyId") || null;
  const history = useHistory();
  const [selectedAgency, setSelectedAgency] = useState({});
  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);

  const reviewedStatus = 1;
  // const [filterValue, setFilterValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "10", value: 10 });

  const {
    allAgenciesByStandardId,
    isGettingAllAgenciesByStandardId,
    totalPages,
    refetchGetStandardsByAgencyId,
  } = useGetStandardsByAgencyId({
    currentPage,
    agencyId: selectedAgency?.value,
    reviewedStatus,
    filterValue: null,
    maxResult: maxResult.value,
  });


  const handleGetAllAgencies = async () => {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination,
      {
        surveyId: surveyId(),
        expertId: expertId() || reviewerId(),
      }
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || "",
      value: item.id,
      name: item.name || "",
    }));

    setAllAgenciesOptions(agenciesOptions);
    const defaultAgency = agenciesOptions.find(
      (agency) => `${agency.value}` === defaultSelectedAgency
    ) || agenciesOptions[0];

    setSelectedAgency(defaultAgency);
  };

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  const handleChangeAgency = (selectedAgencyObj) => {
    setParam("agencyId", selectedAgencyObj?.value);
    setSelectedAgency(selectedAgencyObj || "");
  };

  const columns = [
    {
      name: "كود المعيار",
      center: true,
      sortable: true,
      width: '120px',
      cell: (row) => row.code || "-",
    },
    {
      name: "اسم المعيار",
      center: true,
      sortable: true,
      // width: "300px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) => row.title || "-",
    },
    {
      name: "اجابة الجهة",
      sortable: true,
      center: true,
      width: "140px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.agencyAnswer == null ? (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="flex items-center">
              <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            </div>
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="mb-1">{row.agencyAnswer}</p>

            &nbsp;
            {row.agencyAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}
          </div>
        ),
    },

    {
      name: "اجابة المدقق",
      sortable: true,
      center: true,
      width: "135px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.reviewerAnswer == null ? (
          <div className="flex items-center">
            <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
            <img
              src={removeCircleDarkRed}
              width={"16px"}
              alt="not answered standard"
            />
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              // color: "white",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: row?.reviewerAnswerColor,
              }}
              className={`rounded-full h-[10px] w-[10px]  ml-2 `}
            ></div>
            <span className="mb-1">{row.reviewerAnswer}</span>

            &nbsp;
            {row.reviewerAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}
          </div>
        ),
    },
    {
      name: "اجابة الخبير",
      sortable: true,
      center: true,
      width: "135px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.expertAnswer == null ? (
          <div className="flex items-center">
            <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
            <img
              src={removeCircleDarkRed}
              width={"16px"}
              alt="not answered standard"
            />
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              // color: "white",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: row?.expertAnswerColor,
              }}
              className={`rounded-full h-[10px] w-[10px]  ml-2 `}
            ></div>
            <span className="mb-1">{row.expertAnswer}</span>

            &nbsp;
            {row.expertAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}

          </div>
          // <div
          //   className="text-sm"
          //   style={{
          //     backgroundColor: row?.expertAnswerColor,
          //     color: "white",
          //     padding: 5,
          //     borderRadius: 4,
          //   }}
          // >
          //   {row.expertAnswer}
          // </div>
        ),
    },
    ...(isExpertManager() ? [
      {
        name: "اجابة رئيس الخبراء",
        sortable: true,
        center: true,
        width: "135px",
        style: {
          borderLeft: "2px solid #dfdfdf !important",
        },
        cell: (row) =>
          row.expertManagerAnswer == null ? (
            <div className="flex items-center">
              <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            </div>
          ) : (
            <div
              className="text-xs bg-opacity-15"
              style={{
                backgroundColor: "rgba(0,0,0, 0.025)",
                // color: "white",
                padding: "2px 12px 6px",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: row?.expertManagerAnswerColor,
                }}
                className={`rounded-full h-[10px] w-[10px]  ml-2 `}
              ></div>
              <span className="mb-1">{row.expertManagerAnswer}</span>

              &nbsp;
              {row.expertManagerAnswer === "توجد اجابة" ? (
                <img src={checkGreenCircle} alt="done mark" width={"16px"} />
              ) : (
                <img
                  src={removeCircleDarkRed}
                  width={"16px"}
                  alt="not answered standard"
                />
              )}
            </div>
          ),
      },
    ] : []
    ),
    {
      name: "حالة التدقيق",
      sortable: true,
      center: true,
      width: '130px',
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) => (
        <div className="text-center rounded-full ">
          <IconBasedOnRevieweStatus row={row} />
        </div>
      ),
    },
    {
      name: "الاجراءات",
      sortable: true,
      center: true,
      width: "130px",
      cell: (row) => (
        <div className="flex gap-4 items-center">
          <NewTooltip content="اجابة الجهة">
            <Link
              to={`/programs/${surveyId()}/program/${selectedAgency?.value}/entity/${row.id}/standard`}
              className="block w-8 h-8"
            >
              <img
                src={drawLeftGreen}
                alt="drawLeft Green icon"
              />
            </Link>
          </NewTooltip>

          <NewTooltip content="بيانات الجهة">
            <Link
              to={`/entities/${selectedAgency?.value}/details`}
              className="block w-6 h-6 mb-1"
            >
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-500 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer "
              />
            </Link>
          </NewTooltip>
        </div>
      ),
    },
  ].filter(Boolean);

  const handleRowClick = (row) => {
    history.push(`/programs/${surveyId()}/program/${selectedAgency?.value}/entity/${row.id}/standard`);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    refetchGetStandardsByAgencyId({
      currentPage: newOffset,
      agencyId: selectedAgency?.value,
      reviewedStatus,
      filterValue: null,
      maxResult: maxResult.value,
    })
  };

  return (
    <div>
      {/* <div className="px-5">
        <Title
          subTitle={"الجهات"}
          iconTitle={programsIcon}
          subTitleSeconed={"المعايير"}
          seconed={permissionsCheck(true)}
          thired={permissionsCheck(false)}
          title={"قياس التقنيات الناشئة"}
          withoutButton={true}
          checked={checkedAnswer}
          withSwitch={false}
          toPageSeconed={() => history.push("/programs")}
          toPage={() => history.push("/programs")}
        />
      </div> */}

      <div className="flex-1 max-w-[500px]">
        <DropdownMultiSelect
          placeholder={"اختر جهة حكومية"}
          isClearable={true}
          withoutLabel={false}
          isSearchable={true}
          value={selectedAgency}
          handleChange={handleChangeAgency}
          options={allAgenciesOptions || []}
          isMultiple={false}
          isRequired={false}
          label={"اختر جهة حكومية"}
        />
      </div>
      <StandardEntitiesTable
        customColumns={columns}
        maxResult={maxResult}
        setMaxResult={setMaxResult}
        entities={allAgenciesByStandardId}
        loading={isGettingAllAgenciesByStandardId}
        reviewedStatus={reviewedStatus}
        filterValue={null}
        handleCustomRowClick={handleRowClick}
      />

      <div className="pb-5 mx-auto">
        <AppPagination
          loading={isGettingAllAgenciesByStandardId}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export default AgenciesTab;


