import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import NewTooltip from "./NewTooltip";
import AppModal from "./Models/appModal";

function ShowAgencies({ agenciesInRow, headerTitle }) {
  const [openShowModal, setOpenShowModal] = useState(false);
  const [showedAgencies, setShowedAgencies] = useState([]);
  return (
    <>
      <NewTooltip content={"عرض"}>
        <FontAwesomeIcon
          icon={faEye}
          className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
          onClick={() => {
            setOpenShowModal(true);
            setShowedAgencies(
              agenciesInRow.map((ele) => {
                return {
                  label: ele.nameInArabic,
                  value: ele.id,
                };
              })
            );
          }}
        />
      </NewTooltip>

      <AppModal
        isFooter={false}
        headerTitle={headerTitle}
        open={openShowModal}
        setOpen={setOpenShowModal}
        pushBodyDown
      >
        <div className="text-xl h-full pb-2 overflow-auto">
          {
            showedAgencies?.length ?
            <ul>
              {showedAgencies?.map((agency, index) => (
                <li className="mb-2">
                  {index + 1}- {agency?.label}
                </li>
              ))}
            </ul>
            : <p> لا توجد جهات خاصة بهذا القطاع </p>
          }
        </div>
      </AppModal>
    </>
  );
}

export default ShowAgencies;
