import { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { AppInput, DropdownMultiSelect } from "../../../../../../../components";
import { NewForm } from "../../../../../../../components/form/NewForm";
import UploadOrganizationLogo from "./UploadOrganizationLogo";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import ArabicCountryDropdown from "./ArabicCountryDropdown";

const AddEditSuccessStory = ({
  isEdit,
  formRef,
  initialInputsState,
  inputs,
  setInputs,
  defaultSelectedSector,
  allSectorsOptions,
  handleSubmit,
  validate,
}) => {
  console.log('defaultSelectedSector vvvv --->', defaultSelectedSector);

  const [selectedSector, setSelectedSector] = useState(defaultSelectedSector || null);

  useEffect(() => {
    setInputs({
      ...inputs,
      sectorId: defaultSelectedSector?.value,
      sectorTitle: defaultSelectedSector?.label
    });
  }, [defaultSelectedSector])


  const handleChangeSector = (val) => {
    if (val) {
      setSelectedSector(val);
      setInputs({
        ...inputs,
        sectorId: val?.value,
        sectorTitle: val?.label
      })
    }

  }

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value?.trimStart() });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewForm
        initialValues={initialInputsState}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-3">
          <div>
            <DropdownMultiSelect
              name={"sectorId"}
              label={"اختر القطاع"}
              isClearable={true}
              withoutLabel={false}
              isSearchable={true}
              value={selectedSector}
              handleChange={handleChangeSector}
              options={allSectorsOptions || []}
              isMultiple={false}
              isRequired={true}
              placeholder={"اختر قطاع معين"}
              // errorMessage={!inputs.sectorId ? "هذا الحقل مطلوب" : ""}
            />
            <ErrorMessage
              className="text-red-700"
              name="sectorId"
              component="div"
            />
          </div>

          <div>
            <ArabicCountryDropdown
              inputs={inputs}
              setInputs={setInputs}
            />
            <ErrorMessage
              className="text-red-700"
              name="country"
              component="div"
            />
          </div>

          <div className="flex flex-col gap-3">
            <AppInput
              name="projectName"
              value={inputs.projectName}
              onChange={handleChangeInputs}
              label={"اسم المشروع"}
              isRequired={true}
              maxLength={50}
            />
            <ErrorMessage
              className="text-red-700"
              name="projectName"
              component="div"
            />
          </div>
          <div className="flex flex-col gap-3">
            <AppInput
              name="organizationName"
              value={inputs.organizationName}
              onChange={handleChangeInputs}
              label={"اسم المنظمة"}
              isRequired={true}
              maxLength={50}
            />
            <ErrorMessage
              className="text-red-700"
              name="organizationName"
              component="div"
            />
          </div>

          <UploadOrganizationLogo
            inputs={inputs}
            setInputs={setInputs}
          />

          <div className="flex flex-col gap-3">
            <AppTextarea
              name="organizationTechnology"
              value={inputs.organizationTechnology}
              onChange={handleChangeInputs}
              label={"التقنية"}
              isRequired={true}
              maxNumOfLines={10}
              numOfCharPerLine={50}
            />
            <ErrorMessage
              className="text-red-700"
              name="organizationTechnology"
              component="div"
            />
          </div>
          <div className="flex flex-col gap-3">
            <AppTextarea
              name="organizationImpact"
              value={inputs.organizationImpact}
              onChange={handleChangeInputs}
              label={"الأثر"}
              isRequired={true}
              maxNumOfLines={10}
              numOfCharPerLine={50}
            />
            <ErrorMessage
              className="text-red-700"
              name="organizationImpact"
              component="div"
            />
          </div>
          <div className="flex flex-col gap-3">
            <AppTextarea
              name="organizationApplication"
              value={inputs.organizationApplication}
              onChange={handleChangeInputs}
              label={"التطبيق"}
              isRequired={true}
              maxNumOfLines={10}
              numOfCharPerLine={50}
            />
            <ErrorMessage
              className="text-red-700"
              name="organizationApplication"
              component="div"
            />
          </div>
        </div>
      </NewForm>
    </div>
  );
};

export default AddEditSuccessStory;
