import html2pdf from "html2pdf.js";
import { useRef, useState } from "react";
import slide1 from "../../../features/agencyAnswerReport/assets/images/Slide1.jpg";
import slide10 from "../../../features/agencyAnswerReport/assets/images/Slide10.jpg";
import slide11 from "../../../features/agencyAnswerReport/assets/images/Slide11.jpg";
import slide12 from "../../../features/agencyAnswerReport/assets/images/Slide12.jpg";
import slide13 from "../../../features/agencyAnswerReport/assets/images/Slide13.jpg";
import slide14 from "../../../features/agencyAnswerReport/assets/images/Slide14.jpg";
import slide15 from "../../../features/agencyAnswerReport/assets/images/Slide15.jpg";
import slide16 from "../../../features/agencyAnswerReport/assets/images/Slide16.jpg";
import slide17 from "../../../features/agencyAnswerReport/assets/images/Slide17.jpg";
import slide19 from "../../../features/agencyAnswerReport/assets/images/Slide19.jpg";
import slide2 from "../../../features/agencyAnswerReport/assets/images/Slide2.jpg";
import slide3 from "../../../features/agencyAnswerReport/assets/images/Slide3.jpg";
import slide34 from "../../../features/agencyAnswerReport/assets/images/Slide34.jpg";
import slide36 from "../../../features/agencyAnswerReport/assets/images/Slide36.jpg";
import slide38 from "../../../features/agencyAnswerReport/assets/images/Slide38.jpg";
import slide5 from "../../../features/agencyAnswerReport/assets/images/Slide5.jpg";
import slide6 from "../../../features/agencyAnswerReport/assets/images/Slide6.jpg";
import slide7 from "../../../features/agencyAnswerReport/assets/images/Slide7.jpg";
import slide8 from "../../../features/agencyAnswerReport/assets/images/Slide8.jpg";
import slide9 from "../../../features/agencyAnswerReport/assets/images/Slide9.jpg";
import { PageData } from "../../../features/agencyAnswerReport/PageData";
import { Page18 } from "./Page18";
import Page20 from "./Page20";
import Page21 from "./Page21";
import Page22Interlocutors from "./Page22Interlocutors";
import Page22Perspective from "./Page22Perspective";
import Page35 from "./Page35";
import Page37 from "./page37";
import Page4 from "./Page4";
import { PageWrapper } from "./PageWrapper";

import DiodrumArabicMedium from "../../../assets/fonts/DiodrumArabic-Medium.ttf";
import DownloadButton from "../../../components/DownloadButton";

const HtmlToPdf = ({
  answersAverageStatisics,
  isGettingAnswersAverageStatisics,
  agencyTitle = "[الجهة]",
  agencySector = "[القطاع]",
  agencyLogoUrl = "",
  surveyCommitmentLevelTitle,
  surveyPercentagesAvg,
  surveyPercentageCommitmentLevelColor,
  surveyReports,
  reportType,
  successStories,
  evaluationData,
  SuggestedActivities,
  surveyDifference,
  hidden,
  fileName,
}) => {
  const styles = {
    imagePage: {
      height: "100%",
    },
    indicatorArrowUp: {
      margin: "auto",
      borderStyle: "solid",
      borderColor: "#fff",
      borderWidth: 6,
      borderTopColor: "#7C32C9",
    },
    indicatorArrowDown: {
      margin: "auto",
      borderStyle: "solid",
      borderColor: "#fff",
      borderWidth: 6,
      borderBottomColor: "#7C32C9",
      marginBottom: 8,
    },
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    pageImage: {
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    agencyTitle: {
      marginRight: 4,
      flexWrap: "wrap",
    },
    sectionTitle: {
      color: "#2A2069",
      fontSize: 18,
      display: "flex",
      fontWeight: 500,
      flexWrap: "wrap",
      marginBottom: 10,
      textAlign: "right",
      direction: "rtl",
    },
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: 5,
      textAlign: "right",
    },
    mainSectionTitle: {
      color: "#2A2069",
      fontSize: 24,
      display: "flex",
      flexWrap: "wrap",
      gap: '7px',
      fontWeight: 500,
      marginBottom: 10,
      textAlign: "right",
      direction: "rtl",
    },
    sectionTitleNumber: {
      color: "#1DC183",
    },
    secondarySectionTitle: {
      color: "#1DC183",
      fontSize: 18,
      marginVertical: 5,
      textAlign: "right",
      flexWrap: "wrap",
      display: "flex",
    },
    subtitle: {
      color: "#555",
      fontSize: 10,
      marginVertical: 5,
      textAlign: "right",
      display: "flex",
    },
    progressHeaderContainer: {
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      paddingBottom: 2,
    },
    progressHeaderLabel: {
      width: "30%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
      color: "#7C32C9",
    },
    progressHeaderWeightLabel: {
      width: "10%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
      color: "#7C32C9",
    },
    progressContainer: {
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      backgroundColor: "#7b32c90e",
      border: "1px solid #7C32C9",
      borderRadius: 5,
    },
    progressLabel: {
      width: "30%",
      fontSize: 10,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressWeightLabel: {
      width: "10%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressBarWrapper: {
      width: "55%",
      backgroundColor: "transparent",
      borderRadius: 10,
      height: 12,
      overflow: "hidden",
      transform: "scaleX(-1)",
    },
    progressBar: {
      height: "100%",
      borderRadius: 10,
      transform: "scaleY(-1)",
    },
    progressText: {
      fontSize: 10,
      marginRight: 5,
      width: "10%",
      textAlign: "center",
    },
    totalScore: {
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      backgroundColor: "transparent",
      border: "1px solid #7C32C9",
      borderRadius: 5,
    },
    totalScoreLabel: {
      textAlign: "right",
      fontSize: 14,
      color: "#2A2069",
    },
    totalScoreTextWrapper: {
      width: "50%",
      border: "1px solid #7C32C9",
      alignItems: "center",
      backgroundColor: "#7b32c90e",
      justifyContent: "center",
      borderRadius: 5,
      height: 30,
      marginRight: "auto",
    },
    totalScoreText: {
      fontSize: 14,
      color: "#2A2069",
      marginBottom: "-13px",
    },
    scaleContainer: {
      marginTop: 30,
    },
    scaleTitle: {
      textAlign: "right",
      fontSize: 14,
      marginBottom: 10,
    },
    scale: {
      flexDirection: "row",
      height: 30,
      marginTop: 5,
    },
    scaleItem: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "8px",
    },
    scaleItemText: {
      color: "white",
      fontSize: 10,
      fontWeight: "bold",
    },
    scaleItemFirst: {
      backgroundColor: "#B40002",
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    scaleItemSecond: {
      backgroundColor: "#1DC183",
    },
    scaleItemThird: {
      backgroundColor: "#FF9D00",
    },
    scaleItemFourth: {
      backgroundColor: "#FF6B02",
    },
    scaleItemLast: {
      backgroundColor: "#085133",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    footer: {
      fontSize: 8,
      textAlign: "left",
      color: "#555",
      marginTop: 100,
    },
    fullPage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    bottomInfo: {
      position: "absolute",
      bottom: 24,
      left: 40,
      right: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#666",
      fontSize: 6,
      fontWeight: 300,
    },
    bottomInfo2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 7,
    },
    waterMark: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%) rotate(-45deg)`,
      fontSize: 64,
      color: "gray",
      opacity: 0.2,
    },
    button: {
      backgroundColor: "#7C32C9",
      color: "#FFF",
      borderRadius: 8,
      fontSize: 20,
      textAlign: "center",
      fontWeight: "bold",
      textDecoration: "none",
      width: "100%",
      height: "100%",
      paddingTop: 10,
      position: "absolute",
      top: 0,
    },
    boxShadowParent: {
      position: "relative",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      zIndex: 1,
    },
    boxShadow: {
      position: "absolute",
      borderRadius: 10,
      bottom: 6,
      left: 1,
      width: "80%",
      height: "80%",
      backgroundColor: "#fff",
      zIndex: -1,
    },
  };

  const [loading, setLoading] = useState(true);
  const pdfContentRef = useRef(null);

  const generatePdf = async () => {
    setLoading(true);
      const element = pdfContentRef.current;
      var opt = {
        filename: fileName ?? "تقرير جاهزية تبني التقنيات الناشئة 2025م.pdf",
        html2canvas: { scale: 1 },
        jsPDF: { format: "a4" },
        image: { type: "png", quality: 1 },
        enableLinks: true,
      };

      return html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          let totalPages = pdf.internal.getNumberOfPages();

          pdf.addFont(DiodrumArabicMedium, "DiodrumArabic", "normal");

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            const hight = pdf.internal.pageSize.getHeight() - 6;

            pdf.setFont("DiodrumArabic");

            pdf.setFontSize(9);
            pdf.setTextColor(150);

            pdf.text(`${i}`, pdf.internal.pageSize.getWidth() - 8, hight);

            pdf.text(`تقرير جاهزية تبني التقنيات الناشئة 2025م`, 8, hight);
          }
        })
        .then(() => {
          setLoading(false);
        })
        .save();
  };

  return (
    <div className="">
      <div className="w-full">
        <div
          style={{
            position: "absolute",
            top: "-99999999px",
          }}
        >
          <div ref={pdfContentRef}>
            <PageWrapper>
              <img
                src={slide1}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
              <div className="absolute top-12 left-16 w-20 h-20 rounded-full border border-purple">
                {agencyLogoUrl && (
                  <img
                    style={{
                      borderRadius: "100%",
                      width: "100%",
                      height: "100%",
                    }}
                    src={agencyLogoUrl?.replace("application/octet-stream", "image/png")}
                    alt="logo"
                  />
                )}
              </div>
              <p className="absolute top-[60%] right-36 text-[#1DC183] text-lg">
                التقرير التفصيلي {agencyTitle}
              </p>
              <p className="absolute bottom-44 left-20 text-sm font-bold">
                فبراير 2025 م
              </p>
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide2}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
              <p className="absolute bottom-[43.65%] right-[38.75%] text-sm text-white">
                {agencySector}
              </p>
              <p className="absolute bottom-[40.65%] right-[21.5%] text-sm text-white">
                {agencyTitle}
              </p>
            </PageWrapper>
            <PageWrapper style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide3}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper fullHeight style={{ backgroundColor: "#ffffff" }}>
              <Page4
                agencyTitle={agencyTitle}
                agencySector={agencySector}
                page="4"
              />
            </PageWrapper>
            <PageWrapper style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide5}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide6}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide7}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide8}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide9}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide10}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide11}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide12}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide13}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide14}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide15}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <img
                src={slide16}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide17}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper>
              <Page18
                styles={styles}
                agencyTitle={agencyTitle}
                agencySector={agencySector}
                successStories={successStories}
              />
            </PageWrapper>
            <PageWrapper breakBefore style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide19}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 330,
                  right: 50,
                  color: "#666",
                  fontSize: "30px",
                  width: "700px",
                }}
              >
                نتائج تقييم {agencyTitle}
              </div>
            </PageWrapper>
            <PageWrapper fullHeight style={{ backgroundColor: "#ffffff" }}>
              <Page20
                styles={styles}
                agencyTitle={agencyTitle}
                agencySector={agencySector}
                evaluationData={evaluationData}
                surveyDifference={surveyDifference}
              />
            </PageWrapper>
            <PageWrapper fullHeight style={{ backgroundColor: "#ffffff" }}>
              <Page21
                styles={styles}
                agencyTitle={agencyTitle}
                agencySector={agencySector}
                evaluationData={evaluationData}
                surveyReports={surveyReports}
                agencyLogoUrl={agencyLogoUrl}
                surveyPercentageCommitmentLevelColor={
                  surveyPercentageCommitmentLevelColor
                }
                surveyCommitmentLevelTitle={surveyCommitmentLevelTitle}
                surveyPercentagesAvg={surveyPercentagesAvg}
              />
            </PageWrapper>
            <>
              {PageData(
                surveyReports,
                surveyCommitmentLevelTitle,
                agencyTitle
              )?.map((perspect) => {
                return (
                  <>
                    <PageWrapper fullHeight style={{ backgroundColor: "#fff" }}>
                      <Page22Perspective // القدرات
                        styles={styles}
                        sectionNumber={perspect.sectionNumber}
                        pageTitle={perspect.pageTitle}
                        level={perspect.level}
                        levelColor={perspect.commitmentLevelColor}
                        percentage={perspect.percentage}
                        summary={perspect.summary}
                        perspectiveExpertNotes={perspect.perspectiveExpertNotes}
                        pageNumber={perspect.pageNumber}
                      />
                    </PageWrapper>

                    {[2, 3].includes(reportType) &&
                      perspect?.interlocutors?.map((item) => {
                        return (
                          <PageWrapper style={{ backgroundColor: "#fff" }}>
                            <Page22Interlocutors // العناصر
                              styles={styles}
                              sectionNumber={item.sectionNumber}
                              agencyTitle={agencyTitle}
                              pageTitle={item.pageTitle}
                              level={item.level}
                              levelColor={item.commitmentLevelColor}
                              percentage={item.percentage}
                              property={item.property}
                              standardData={item.standardData}
                              summary={item.summary}
                              elementsCode={item.elementsCode}
                              elementsTitle={item.elementsTitle}
                              elementsSubject={item.elementsSubject}
                              interlocutorExpertNotes={
                                item.interlocutorExpertNotes
                              }
                              pageNumber={item.pageNumber}
                              reportType={reportType}
                            />
                          </PageWrapper>
                        );
                      })}
                  </>
                );
              })}
            </>

            <PageWrapper breakBefore style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide34}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper breakBefore style={{ backgroundColor: "#ffffff" }}>
              <Page35
                SuggestedActivities={SuggestedActivities}
                styles={styles}
              />
            </PageWrapper>
            <PageWrapper breakBefore style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide36}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
            <PageWrapper breakBefore fullHeight>
              <Page37 />
            </PageWrapper>
            <PageWrapper breakBefore style={{ backgroundColor: "#1A184E" }}>
              <img
                src={slide38}
                alt=""
                style={{
                  height: "296.86mm",
                }}
              />
            </PageWrapper>
          </div>
        </div>

        <DownloadButton
          onClick={generatePdf}
          loading={isGettingAnswersAverageStatisics || loading}
        >
          تحميل التقرير النهائي (ملف PDf)
        </DownloadButton>

        {/* <button
          className="bg-red-500
        hover:bg-blue-700
        text-white
        font-bold
        py-2
        px-4
        rounded
        mt-4
      "
          onClick={generatePdf}
        >
          Generate PDF
        </button> */}
      </div>
    </div>
  );
};

export default HtmlToPdf;
