import React, { useState, useEffect, useRef } from "react";
import { FModal, Title } from "../../../components";
import NewHeader from "../../../layouts/header/NewHeader";
import { GroupIcon, programsIcon, settingsIcon } from "../../../assets/icons/Index";
import environment from "../../../services/environment";
import { axiosGetRequest, axiosPostRequest } from "../../../services/Request";

import AppButton from "../../../components/buttons/appButton";
import AgencyCategoriesTable from "./agency/AgencyCategoriesTable";
import AddAgencyCategoryModal from "./agency/AddAgencyCategoryModal";
import { toast } from "react-toastify";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import AddAgencyClassificationModal from "./agency/AddAgencyClassificationModal";
import AgencyClassificationsTable from "./agency/AgencyClassificationsTable";
import AddAgencyRegionModal from "./agency/AddAgencyRegionModal";
import AgencyRegionTable from "./agency/AgencyRegionTable";
import AddAgencySectorModal from "./agency/AddAgencySectorModal";
import AgencySectorTable from "./agency/AgencySectorTable";
import { permissionsCheck } from "../../../helpers/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AppModal from "../../../components/Models/appModal";
import ApproveSectorWithAgencies from "./agency/ApproveSectorWithAgencies";
import { Modal } from "flowbite-react";

const AgencySettings = () => {
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState(3);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const [addClassificationLoading, setAddClassificationLoading] = useState(
    false
  );
  const [addRegionLoading, setAddRegionLoading] = useState(false);
  const [addSectorLoading, setAddSectorLoading] = useState(false);
  const [openApproveSector, setOpenApproveSector] = useState(false);

  const [addCategoryInputs, setAddCategoryInputs] = useState({
    title: "",
    description: "",
  });
  const [categoryAgenceisIds, setCategoryAgenceisIds] = useState([]);

  const [addClassificationInputs, setAddClassificationInputs] = useState({
    title: "",
    nameInArabic: "",
  });
  const [classificationAgenceisIds, setClassificationAgenceisIds] = useState(
    []
  );

  const [addRegionInputs, setAddRegionInputs] = useState({
    name: "",
    nameInArabic: "",
  });
  const [regionAgenceisIds, setRegionAgenceisIds] = useState([]);

  const [addSectorInputs, setAddSectorInputs] = useState({
    name: "",
    nameInArabic: "",
  });
  const [sectorAgenceisIds, setSectorAgenceisIds] = useState([]);

  const [page, setPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "5", value: 5 });

  // LATER
  // getAllAgencyCategories
  const [
    getAllAgencyCategories,
    allAgencyCategories,
    getAllAgencyCategoriesLoading,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyCategories(environment.GetAllAgencyCategory);
  }, []);

  // handleAddAgencyCategory
  const handleAddAgencyCategory = async () => {
    const agenceisIdsQuery = categoryAgenceisIds.map((ele) => ele.value);

    const body = {
      title: addCategoryInputs.title,
      titleArabic: addCategoryInputs.title,
      description: addCategoryInputs.description,
      agenceisIds: agenceisIdsQuery,
    };

    setAddCategoryLoading(true);
    const res = await axiosPostRequest(environment.createAgencyCategory, body);
    setAddCategoryLoading(false);

    if (res?.success) {
      toast.success(`تم إضافة فئة جديدة بنجاح`);
      getAllAgencyCategories(environment.GetAllAgencyCategory);
      setOpenAddModal(false);
      setAddCategoryInputs({
        title: "",
        description: "",
      });
      setCategoryAgenceisIds([]);
    } else {
      toast.error("حدث خطأ");
    }
  };

  const handleAddCategoryInputs = (e) => {
    setAddCategoryInputs({
      ...addCategoryInputs,
      [e.target.name]: e.target.value,
    });
  };

  // LATER
  // getAllAgencyClassifications
  const [
    getAllAgencyClassifications,
    allAgencyClassifications,
    getAllAgencyClassificationsLoading,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyClassifications(environment.getAllAgencyClassification);
  }, []);

  // handleAddAgencyClassification
  const handleAddAgencyClassification = async () => {
    const agenceisIdsQuery = classificationAgenceisIds.map((ele) => ele.value);

    const body = {
      name: addClassificationInputs.nameInArabic,
      nameInArabic: addClassificationInputs.nameInArabic,
      agenceisIds: agenceisIdsQuery,
    };

    setAddClassificationLoading(true);
    const res = await axiosPostRequest(
      environment.createAgencyClassification,
      body
    );
    setAddClassificationLoading(false);

    if (res?.success) {
      toast.success(`تم إضافة تصنيف  جديد بنجاح`);
      getAllAgencyClassifications(environment.getAllAgencyClassification);
      setOpenAddModal(false);
      setAddClassificationInputs({
        title: "",
        nameInArabic: "",
      });
      setClassificationAgenceisIds([]);
    } else {
      toast.error("حدث خطأ");
    }
  };

  const handleAddClassificationInputs = (e) => {
    setAddClassificationInputs({
      ...addClassificationInputs,
      [e.target.name]: e.target.value,
    });
  };

  // LATER
  // getAllAgencyRegions
  const [
    getAllAgencyRegions,
    allAgencyRegions,
    getAllAgencyRegionsLoading,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyRegions(environment.getAllRegion);
  }, []);

  // handleAddAgencyRegion
  const handleAddAgencyRegion = async () => {
    const agenceisIdsQuery = regionAgenceisIds.map((ele) => ele.value);
    const body = {
      name: addRegionInputs.nameInArabic,
      nameInArabic: addRegionInputs.nameInArabic,
      agenceisIds: agenceisIdsQuery,
    };

    setAddRegionLoading(true);
    const res = await axiosPostRequest(environment.createRegion, body);
    setAddRegionLoading(false);

    if (res?.success) {
      toast.success(`تم إضافة منطقة جديدة بنجاح`);
      getAllAgencyRegions(environment.getAllRegion);
      setOpenAddModal(false);
      setAddRegionInputs({
        name: "",
        nameInArabic: "",
      });
      setRegionAgenceisIds([]);
    } else {
      toast.error("حدث خطأ");
    }
  };

  const handleAddRegionInputs = (e) => {
    setAddRegionInputs({
      ...addRegionInputs,
      [e.target.name]: e.target.value,
    });
  };

  // handleAddAgencyRegion
  const handleAddAgencySector = async () => {
    const agenceisIdsQuery = sectorAgenceisIds.map((ele) => ele.value);
    const body = {
      name: addSectorInputs.nameInArabic,
      nameInArabic: addSectorInputs.nameInArabic,
      agenceisIds: agenceisIdsQuery,
    };

    setAddSectorLoading(true);
    const res = await axiosPostRequest(environment.createAgencySector, body);
    setAddSectorLoading(false);

    if (res?.success) {
      console.log('✌️res --->', res);
      setOpenAddModal(false);
      setAddSectorInputs({
        name: "",
        nameInArabic: "",
      });
      setSectorAgenceisIds([]);

      if (res.result?.agencyExceptions?.length) {
        setOpenApproveSector(res.result?.agencyExceptions);
      } else {
        toast.success(`تم إضافة قطاع جديد بنجاح`);
      }
      queryClient.invalidateQueries(["getAllAgencySectors", page, maxResult.value]);
    } else {
      // toast.error("حدث خطأ");
    }
  };

  const handleAddSectorInputs = (e) => {
    setAddSectorInputs({
      ...addSectorInputs,
      [e.target.name]: e.target.value?.trimStart(),
    });
  };

  const tabs = [
    // {
    //   // icon: categoryIcon,
    //   title: "الفئات",
    //   id: 0,
    //   table: "categories",
    //   content: (
    //     <AgencyCategoriesTable
    //       loading={getAllAgencyCategoriesLoading}
    //       allAgencyCategories={allAgencyCategories}
    //       getAllAgencyCategories={getAllAgencyCategories}
    //     />
    //   ),
    // },
    // {
    //   // icon: priorityIcon,
    //   title: "القطاعات",
    //   id: 1,
    //   table: "classifications",
    //   content: (
    //     <AgencyClassificationsTable
    //       loading={getAllAgencyClassificationsLoading}
    //       allAgencyClassifications={allAgencyClassifications}
    //       getAllAgencyClassifications={getAllAgencyClassifications}
    //     />
    //   ),
    // },
    // {
    //   // icon: priorityIcon,
    //   title: "المناطق",
    //   id: 2,
    //   table: "regions",
    //   content: (
    //     <AgencyRegionTable
    //       loading={getAllAgencyRegionsLoading}
    //       allAgencyRegions={allAgencyRegions}
    //       getAllAgencyRegions={getAllAgencyRegions}
    //     />
    //   ),
    // },
    {
      icon: GroupIcon,
      title: "القطاعات",
      id: 3,
      table: "sectors",
      content: (
        <AgencySectorTable
          page={page}
          setPage={setPage}
          maxResult={maxResult}
          setMaxResult={setMaxResult}
          setOpenApproveSector={setOpenApproveSector}
        />
      ),
    },
  ];

  const formAgencyRef = useRef(null);

  const handleSubmitAddAgency = () => {
    if (formAgencyRef.current) {
      formAgencyRef.current?.handleSubmit();
    }
  };

  const formAgencyClassificationRef = useRef(null);

  const handleSubmitAgencyClassification = () => {
    if (formAgencyClassificationRef.current) {
      formAgencyClassificationRef.current?.handleSubmit();
    }
  };

  const formAgencyRegionRef = useRef(null);

  const handleSubmitAgencyRegion = () => {
    if (formAgencyRegionRef.current) {
      formAgencyRegionRef.current?.handleSubmit();
    }
  };

  const formAgencySectorRef = useRef(null);

  const handleSubmitAgencySector = () => {
    if (formAgencySectorRef.current) {
      formAgencySectorRef.current?.handleSubmit();
    }
  };

  const handleApproveSectors = () => {
    console.log("Hello world")
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={settingsIcon}
        style={{ backgroundColor: "#2B2969" }}
        single={true}
        title={"إعدادات الجهات"}
        withIcon={true}
        withoutButton={true}
      />
      <div className="w-full bg-white pb-5 my-5 flex flex-col">
        <div className="w-full flex justify-end h-[50px] px-5">
          <AppButton onClick={() => setOpenAddModal(true)}>
            {activeTab === 0
              ? "إضافة فئة"
              : activeTab === 1
                ? "إضافة تصنيف"
                : activeTab === 2
                  ? "إضافة منطقة"
                  : activeTab === 3
                    ? "إضافة قطاع"
                    : ""}
          </AppButton>
        </div>

        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  "mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] " +
                  (activeTab === tab.id
                    ? "bg-emerald-500 text-white"
                    : "text-slate-400 bg-slate-50")
                }
                onClick={() => setActiveTab(tab.id)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={"24px"}
                  height={"24px"}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>
                {activeTab === tab.id &&
                  tab.content /*<NewTable columns={columns} data={categories}/>*/}
              </div>
            ))}
          </div>
        </div>

        <FModal
          stopScroll={true}
          loading={
            activeTab === 0
              ? addCategoryLoading
              : activeTab === 1
                ? addClassificationLoading
                : activeTab === 2
                  ? addRegionLoading
                  : activeTab === 3
                    ? addSectorLoading
                    : ""
          }
          submitLabel={"إضافة"}
          headerTitle={
            activeTab === 0
              ? "إضافة فئة"
              : activeTab === 1
                ? "إضافة قطاع"
                : activeTab === 2
                  ? "إضافة منطقة"
                  : activeTab === 3
                    ? "إضافة قطاع"
                    : ""
          }
          content={
            activeTab === 0 ? (
              <AddAgencyCategoryModal
                agenceisIds={categoryAgenceisIds}
                setAgenceisIds={setCategoryAgenceisIds}
                innerRef={formAgencyRef}
                onSubmit={handleAddAgencyCategory}
                inputs={addCategoryInputs}
                onChange={handleAddCategoryInputs}
              />
            ) : activeTab === 1 ? (
              <AddAgencyClassificationModal
                agenceisIds={classificationAgenceisIds}
                setAgenceisIds={setClassificationAgenceisIds}
                innerRef={formAgencyClassificationRef}
                onSubmit={handleAddAgencyClassification}
                inputs={addClassificationInputs}
                onChange={handleAddClassificationInputs}
              />
            ) : activeTab === 2 ? (
              <AddAgencyRegionModal
                agenceisIds={regionAgenceisIds}
                setAgenceisIds={setRegionAgenceisIds}
                innerRef={formAgencyRegionRef}
                onSubmit={handleAddAgencyRegion}
                inputs={addRegionInputs}
                onChange={handleAddRegionInputs}
              />
            ) : activeTab === 3 ? (
              <AddAgencySectorModal
                agenceisIds={sectorAgenceisIds}
                setAgenceisIds={setSectorAgenceisIds}
                innerRef={formAgencySectorRef}
                onSubmit={handleAddAgencySector}
                inputs={addSectorInputs}
                onChange={handleAddSectorInputs}
              />
            ) : (
              ""
            )
          }
          open={openAddModal}
          setOpen={() => setOpenAddModal(false)}
          type={"submit"}
          action={
            activeTab === 0
              ? handleSubmitAddAgency
              : activeTab === 1
                ? handleSubmitAgencyClassification
                : activeTab === 2
                  ? handleSubmitAgencyRegion
                  : activeTab === 3
                    ? handleSubmitAgencySector
                    : ""
          }
        />

        <ApproveSectorWithAgencies
          agencies={openApproveSector}
          open={openApproveSector}
          setOpen={setOpenApproveSector}
          handleSubmit={handleApproveSectors}
        />
        {/* <AppModal
          handleSubmit={handleApproveSectors}
          type={"submit"}
          headerTitle={"تأكيد تحديث الجهات الخاصة بالقطاع"}
          open={openApproveSector}
          setOpen={(value) => {
            setOpenApproveSector(value);
          }}
          onClose={() => setOpenApproveSector(false)}
          submitLabel={"تحديث"}
        >
          <ApproveSectorWithAgencies
          />

        </AppModal> */}


      </div>
    </div>
  );
};

export default AgencySettings;
