import { PDFViewer } from "@react-pdf/renderer";
import { DigitalTransformationReport } from "./DigitalTransformationReport";
import { useGetAgencyAnswerReportData } from "./useGetAgencyAnswerReportData";
import AppLoader from "../../components/loader";

const AgencyAnswerReport = ({ selectedAgencyId = 123 }) => {
  const agencyAnswerReportData = useGetAgencyAnswerReportData(selectedAgencyId);

  console.log("testData", {
    agencyAnswerReportData,
  });

  return (
    <div className="bg-white rounded-lg m-5 p-5">
      {agencyAnswerReportData?.isGettingAnswersAverageStatisics ? (
        <div className="h-[400px] w-full flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <PDFViewer width="100%" height="1300px">
          <DigitalTransformationReport {...agencyAnswerReportData} />
        </PDFViewer>
      )}
    </div>
  );
};

export default AgencyAnswerReport;
