import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import SearchIconPDF from "../assets/svgs/SearchIconPDF";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";
import { Footer } from "../components/Footer";
import CheckListIconPDF from "../assets/svgs/CheckListIconPDF";
import { NotesRecommendationTextArea } from "../components/NotesRecommendationTextArea";
import { A4Height, A4Width } from "../PageData";
import { getBgColorOrDefault } from "../../utils";
// import { Subject } from "@microsoft/signalr";

export default function Page21({
  styles,
  agencyTitle,
  surveyPercentagesAvg,
  surveyCommitmentLevelTitle,
  surveyPercentageCommitmentLevelColor,
  surveyReports,
  agencyLogoUrl,
  page = '21',
}) {
  console.log('agencyLogoUrl gggg--->', agencyLogoUrl);

  const surveyExpertNotes = surveyReports?.surveyExpertNotes || [];
  const Err = `نتائج ${agencyTitle} حسب القدرات الرئيسية`;

  const cardWidth = (88 / surveyReports?.perspectives?.length) + '%';
  const style = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    body: {
      paddingTop: 80,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    heading: {
      marginTop: 30,
    },
    title: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
      fontSize: 16,
      textAlign: "right",
      color: "#21C284"
    },
    titleSpan: {
      color: "#C00000",
    },

    main: {
      width: "100%",
      position: "relative",
      marginTop: 20,
      left: 30,
      textAlign: "right",
      fontSize: 11,
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
    logo: {
      position: "absolute",
      zIndex: 10000,
      backgroundColor: "white",
      left: 507,
      width: 90,
      height: 90,
      borderRadius: "50%",
      // border: "2px solid purple", // Circle border
      alignItems: "center",
      justifyContent: "center",
    },
    textLogo: {
      backgroundColor: "white",
      color: "#C00000",
      paddingHorizontal: 5,
      paddingVertical: "auto",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },

    textBoadyBorder: {
      width: "88%",
      minHeight: 55,
      borderRadius: "20%",
      border: "2px solid purple",
      borderRightColor: "transparent",
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      alignItems: "center",
      justifyContent: "center",
      zIndex: -1
    },
    textBody: {
      backgroundColor: "transparent",
      color: "#000",
      fontSize: 11,
      fontWeight: "light",
      textAlign: "center",
      padding: 10,
      paddingRight: 20,
    },
    section: {
      marginTop: 35,
      // marginRight: 25,
      textAlign: "right",
    },
    sectionText: {
      color: "#382F71",
      fontSize: 11,
      marginBottom: 5,
      // border: "2px solid balck",
      // width: "100%",
    },
    cards: {
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      // marginTop: 10,
      marginBottom: 10,
      gap: 3,
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    card: {
      fontSize: 11,
      width: cardWidth,
      height: "100px",
      marginTop: 13,
      paddingTop: 10,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 16,
      // border: "2px solid #7C32C9",
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderRadius: 5,
      zIndex: 1,
    },
    cardShadow: {
      position: "absolute",
      borderRadius: 10,
      top: -6,
      left: 0,
      width: "100%",
      height: "110%",
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      zIndex: -1,
    },
    cardTitleText: {
      color: "#382F71",
      fontSize: 12,
    },
    cardSubjectText: {
      color: "#C00000",
      fontSize: 11,
    },
    cardTextContainer: {
      color: "#C00000",
      width: "83%",
      paddingVertical: 5,
      borderRadius: "20%",
      backgroundColor: "purple",
      border: "2px solid purple",
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
    cardLevel: {
      lineHeight: 0.9,
      padding: "5px",
      flexDirection: "row-reverse",
      // backgroundColor: "#FFFF00",
      color: '#fff',
      alignItems: "center",
      justifyContent: "center",
    },
    resultSection: {
      width: "100%",
      textAlign: "right",
      marginBottom: 20,
      marginTop: 30,
    },
    resultTitle: {
      color: "#1CC182",
      fontSize: 11,
      textAlign: "right",
      marginBottom: 10,
    },
    resultBody: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#C00000",
      fontSize: 14,
      position: "relative",
    },
    resultBodyContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "45%",
      border: "2px solid #382F71",
      borderRadius: "30%",
    },
    resultData: {
      paddingVertical: 15,
      fontSize: 16,
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
    resultLevels: {
      position: "absolute",
      top: 50,
      border: "3px solid #382F71",
      backgroundColor: "#382F71",
      borderRadius: "50%",
      width: "230px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    resultTextLevel: {
      fontSize: 14,
      // backgroundColor: "#FFFF00",
      color: '#fff',
      lineHeight: 1,
      paddingVertical: 10
    },

  });


  const Icons = [
    {
      icon: <SearchIconPDF height={40} width={40} />,
    },
    {
      icon: <SearchIconPDF height={40} width={40} />,
    },
    {
      icon: <SearchIconPDF height={40} width={40} />,
    },
    {
      icon: <SearchIconPDF height={40} width={40} />,
    },
  ];

  return (
    <Page size={{ width: A4Width, height: A4Height + 200 }} style={style.page}>
      <View style={style.heading}>
        <View style={style.title}>
          <Text>
            أبرز المشاهدات للوضع الحالي في
          </Text>
          <Text> {agencyTitle} </Text>
        </View>
      </View>
      <View style={style.main}>
        <View style={style.logo}>
          {
            agencyLogoUrl && <Image style={{ borderRadius: "100%", width: '100%', height: '100%' }} src={agencyLogoUrl ?? ''} />
          }
        </View>
        <View style={style.textBoadyBorder}>
          <Text style={style.textBody}>
            تمكنت {agencyTitle} من تحقيق مستوى {surveyCommitmentLevelTitle} في تقييم مؤشر جاهزية تبني التقنيات الناشئة
          </Text>
        </View>
      </View>

      {/* القدرات */}
      <View>
        <View style={style.section}>
          <Text style={style.sectionText}>{Err}</Text>
        </View>
        <View style={style.cards}>
          {surveyReports?.perspectives?.map((card, indx) => (
            <View key={card.perspectiveTitle} style={style.card}>
              <View style={style.cardShadow}></View>
              <Text style={style.cardTitleText}>{card.perspectiveTitle}</Text>
              <Text style={style.cardSubjectText}>{Number(ifNotDefinedReturnZero(card.userPercentagesAvg))?.toFixed(2)}%</Text>
              <View style={{...style.cardTextContainer, backgroundColor: getBgColorOrDefault(card?.commitmentLevelColor), borderColor: getBgColorOrDefault(card?.commitmentLevelColor)}}>
                <Text style={style.cardLevel}>{card.commitmentLevelTitle}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      {/* النتيجة العامة */}
      <View style={style.resultSection}>
        <View style={style.resultTitle}>
          <Text>النتيجة العامة {agencyTitle}</Text>
        </View>
        <View style={style.resultBody}>
          <View style={style.resultBodyContainer}>
            <Text style={style.resultData}> {Number(ifNotDefinedReturnZero(surveyPercentagesAvg))?.toFixed(2)}% </Text>
          </View>
          <View style={{...style.resultLevels, backgroundColor: surveyPercentageCommitmentLevelColor, borderColor: getBgColorOrDefault(surveyPercentageCommitmentLevelColor)}}>
            <Text style={style.resultTextLevel}> {surveyCommitmentLevelTitle} </Text>
          </View>
        </View>
      </View>

      {/* الوضع الراهن و التوصيات  */}
      <NotesRecommendationTextArea
        expertNotes={surveyExpertNotes}
      />

      <Footer />
    </Page>
  );
}
