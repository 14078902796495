import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { getMaxLengthOfCharsBasedOnLines } from "../../helpers/utils";

const AppTextarea = ({
  rows = "4",
  name,
  value,
  onChange,
  placeholder,
  defaultValue,
  style,
  label,
  isRequired = false,
  readOnly = false,
  errorMessage,
  maxLength,
  numOfCharPerLine,
  maxNumOfLines,
}) => {
  const [maxLengthError, setMaxLengthError] = useState(null);
  return (
    <>
      {label && (
        <label
          className="label_input text-lg block text-[#292069] mb-2"
          htmlFor={name}
        >
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}
      <textarea
        rows={rows}
        id={name}
        defaultValue={defaultValue}
        className="bg-gray_sm rounded w-full border-gray_sm border-1 "
        name={name}
        value={value}
        placeholder={placeholder}
        style={style}
        readOnly={readOnly}
        onChange={(e) => {
          const value = e.target.value?.trimStart();
          if (maxLength) {
            if (value?.length <= maxLength) {
              onChange(e);
              setMaxLengthError(null);
            } else {
              setMaxLengthError(`لا يمكن ادخال اكثر من ${maxLength} حرف`);
            }
          } else if (maxNumOfLines) {
            const validationDataObj = getMaxLengthOfCharsBasedOnLines(value, numOfCharPerLine, maxNumOfLines);
            if (validationDataObj?.isValid) {
              onChange(e);
              setMaxLengthError(null);
            } else {
              setMaxLengthError(`لا يمكن ادخال اكثر من ${maxNumOfLines * numOfCharPerLine} حرف و ${maxNumOfLines} سطور`);
            }
          } else {
            onChange(e);
          }
        }}
      />

      {errorMessage && <p className="text-[#ce8602]">{errorMessage}</p>}
      {maxLengthError && <p className="text-[#ce8602]">{maxLengthError}</p>}

    </>
  );
};

export default AppTextarea;
