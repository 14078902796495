import { Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
// import Slide4 from "../assets/images/Slide4.jpg";


export default function Page4({
  agencyTitle,
  agencySector,
  page = '4'
}) {
  const style = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    container: {
      marginTop: 80,
      marginRight: 10,
    },
    header: {
      textAlign: "right",
      flexDirection: "row-reverse",
      gap: 4,
      fontSize: 20,
      // marginTop: 30,
    },
    headerTextNum1: {
      color: "#30B880",
    },
    headerTextNum2: {
      color: "#7C32C9",
    },
    headerText: {
      color: "#3F3578",
    },
    subjectText: {
      color: "#2B206A",
      width: "100%",
      paddingVertical: "20px",
      textAlign: "right",
      fontSize: 10,
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "flex-end",
      flexWrap: "wrap",
    },
  });
  return (
    <Page style={style.page}>
      <View style={style.container}>
        <View style={style.header}>
          <Text style={style.headerTextNum1}> .1</Text>
          <Text style={style.headerText}>تمهيد</Text>
        </View>
        <View>
          <View style={style.subjectText}>
            <Text>
              استناداً إلى قرار مجلس الوزراء رقم (418) وتاريخ 1442/07/25هـ
              الصادر بالموافقة على تنظيم هيئة الحكومة الرقمية على أن تتولى
              الهيئة إصدار القياسات والمؤشرات والأدوات والتقارير لقياس أداء
              الجهات الحكومية وقدراتها في مجال الحكومة الرقمية، ورضا المستفيد
              عنها و تقديم المساندة للجهات الحكومية فيما يتعلق بخدمات الحكومة
              الرقمية، لتبنّي وتمكين التقنيات الناشئة. تم إعداد التقرير
              التفصيلي
              <Text style={{ textAlign: "right" }}> ل {agencyTitle}</Text>
              <Text>
                لعام 2025م لفهم الوضع الحالي بالهيئة ووضع الخطط  اللـا زمة للتحسين
              </Text>
            </Text>
          </View>
        </View>
        <View style={{ ...style.header, marginTop: 150 }}>
          <Text style={style.headerTextNum2}> 1.1</Text>
          <Text style={style.headerText}>الهدف من التقرير</Text>
        </View>
        <View>
          <View style={style.subjectText}>
            <Text>
              يهدف هذا التقرير لتقييم الادارات التابعة لـ
              <Text style={{ textAlign: "right" }}> {agencyTitle} </Text>
              <Text>
                ، ويستند التقرير إلى إطار مؤشر جاهزية تبني التقنيات الناشئة، مع
                تقديم نتائج تقييم جاهزية الجهة بناءً على المستويات الخمسة، وهي:
                متميز ,متقدم ,متمكن ,متطور وناشئ بالإضافة إلى بعض من تطبيقات
                التقنيات الناشئة عالميًّا في قطاع
              </Text>
              <Text style={{ textAlign: "right" }}> {agencySector}. </Text>
              <Text style={{ textAlign: "right" }}>
                {" "}
                كما يستعرض التقرير لمحة عن الوضع الراهن، ويحدّد مدى استعداد
                الجهة لتبني التقنيات الناشئة، وأبرز الاستنتاجات والتوصيات وخطط
                العمل المقترحة التي تم التوصل لها من خلال تحليل النتائج
              </Text>
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </Page>
  );
}
