import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";
import Picture1 from "../assets/images/Picture1.PNG";
import Picture2 from "../assets/images/Picture2.PNG";
import Picture3 from "../assets/images/Picture3.PNG";
import Picture4 from "../assets/images/Picture4.PNG";

import { evaluationResult } from "../PageData";

export default function Page20({ styles, agencyTitle, agencySector, page = 20, evaluationData, surveyDifference }) {
  const evCardWidth = `${90 / 4} %`
  const pageStyles = StyleSheet.create({
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: 5,
      textAlign: "right",
    },
    sectionTitleNumber: {
      color: "#1DC183",
    },
    cardHeader: {
      backgroundColor: "#7C32C9",
      fontSize: 14,
      paddingTop: 7,
      color: "#fff",
      marginBottom: 2,
    },
    cardItemContainer: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      gap: 2,
    },
    cardItemWrapper: {
      flexGrow: 1,
      fontSize: 14,
      textAlign: "center",
    },
    cardItemHeader: {
      backgroundColor: "#7C32C9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    },
    cardItemBody: { minHeight: 70, backgroundColor: "#F5F5F5" },

    tabelContainer: {
      fontSize: 14,
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
      color: "#1DC183",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1",
      textAlign: "center",
    },
    tableHeaderCell: {
      paddingTop: 10,
      paddingHorizontal: 5,
      width: "20%",
      fontWeight: 500,
    },
    tableBodyCell: {
      textAlign: 'center',
      paddingHorizontal: 30,
      paddingVertical: 10,
      width: "20%",
      height: '100%',
      lineHeight: 1.2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
    },
    perspectiveTitle: {
    },
    tableBody: {
      display: "flex",
      flexDirection: "row-reverse",
      // alignItems: "center",
      justifyContent: "space-between",
      color: "#2A2069",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1",
      position: 'relative',
    },
    tableLastCell: {
      color: "#1DC183",
      backgroundColor: "rgb(28 192 129 / 20%)",
    },


    resultSection: {
      width: "100%",
      marginBottom: 20,
      marginTop: 30,
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      textAlign: "center",
    },
    resultBody: {
      fontSize: 14,
      position: "relative",
      width: evCardWidth,
    },
    resultBodyContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      // width: evCardWidth,
      border: "2px solid #382F71",
      borderRadius: 10,
    },
    resultData: {
      paddingTop: 30,
      fontSize: 14,
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
    resultLevels: {
      position: "absolute",
      top: -20,
      left: 5,
      border: "3px solid #1DC183",
      backgroundColor: "#1DC183",
      borderRadius: 15,
      width: "110px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      display: 'flex',
      flexDirection: 'row',
      gap: 0
    },
    resultTextLevel: {
      fontSize: 14,
      color: '#fff',
      lineHeight: 1,
      paddingVertical: 10,
      paddingHorizontal: 0,
    },
    icon: {
      width: '20px',
      height: '20px',
      marginBottom: '10px',
    },
  });

  const tableData = [
    <Text style={{ color: "#1DC183" }}>البحث</Text>,
    <Text style={{ color: "#1DC183" }}>التواصل</Text>,
    <Text style={{ color: "#1DC183" }}>الثبات</Text>,
    <Text style={{ color: "#1DC183" }}>التكامل</Text>,
  ];

  interface ITableData {
    "perspectiveTitle": string,
    "persPectiveDegree2023": number,
    "persPectiveDegree2024": number,
    "persPectiveDegree2025": number,
    "persPectiveDifference": number | string,
  };

  const isShow2023 = evaluationResult(evaluationData)?.isShow2023;
  const isShow2024 = evaluationResult(evaluationData)?.isShow2024;
  const isNewAgency = !isShow2023 && !isShow2024;
  const icons = [Picture1, Picture2, Picture3, Picture4];

  const EvaluationTable = ({ data }: { data: ITableData[] }) => {

    return (
      <View style={pageStyles.tabelContainer}>
        {/* ---------------- table header ---------------- */}
        <View style={pageStyles.tableHeader}>
          <View style={pageStyles.tableHeaderCell}>
            <Text>القدرات</Text>
          </View>
          {
            isShow2023 &&
            <View style={pageStyles.tableHeaderCell}>
              <Text>الدورة الأولى 2023م</Text>
            </View>
          }
          {
            isShow2024 &&
            <View style={pageStyles.tableHeaderCell}>
              <Text>الدورة الثانية 2024م</Text>{" "}
            </View>
          }
          <View style={pageStyles.tableHeaderCell}>
            <Text>الدورة الثالثة 2025م</Text>{" "}
          </View>
          <View style={{ ...pageStyles.tableHeaderCell, ...pageStyles.tableLastCell }}>
            <Text>نسبة التغير علي القدرة</Text>
          </View>
        </View>
        {/* ---------------- table body ---------------- */}
        {data?.length && data?.map((item, index) => (
          <View style={pageStyles.tableBody}>
            <View style={{ ...pageStyles.tableBodyCell }}>
              <Image src={icons[index % 4]} style={pageStyles.icon} />

              <Text>{item?.perspectiveTitle}</Text>{" "}
            </View>
            {
              isShow2023 &&
              <View style={pageStyles.tableBodyCell}>
                <Text>{ifNotDefinedReturnZero(item?.persPectiveDegree2023)?.toFixed(2)}</Text>{" "}
              </View>
            }
            {
              isShow2024 &&
              <View style={pageStyles.tableBodyCell}>
                <Text>{ifNotDefinedReturnZero(item?.persPectiveDegree2024)?.toFixed(2)}</Text>{" "}
              </View>
            }
            <View style={pageStyles.tableBodyCell}>
              <Text>{ifNotDefinedReturnZero(item?.persPectiveDegree2025)?.toFixed(2)}</Text>{" "}
            </View>
            <View style={{ ...pageStyles.tableBodyCell, ...pageStyles.tableLastCell }}>
              <Text>{isNewAgency ? "N/A" : ifNotDefinedReturnZero(item?.persPectiveDifference)?.toFixed(2)}</Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <Page style={styles.page}>
      <View style={styles.mainSectionTitle}>
        <Text style={pageStyles.sectionTitleNumber}> .5</Text>
        <Text> {`نتائج تقييم ${agencyTitle}`} </Text>
      </View>

      {/* مقارنة نتائج */}
      <View>
        <View style={{ ...styles.secondarySectionTitle, fontSize: 16 }}>
          <Text>مقارنة نتائج جاهزية تبنّي التقنيات الناشئة في </Text>
          <Text style={{ ...styles.agencyTitle, fontSize: 13 }}>{agencySector}</Text>
        </View>

        <View style={pageStyles.resultSection}>
          {
            evaluationResult(evaluationData)?.totalEvaluation?.map(item => {
              return item?.isShow ?
                <View style={pageStyles.resultBody}>
                  <View style={pageStyles.resultBodyContainer}>
                    <Text style={pageStyles.resultData}> {Number(ifNotDefinedReturnZero(item?.value))?.toFixed(2)}% </Text>
                  </View>
                  <View style={pageStyles.resultLevels}>
                    <Text style={pageStyles.resultTextLevel}> م </Text>
                    <Text style={pageStyles.resultTextLevel}> {item?.title} </Text>
                  </View>
                </View>
                : null
            })
          }
          <View style={{ ...pageStyles.resultBody, width: '30%' }}>
            <View style={pageStyles.resultBodyContainer}>
              <Text style={pageStyles.resultData}> {isNewAgency ? "N/A" : Number(ifNotDefinedReturnZero(surveyDifference))?.toFixed(2) + "%"} </Text>
            </View>
            <View style={{ ...pageStyles.resultLevels, width: '95%' }}>
              <Text style={pageStyles.resultTextLevel}> مقدار التغيير الكلي </Text>
            </View>
          </View>
        </View>
      </View>

      <EvaluationTable data={evaluationResult(evaluationData)?.perspectiveCategoryEvaluation} />

      <Footer />
    </Page>
  );
}
