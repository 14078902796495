import DeleteIcon from "../../../../components/DeleteIcon";
import NewTable from "../../../../components/NewTable";
import { useEffect, useRef, useState } from "react";
import environment from "../../../../services/environment";
import EditIcon from "../../../../components/EditIcon";
import { FModal, InputLabel } from "../../../../components";
import {
  axiosDeleteRequest,
  axiosGetRequest,
  axiosPutRequest,
} from "../../../../services/Request";
import { toast } from "react-toastify";
import { NewForm } from "../../../../components/form/NewForm";
import { ErrorMessage } from "formik";
import { permissionsCheck } from "../../../../helpers/utils";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import NewTooltip from "../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import AppModal from "../../../../components/Models/appModal";
import ShowAgencies from "../../../../components/ShowAgencies";
import { useQuery } from "@tanstack/react-query";
import EditAgencySectorModal from "./EditAgencySectorModal";

function AgencySectorTable({ page, setPage, maxResult, setMaxResult, setOpenApproveSector }) {
  const [inputs, setInputs] = useState({
    name: "",
    nameInArabic: "",
    selectedRow: null
  });

  const {
    data: allAgencySectorsData,
    isLoading: loading,
    refetch: getAllAgencySectors,
  } = useQuery({
    queryKey: ["getAllAgencySectors", page, maxResult.value],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencySectors, {
        skipCount: page * maxResult.value,
        maxResultCount: maxResult.value,
      }),
    select: (res) => res?.result,
  });

  const allAgencySectors = allAgencySectorsData?.items || [];
  const totalPages = Math.ceil(allAgencySectorsData?.totalCount / maxResult?.value);


  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const [sectorAgenceisIds, setSectorAgenceisIds] = useState([]);
  const handleAgenciesChange = (value) => {
    setSectorAgenceisIds(value || []);
  };
  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination, {});
  }, []);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item.nameInArabic || "",
        value: item.id,
        name: item.name || "",
      };
    });

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    nameInArabic: "",
    agencies: [],
  };

  const validate = () => {
    const errors = {};

    if (!inputs.nameInArabic) errors.nameInArabic = "هذا الحقل مطلوب";

    if (!sectorAgenceisIds.length) errors.agencies = "هذا الحقل مطلوب";

    return errors;
  };

  // ----------------------------------------------------- //

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleSubmitAgencySector = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };
  const columns = [
    {
      name: "اسم القطاع",
      selector: (row) => row.nameInArabic,
      header: "nameInArabic",
      accessorKey: "nameInArabic",
      footer: (props) => props.column.nameInArabic,
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck("Pages.AgencySectors.Edit") && (
            <div className="flex gap-2">
              <NewTooltip content={"تعديل"}>
                <FontAwesomeIcon
                  icon={faPen}
                  className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                  onClick={() => {
                    setInputs({
                      name: row.nameInArabic,
                      nameInArabic: row.nameInArabic,
                      selectedRow: row
                    });
                    setSectorAgenceisIds(
                      row.agencies.map((ele) => {
                        return {
                          label: ele.nameInArabic,
                          value: ele.id,
                        };
                      })
                    );
                    setOpenEditModal(row);
                  }}
                />
              </NewTooltip>

              {permissionsCheck("Pages.AgencySectors.Delete") ? (
                <DeleteIcon
                  loading={deleteLoading}
                  id={row.id}
                  deleteFunction={() => handleDeleteAgencySector(row.id)}
                  deletedName={row.nameInArabic}
                />
              ) : null}

              <ShowAgencies
                agenciesInRow={row?.agencies}
                headerTitle="الجهات الخاصة بهذا القطاع"
              />
            </div>
          )
        );
      },
    },
  ];

  // handleUpdateAgencySector
  const handleUpdateAgencySector = async (row) => {
    const agenceisIds = sectorAgenceisIds.map((ele) => ele.value);
    const body = {
      id: inputs?.selectedRow?.id,
      name: inputs.nameInArabic,
      nameInArabic: inputs.nameInArabic,
      agenceisIds: agenceisIds,
    };

    setEditLoading(true);
    const res = await axiosPutRequest(environment.updateAgencySector, body);
    setEditLoading(false);
    if (res?.success) {
      if (res.result?.agencyExceptions?.length) {
        getAllAgencySectors();
        setOpenApproveSector(res.result?.agencyExceptions);
      } else {
        toast.info(`تم تحديث القطاع بنجاح`);
        getAllAgencySectors();
      }

    } else {
      toast.error(res?.response?.data?.error?.message);
    }
    setOpenEditModal(false);
  };

  // handleDeleteAgencySector
  const handleDeleteAgencySector = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteAgencySector, {
      Id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getAllAgencySectors();
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setPage(newOffset);
  };

  return (
    <div className="w-full relative">
      {permissionsCheck("Pages.AgencySectors.GetAll") ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={allAgencySectors || []}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={page}
          withMaxResultChange={true}
          maxResultValue={maxResult}
          setMaxResultValue={(val) => {
            setMaxResult(val);
            setPage(0);
          }}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}

      <FModal
        stopScroll={true}
        loading={editLoading}
        submitLabel={"تحديث"}
        headerTitle={"تحديث قطاع"}
        content={
            <EditAgencySectorModal
              setAgenceisIds={setSectorAgenceisIds}
              sectorAgenceisIds={sectorAgenceisIds}
              handleAgenciesChange={handleAgenciesChange}
              allAgenciesOptions={allAgenciesOptions}
              innerRef={formRef}
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleUpdateAgencySector}
              inputs={inputs}
              onChange={handleChangeInputs}
            />
        }
        open={openEditModal}
        setOpen={() => setOpenEditModal(false)}
        type={"submit"}
        action={handleSubmitAgencySector}
      />

    </div>
  );
}

export default AgencySectorTable;
