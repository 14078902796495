export default function Page4({ agencyTitle, agencySector, page = "4" }) {
  const styles = {
    page: {
      padding: "20px",
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    container: {
      marginTop: "80px",
      marginRight: "10px",
    },
    header: {
      textAlign: "right",
      display: "flex",
      gap: "4px",
      fontSize: "24px",
    },
    headerTextNum1: {
      color: "#30B880",
    },
    headerTextNum2: {
      color: "#7C32C9",
    },
    headerText: {
      color: "#3F3578",
    },
    subjectText: {
      color: "#2B206A",
      width: "100%",
      padding: "20px 0",
      textAlign: "right",
      fontSize: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      flexWrap: "wrap",
    },
  };

  return (
    <div style={{ ...styles.page, position: "relative", height: "296.86mm" }}>
      <div style={styles.container}>
        {/* Header Section */}
        <div style={styles.header}>
          <span style={styles.headerTextNum1}>.1</span>
          <span style={styles.headerText}>تمهيد</span>
        </div>
        {/* Subject Section */}
        <div>
          <div style={styles.subjectText}>
            <p>
              استناداً إلى قرار مجلس الوزراء رقم (418) وتاريخ 1442/07/25هـ
              الصادر بالموافقة على تنظيم هيئة الحكومة الرقمية "على أن تتولى
              الهيئة إصدار القياسات والمؤشرات والأدوات والتقارير لقياس أداء
              الجهات الحكومية وقدراتها في مجال الحكومة الرقمية، ورضا المستفيد
              عنها" و"تقديم المساندة للجهات الحكومية فيما يتعلق بخدمات الحكومة
              الرقمية، لتبنّي وتمكين التقنيات الناشئة". تم إعداد "التقرير
              التفصيلي"
              <span> لـ {agencyTitle}</span>
              لعام 2025م لفهم الوضع الحالي بالهيئة ووضع الخطط اللازمة للتحسين.
            </p>
          </div>
        </div>
        {/* Goal Section */}
        <div style={{ ...styles.header, marginTop: "150px" }}>
          <span style={styles.headerTextNum2}>1.1</span>
          <span style={styles.headerText}>الهدف من التقرير</span>
        </div>
        <div>
          <div style={styles.subjectText}>
            <p>
              يهدف هذا التقرير لتقييم الادارات التابعة لـ
              <span>{agencyTitle}</span>، ويستند التقرير إلى إطار مؤشر جاهزية
              تبني التقنيات الناشئة، مع تقديم نتائج تقييم جاهزية الجهة بناءً على
              المستويات الخمسة، وهي: متميز، متقدم، متمكن، متطور وناشئ. بالإضافة
              إلى بعض من تطبيقات التقنيات الناشئة عالميًّا في قطاع
              <span>{agencySector}</span>. كما يستعرض التقرير لمحة عن الوضع
              الراهن، ويحدّد مدى استعداد الجهة لتبني التقنيات الناشئة، وأبرز
              الاستنتاجات والتوصيات وخطط العمل المقترحة التي تم التوصل لها من
              خلال تحليل النتائج.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
