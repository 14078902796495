import { Page, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import Slide35 from "../assets/images/Slide35.jpg";
import { Footer } from "../components/Footer";
import { A4Height, A4Width } from "../PageData";

export default function Page19({ styles, page, SuggestedActivities }) {

  const sectionHeight = `${93 / 4} %`;

  const pageStyles = StyleSheet.create({
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: 5,
      textAlign: "right",
    },
    sectionTitleNumber: {
      color: "#1DC183",
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      paddingHorizontal: 10,
      position: 'relative',
    },
    sectionContainer: {
      position: 'relative',
      textAlign: 'right',
      minHeight: '120px',
      maxHeight: '210px',
    },
    header: {
      fontSize: 14,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 8,
      lineHeight: 1.5,
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      color: 'purple',
    },
    subHeader: {
      fontSize: 11,
      marginTop: 10,
    },
    description: {
      fontSize: 9,
      lineHeight: 1.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minHeight: '70px',
      height: 'auto',
      maxHeight: '170px',
      padding: 10,
      border: '1px solid gray'
    }
  });

  let pageHeight: string | number = A4Height;
  if (SuggestedActivities?.length > 3 && SuggestedActivities?.length < 5) {
    pageHeight = 'auto'
  }
  else if (SuggestedActivities?.length >= 5) {
    pageHeight = A4Height + 150
  }

  return (
    <Page size={{ width: A4Width, height: pageHeight }} style={{...styles.page, paddingBottom: 60}}>
      <View>
        <View style={styles.mainSectionTitle}>
          <Text style={pageStyles.sectionTitleNumber}> 6.1</Text>
          <Text> الخطط المقترحة لتطوير القدرات لدى الجهة </Text>
        </View>

        {/* Suggested plans for perspectives */}
        <View style={pageStyles.main}>
          {
            SuggestedActivities?.map((item, index) => {
              return (
                <>
                  <View wrap={false} style={{ ...pageStyles.sectionContainer, marginTop: ((SuggestedActivities.length > 4 && index === 4) ? 20 : 0) }}>
                    <View style={pageStyles.header}>
                      <Text>
                        {item?.perspectiveTitle || "البحث"}
                      </Text>
                    </View>
                    <View style={pageStyles.subHeader}>
                      <Text> الأنشطة المقترحة​ </Text>
                    </View>
                    <View style={pageStyles.description}>
                      <Text>
                        {item.note || ' مقترحة مقترحة '}
                      </Text>
                    </View>
                  </View>
                </>
              )
            })
          }
        </View>
      </View>

      <Footer />
    </Page>
  );
}
