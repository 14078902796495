import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import environment from "../../../../../../../services/environment";
import { axiosDeleteRequest, axiosGetRequest, axiosPostRequest, axiosPutRequest } from "../../../../../../../services/Request";
import { toast } from "react-toastify";
import AppRadio from "../../../../../../../components/form/appRadio";
import NewSwitch from "../../../../../../../components/NewSwitch";
import NewTable from "../../../../../../../components/NewTable";
import AppButton from "../../../../../../../components/buttons/appButton";
import { surveyId } from "../../../../../../../helpers/isPermission";
import AppLoader from "../../../../../../../components/loader";
import { ifNotDefinedReturnZero, truncateText } from "../../../../../../../helpers/utils";
import { DropdownMultiSelect } from "../../../../../../../components";
import AppModal from "../../../../../../../components/Models/appModal";
import AddEditSuccessStory from "./AddEditSuccessStory";
import EditIcon from "../../../../../../../components/EditIcon";
import DeleteIcon from "../../../../../../../components/DeleteIcon";
import NewTooltip from "../../../../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { countries } from "../constants";

const SuccessStories = () => {

  const initialInputsState = {
    projectName: '',
    country: countries[0]?.value,
    organizationName: '',
    organizationLogo: '',
    organizationImpact: '',
    organizationTechnology: '',
    organizationApplication: '',
  }
  const [inputs, setInputs] = useState({
    sectorTitle: '',
    sectorId: '',
    projectName: '',
    country: countries[0]?.value,
    organizationName: '',
    organizationLogo: '',
    organizationImpact: '',
    organizationTechnology: '',
    organizationApplication: '',
  });
  const addFormRef = useRef(null);
  const editFormRef = useRef(null);

  const [selectedSector, setSelectedSector] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: "5", value: 5 });

  const {
    data: allSectorsOptions,
    isLoading: isLoadingSectors,
    isFetching,
  } = useQuery({
    queryKey: ["getAllAgencySectors"],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencySectors, {
        skipCount: 0,
        maxResultCount: 500,
      }),
    select: (res) => res?.result?.items?.map(item => ({
      label: item.name,
      value: item.id
    })) || [],
  });

  const {
    data: getAllSuccessStory,
    isLoading: isLoadingSuccessStory,
    isFetching: isFetchingSuccessStory,
    refetch: refetchGetAllSuccessStories,
  } = useQuery({
    queryKey: ["GetAllSuccessStory", selectedSector?.value, page, maxResult.value],
    queryFn: () =>
      axiosGetRequest(environment.GetAllSuccessStory, {
        surveyId: surveyId(),
        AgencySectorId: selectedSector?.value ?? null,
        skipCount: page * maxResult.value,
        maxResultCount: maxResult.value,
      }),
    select: (res) => res?.result,
  });

  const allSuccessStory = getAllSuccessStory?.items || [];
  const totalPages = Math.ceil(getAllSuccessStory?.totalCount / maxResult?.value);


  useEffect(() => {
    if (allSectorsOptions?.length) {
      setInputs({
        ...inputs,
        sectorTitle: allSectorsOptions[0]?.label || '',
        sectorId: allSectorsOptions[0]?.value || '',
      });
    }
  }, [allSectorsOptions])

  const handleSubmitAddEdit = (isAdd) => {
    if (isAdd && addFormRef.current) {
      addFormRef.current?.handleSubmit();
    }

    if (!isAdd && editFormRef.current) {
      editFormRef.current?.handleSubmit();
    }
  };

  const AddSuccessStories = async () => {
    const reqBody = {
      "surveyId": surveyId(),
      "agencySectorId": inputs.sectorId || selectedSector?.value,
      "title": inputs?.sectorTitle || selectedSector?.label,
      "country": inputs?.country || "",
      "attachmentId": inputs.organizationLogo,
      "projectName": inputs.projectName || "",
      "organizationName": inputs.organizationName || "",
      "organizationImpact": inputs.organizationImpact || "",
      "organizationTechnology": inputs.organizationTechnology || "",
      "organizationApplication": inputs.organizationApplication || "",
    };

    const res = await axiosPostRequest(environment.CreateSuccessStory, reqBody);

    if (res?.success) {
      toast.success("تم إضافة قصة النجاح");
      setOpenAddModal(false);
      setInputs({ ...initialInputsState })
      refetchGetAllSuccessStories();
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  const EditSuccessStories = async (values) => {
    const reqBody = {
      "id": inputs.successStoryId,
      "surveyId": surveyId(),
      "agencySectorId": inputs.sectorId || selectedSector?.value,
      "title": inputs?.sectorTitle || selectedSector?.label,
      "country": inputs?.country || "",
      "attachmentId": inputs.organizationLogo,
      "projectName": inputs.projectName || "",
      "organizationName": inputs.organizationName || "",
      "organizationImpact": inputs.organizationImpact || "",
      "organizationTechnology": inputs.organizationTechnology || "",
      "organizationApplication": inputs.organizationApplication || "",
    };

    const res = await axiosPutRequest(environment.UpdateSuccessStory, reqBody)

    if (res?.success) {
      toast.success("تم تعديل قصة النجاح");
      setOpenEditModal(false);
      setInputs({ ...initialInputsState })
      refetchGetAllSuccessStories();
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  const deleteSuccessStory = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.DeleteSuccessStory, {
      id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      refetchGetAllSuccessStories();
    }
  };


  const columns = [
    {
      name: "اسم القطاع",
      selector: (row) => row.title,
      header: "title",
      accessorKey: "title",
      cell: (row) => row.title,
    },
    {
      name: "اسم المشروع",
      selector: (row) => row.projectName,
      header: "projectName",
      accessorKey: "projectName",
      cell: (row) => row.projectName,
    },
    {
      name: "اسم المنظمة",
      selector: (row) => row.organizationName,
      header: "organizationName",
      accessorKey: "organizationName",
      cell: (row) => row.organizationName,
    },
    {
      name: "التقنية",
      selector: (row) => row.organizationTechnology,
      header: "organizationTechnology",
      accessorKey: "organizationTechnology",
      cell: (row) => truncateText(row.organizationTechnology, 100),
    },
    {
      name: "الأثر",
      selector: (row) => row.organizationImpact,
      header: "organizationImpact",
      accessorKey: "organizationImpact",
      cell: (row) => truncateText(row.organizationImpact, 100),
    },
    {
      name: "التطبيق",
      selector: (row) => row.organizationApplication,
      header: "organizationApplication",
      accessorKey: "organizationApplication",
      cell: (row) => truncateText(row.organizationApplication, 100),
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      footer: (props) => props.column.actions,
      cell: (row) =>
        <div className="flex gap-2">
          <NewTooltip content={"تعديل"}>
            <FontAwesomeIcon
              icon={faPen}
              className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setInputs({
                  ...inputs,
                  successStoryId: row?.id,
                  sectorTitle: row?.title || null,
                  sectorId: row?.agencySectorId || null,
                  projectName: row?.projectName || null,
                  country: row?.country || countries[0]?.value,
                  organizationName: row?.organizationName || null,
                  organizationLogo: row?.attachmentId || null,
                  descriptionFile: row?.attachmentTitle?.length ? { name: row?.attachmentTitle, size: row?.attachmentSize } : null,
                  organizationImpact: row?.organizationImpact || null,
                  organizationTechnology: row?.organizationTechnology || null,
                  organizationApplication: row?.organizationApplication || null,
                })
                setOpenEditModal(row);
              }}
            />
          </NewTooltip>
          <DeleteIcon
            id={row.id}
            loading={deleteLoading}
            deleteFunction={() => deleteSuccessStory(row.id)}
            deletedName={'قصة النجاح'}
          />
        </div>
      ,
    },

  ];

  const handleChangeSector = (val) => {
    setSelectedSector(val);
    setPage(0);
  }

  const validate = (values) => {
    const errors = {};

    const checkIfFieldIsValid = (key) => {
      if (!inputs[key]) {
        errors[key] = "هذا الحقل مطلوب";
      }
    };

    checkIfFieldIsValid("projectName");
    checkIfFieldIsValid("organizationName");
    checkIfFieldIsValid("organizationLogo");
    checkIfFieldIsValid("organizationImpact");
    checkIfFieldIsValid("organizationTechnology");
    checkIfFieldIsValid("organizationApplication");

    return errors;
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setPage(newOffset);
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      {
        (isLoadingSectors || isFetching) ?
          <div className="flex justify-center items-center min-h-[400px]">
            <AppLoader />
          </div>
          :
          <>
            <div className="flex-1 max-w-[500px]">
              <DropdownMultiSelect
                label={"اختر القطاع"}
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedSector}
                handleChange={handleChangeSector}
                options={allSectorsOptions || []}
                isMultiple={false}
                isRequired={false}
                placeholder={"اختر قطاع معين"}
                loading={isLoadingSectors}
              />
            </div>

            <div className="flex justify-end">
              <AppButton
                onClick={() => setOpenAddModal(true)}
                variant="primary"
              >
                إضافة قصة نجاح
              </AppButton>
            </div>

            <NewTable
              columns={columns}
              data={allSuccessStory}
              loading={isLoadingSuccessStory || isFetchingSuccessStory}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={page}
              withMaxResultChange={true}
              maxResultValue={maxResult}
              setMaxResultValue={(val) => {
                setMaxResult(val);
                setPage(0);
              }} 
              withExcel={false}
              withSearch={false}
            />

            <AppModal
              loading={false}
              handleSubmit={() => handleSubmitAddEdit(true)}
              type={"submit"}
              headerTitle={"إضافة قصة نجاح"}
              open={openAddModal}
              setOpen={setOpenAddModal}
              onClose={() => {
                setOpenAddModal(false);
                setInputs({ ...initialInputsState });
              }}
              submitLabel={"إضافة"}
            >
              <AddEditSuccessStory
                formRef={addFormRef}
                initialInputsState={initialInputsState}
                inputs={inputs}
                setInputs={setInputs}
                handleSubmit={AddSuccessStories}
                validate={validate}
                defaultSelectedSector={selectedSector || allSectorsOptions[0]}
                allSectorsOptions={allSectorsOptions}
              />
            </AppModal>

            <AppModal
              loading={false}
              handleSubmit={() => handleSubmitAddEdit(false)}
              type={"submit"}
              headerTitle={"تعديل قصة نجاح"}
              open={openEditModal}
              setOpen={setOpenEditModal}
              onClose={() => {
                setOpenEditModal(false);
                setInputs({ ...initialInputsState });
              }}
              submitLabel={"تعديل"}
            >
              <AddEditSuccessStory
                formRef={editFormRef}
                initialInputsState={initialInputsState}
                inputs={inputs}
                setInputs={setInputs}
                handleSubmit={EditSuccessStories}
                validate={validate}
                defaultSelectedSector={allSectorsOptions?.find(el => Number(el.value) === ifNotDefinedReturnZero(openEditModal?.agencySectorId)) || null}
                allSectorsOptions={allSectorsOptions}
              />
            </AppModal>

          </>

      }
    </div>
  );
};

export default SuccessStories;