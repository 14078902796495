import React, { useEffect, useMemo, useState } from "react";
import NewTable from "../../../../../../components/NewTable";
import useExcelExport from "../../../../../../helpers/customHooks/useExcelExport";
import { interlocutorColumns } from "./constants";
import { ifNotDefinedReturnZero } from "../../../../../../helpers/utils";


export const InterlocutorTable = ({
    answersAverageStatisics,
    isGettingAnswersAverageStatisics,
    agencyName,
}) => {
    const MemoizedTable = React.memo(NewTable, [
        answersAverageStatisics,
        isGettingAnswersAverageStatisics,
        agencyName,
    ]);

    const downloadExcel = useExcelExport();

    const [interlocutorLsts, setInterlocutorLsts] = useState([]);
    // const [reportType, setReportType] = useState(3);

    useEffect(() => {
        if (answersAverageStatisics && answersAverageStatisics?.interlocutorLsts) {
            setInterlocutorLsts(answersAverageStatisics?.interlocutorLsts);
        }
        // if (answersAverageStatisics?.reportType) {
        //     setReportType(answersAverageStatisics?.reportType)
        // }

    }, [answersAverageStatisics]);


    const handleFilterData = (value) => {
        if (answersAverageStatisics?.interlocutorLsts?.length) {
            const filtered = answersAverageStatisics?.interlocutorLsts?.filter(
                (item) => {
                    const { interlocutorTitle, interlocutorUserPercentagesAvg } = item;
                    return (
                        interlocutorTitle.includes(value) ||
                        interlocutorUserPercentagesAvg.toString().includes(value)
                    );
                }
            );

            setInterlocutorLsts([...filtered]);
        }
    };

    const memoizedTableData = useMemo(() => interlocutorLsts || [], [interlocutorLsts]);
    const memoizedColumns = useMemo(() => interlocutorColumns, []);

    return (
        [2, 3].includes(answersAverageStatisics?.reportType) &&
        <div className="px-5 mb-5 ">
            <h2> العناصر </h2>
            <NewTable
                withSearch
                withExcel
                onExportExcel={() => {
                    const dataForExcel = interlocutorLsts?.map(
                        (ele) => {
                            return {
                                العنصر: ele.interlocutorTitle,
                                النتيجة: Number(ifNotDefinedReturnZero(ele.interlocutorUserPercentagesAvg))?.toFixed(2),
                            };
                        }
                    );

                    downloadExcel(
                        `تقرير التقنيات الناشئة السابقة لجهة ${agencyName}`,
                        dataForExcel
                    );
                }}
                onSearch={(val) => handleFilterData(val, "2")}
                loading={isGettingAnswersAverageStatisics}
                columns={memoizedColumns}
                data={memoizedTableData || []}
                pagination={false}
            />
        </div>

    )
}