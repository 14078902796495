import { Page, Image, StyleSheet, View } from "@react-pdf/renderer";
import Slide7 from "../assets/images/Slide7.jpg";
import { Footer } from "../components/Footer";

export default function Page7({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Image src={Slide7} style={styles.imagePage} />

        <Footer />
      </View>
    </Page>
  );
}
