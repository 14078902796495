import { Page, Image, StyleSheet, View } from "@react-pdf/renderer";
import Slide5 from "../assets/images/Slide5.jpg";
import { Footer } from "../components/Footer";

export default function Page5({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Image src={Slide5} style={styles.imagePage} />

        <Footer light={true} />
      </View>
    </Page>
  );
}
