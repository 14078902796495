import { StyleSheet, Text, View } from "@react-pdf/renderer";
import SearchIconPDF from "../assets/svgs/SearchIconPDF";
import CheckListIconPDF from "../assets/svgs/CheckListIconPDF";

export function NotesRecommendationTextArea({ expertNotes }) {
    const styles = StyleSheet.create({
        NotesTextAreaContainer: {
            width: "100%",
            textAlign: "right",
            color: "#3F3578",
            marginTop: 20,
            fontSize: 13,
            fontWeight: "medium",
            position: "relative",
        },
        NotesTextAreaTitle: {
            flexDirection: "row-reverse",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 10,
        },
        innerFace: {
            position: "absolute",
            width: "99%",
            height: 170,
            overflow: 'hidden',
            borderRadius: "20%",
            backgroundColor: "#F1F1F1",
            padding: '15px',
            top: 30,
            left: 5.5,
            zIndex: 1,
        },
        outerFace: {
            width: "100%",
            minHeight: 180,
            paddingHorizontal: 50,
            border: "1px solid #fefcfc",
            borderRadius: "10%",
            borderTopColor: "#03ABAF",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        },

    });

    return (
        <View>
            {/* الوضع الراهن و التوصيات  */}
            <View style={styles?.NotesTextAreaContainer}>
                <View style={styles?.NotesTextAreaTitle}>
                    <Text style={{ position: "relative", top: 3.3 }}>
                        الوضع الراهن
                    </Text>
                    <View style={{ position: "relative", bottom: 2 }}>
                        <SearchIconPDF height={16} width={16} />
                    </View>
                </View>
                {/* الوضع الراهن و التوصيات  */}
                <View style={styles?.innerFace}>
                    {
                        expertNotes.map(item => {
                            return (
                                <Text> {item.note} -</Text>
                            )
                        })
                    }
                </View>
                <View style={styles?.outerFace}>
                    <Text></Text>
                </View>
            </View>
            <View style={styles?.NotesTextAreaContainer}>
                <View style={styles?.NotesTextAreaTitle}>
                    <Text style={{ position: "relative", top: 3.3 }}>
                        أبرز التوصيات
                    </Text>
                    <View style={{ position: "relative", bottom: 2 }}>
                        <CheckListIconPDF height={20} width={20} />
                    </View>
                </View>
                <View style={styles?.innerFace}>
                    {
                        expertNotes.map(item => {
                            return (<>
                                <Text> {item.recommendation} -</Text>
                            </>
                            )
                        })
                    }
                </View>
                <View style={styles?.outerFace}></View>
            </View>
        </View>
    );
}