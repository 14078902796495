import environment from "../services/environment";
import { axiosPostRequest } from "../services/Request";

export const getAttachmentBase64URL = async (attachmentId, type) => {
    if (attachmentId) {
        const queryParams = new URLSearchParams();
        queryParams.append("id", attachmentId);

        const resp = await axiosPostRequest(
            environment.downloadFile,
            null,
            queryParams,
            true,
            true,
            true
        );

        if (resp) {
            const blob = new Blob([resp], { type: type ?? "application/octet-stream" });

            const base64URL = await blobToBase64(blob);

            // Check if the base64URL is a WebP image and convert it to a supported format
            if (base64URL.startsWith("data:image/webp")) {
                const convertedBase64 = await convertWebPToSupportedFormat(base64URL);
                return convertedBase64;
            }

            return base64URL; // Return the Base64 string for other formats
        }
    }
    return null;
};

export const processImageFromAssets = async (imagePath, type) => {
    try {
        // Fetch the image from the assets folder
        const response = await fetch(imagePath);

        if (!response.ok) {
            throw new Error(`Failed to fetch image from ${imagePath}`);
        }

        const blob = await response.blob();

        const base64URL = await blobToBase64(blob);

        // Check if the Base64 string is a WebP image and convert it
        if (base64URL.startsWith("data:image/webp")) {
            const convertedBase64 = await convertWebPToSupportedFormat(base64URL);
            return convertedBase64;
        }

        return base64URL; // Return the Base64 string for other formats
    } catch (error) {
        console.error("Error processing image from assets:", error);
        return null;
    }
};

// Helper function to convert Blob to Base64
const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Base64 string
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

// Helper function to convert WebP to a supported format (JPEG or PNG)
const convertWebPToSupportedFormat = async (base64URL) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = base64URL;
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const convertedBase64 = canvas.toDataURL("image/png", 1.0); // 1.0 = highest quality
            resolve(convertedBase64);
        };
    });
};
