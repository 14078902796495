import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { agencyId, expertId, expertManagerId, isAgency, reviewerId, surveyId, userId } from "../../helpers/isPermission";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";
import { getAttachmentBase64URL, processImageFromAssets } from "../../hooks/useGetAttachmentBase64URL";
import { ifNotDefinedReturnZero } from "../../helpers/utils";

export const useGetAgencyAnswerReportData = (selectedAgencyId) => {
  const [slidesUrl, setSlidesUrl] = useState({});
  const [agencyLogoUrl, setAgencyLogoUrl] = useState(null);
  const [successStories, setSuccessStories] = useState([]);
  const [isLoadingAgencyLogo, setIsLoadingAgencyLogo] = useState(false);
  const [isLoadingSuccessStories, setIsLoadingSuccessStories] = useState(false);
  const [isLoadingSlides, setIsLoadingSlides] = useState(false);

  const {
    data: answersAverageStatisics,
    isLoading,
    isFetching,
    refetch: GetAnswersAverageStatisics,
  } = useQuery({
    queryKey: [
      "GetAnswersOnPerspectiveInterlocutorStandardAverageStatisics",
      surveyId(),
      userId(),
      selectedAgencyId,
    ],
    queryFn: () =>
      axiosGetRequest(
        environment.GetAnswersOnPerspectiveInterlocutorStandardAverageStatisics,
        {
          agencyId: selectedAgencyId || 0,
          surveyId: surveyId(),
          reviewerId: reviewerId(),
          expertId: expertId(),
          expertManagerId: expertManagerId(),
        }
      ),
    select: (data) => {
      const surveyReports = data?.result?.surveyReports;
      const structureSubStandardRequirementDirectly = data?.result?.structureSubStandardRequirementDirectly;

      const getCommitmentLevelTitle = (item) => structureSubStandardRequirementDirectly
        ? item.evaluationDgreeCommitmentLevelTitle
        : item.percentageCommitmentLevelTitle;

      const getUserPercentagesAvg = (item, key) => structureSubStandardRequirementDirectly
        ? item?.[`${key}UserEvaluationDgreeAvg`]
        : item?.[`${key}UserPercentagesAvg`];

      const getPercentageCommitmentLevelColor = (item) => structureSubStandardRequirementDirectly
        ? item?.evaluationDgreeCommitmentLevelColor
        : item?.percentageCommitmentLevelColor;

      return {
        ...data?.result,
        perspectiveLsts: data?.result?.perspectiveLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          perspectiveUserPercentagesAvg: getUserPercentagesAvg(item, 'perspective'),
          commitmentLevelColor: getPercentageCommitmentLevelColor(item),
        })),
        interlocutorLsts: data?.result?.interlocutorLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          interlocutorUserPercentagesAvg: getUserPercentagesAvg(item, 'interlocutor'),
          commitmentLevelColor: getPercentageCommitmentLevelColor(item),
        })),
        standardLsts: data?.result?.standardLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          standardUserPercentagesAvg: getUserPercentagesAvg(item, 'standard'),
          commitmentLevelColor: getPercentageCommitmentLevelColor(item),
        })),
        surveyReports: {
          ...surveyReports,
          perspectives: surveyReports?.perspectives?.map(item => {
            return {
              ...item,
              commitmentLevelTitle: getCommitmentLevelTitle(item),
              userPercentagesAvg: getUserPercentagesAvg(item, 'perspective'),
              commitmentLevelColor: getPercentageCommitmentLevelColor(item),

              interlocutors: item.interlocutors?.map(interLoc => {
                return {
                  ...interLoc,
                  commitmentLevelTitle: getCommitmentLevelTitle(interLoc),
                  userPercentagesAvg: getUserPercentagesAvg(interLoc, 'interlocutor'),
                  commitmentLevelColor: getPercentageCommitmentLevelColor(interLoc),
                  standards: interLoc.standards?.map(stand => {
                    return {
                      ...stand,
                      commitmentLevelTitle: getCommitmentLevelTitle(stand),
                      userPercentagesAvg: getUserPercentagesAvg(stand, 'standard'),
                      commitmentLevelColor: getPercentageCommitmentLevelColor(stand),
                    }
                  })
                }
              })
            }
          })
        }
      };
    },
    enabled: !!selectedAgencyId,
    staleTime: (0.5 * 60 * 1000),  // Cache data for 5 minutes
  });

  // Fetch Agency Logo URL
  useEffect(() => {
    const fetchLogo = async () => {
      if (answersAverageStatisics?.attachmentId) {
        setIsLoadingAgencyLogo(true);
        const logo = await getAttachmentBase64URL(answersAverageStatisics.attachmentId);
        setAgencyLogoUrl(logo);
        setIsLoadingAgencyLogo(false);
      } else {
        setAgencyLogoUrl(null);
      }
    };
    fetchLogo();
  }, [answersAverageStatisics?.attachmentId]);

  // Fetch Success Stories
  useEffect(() => {
    const fetchSuccessStories = async () => {
      if (answersAverageStatisics?.successStories?.length) {
        setIsLoadingSuccessStories(true);
        const updatedStories = await Promise.all(
          answersAverageStatisics.successStories.map(async (item) => ({
            ...item,
            attachmentUrl: await getAttachmentBase64URL(item.attachmentId),
          }))
        );
        setSuccessStories(updatedStories);
        setIsLoadingSuccessStories(false);
      } else {
        setSuccessStories([]);
      }
    };
    fetchSuccessStories();
  }, [answersAverageStatisics?.successStories]);

  // Fetch Static slides
  useEffect(() => {
    const fetchSlides = async () => {
      setIsLoadingSlides(true);

      // Path relative to the `public` folder
      const Slide10 = `${window.location.origin}/pdfWebpImages/Slide10.webp`;
      const Slide12 = `${window.location.origin}/pdfWebpImages/Slide12.webp`;

      const updatedSlide = {};
      processImageFromAssets(Slide10).then((base64) => {
        updatedSlide.Slide10 = base64; 
      });

      processImageFromAssets(Slide12).then((base64) => {
        updatedSlide.Slide12 = base64; 
      });
      
      console.log("Base64 URL: updatedSlide ssssss", updatedSlide);
      setSlidesUrl(updatedSlide);
      setIsLoadingSlides(false);
    };
    fetchSlides();
  }, [answersAverageStatisics?.agencyName]);

  const surveyDifference = ifNotDefinedReturnZero(answersAverageStatisics?.surveyDifference) ?? 0;
  const reportType = answersAverageStatisics?.reportType ?? "1";
  const agencyTitle = answersAverageStatisics?.agencyName ?? "";
  const agencySector = answersAverageStatisics?.agencySector ?? "";
  const agencyLogoId = answersAverageStatisics?.attachmentId ?? null;
  const surveyReports = answersAverageStatisics?.surveyReports ?? null;
  const surveyTitle = surveyReports?.surveyTitle ?? "";
  const surveyExpertNotes = surveyReports?.surveyExpertNotes ?? "";
  const SuggestedActivities = answersAverageStatisics?.suggestedActivity ?? [];
  const evaluationData = {
    perspectiveCategoryEvaluation: answersAverageStatisics?.perspectiveCategoryEvaluation,
    surveyEvaluation: answersAverageStatisics?.surveyEvaluation,
  }

  const surveyCommitmentLevelTitle = answersAverageStatisics?.structureSubStandardRequirementDirectly
    ? surveyReports?.evaluationDgreeCommitmentLevelTitle
    : surveyReports?.percentageCommitmentLevelTitle;

  const surveyPercentagesAvg = answersAverageStatisics?.structureSubStandardRequirementDirectly
    ? surveyReports?.surveyUserEvaluationDgreeAvg
    : surveyReports?.surveyUserPercentagesAvg;

  const surveyPercentageCommitmentLevelColor = answersAverageStatisics?.structureSubStandardRequirementDirectly
    ? surveyReports?.evaluationDgreeCommitmentLevelColor
    : surveyReports?.percentageCommitmentLevelColor;


  const returnedData = {
    answersAverageStatisics,
    isGettingAnswersAverageStatisics: isFetching || isLoading,
    isLoadingAttachments: isLoadingAgencyLogo || isLoadingSuccessStories || isLoadingSlides,
    GetAnswersAverageStatisics,
    agencyTitle,
    agencySector,
    slidesUrl,
    agencyLogoUrl,
    surveyReports,
    surveyTitle,
    surveyCommitmentLevelTitle,
    surveyPercentagesAvg,
    surveyPercentageCommitmentLevelColor,
    surveyExpertNotes,
    reportType,
    successStories: successStories?.length ? successStories : (answersAverageStatisics?.successStories || []),
    evaluationData,
    surveyDifference,
    SuggestedActivities,
  }

  console.log('✌️answersAverageStatisics hhh--->', { answersAverageStatisics, returnedData, agencyLogoId });

  return returnedData;
};
