import React from "react";
import SearchIconPDF from "../assets/svgs/SearchIconPDF";
import CheckListIconPDF from "../assets/svgs/CheckListIconPDF";

export function NotesRecommendationTextArea({ expertNotes }) {
  return (
    <div className="w-full text-right text-[#3F3578] mt-5 text-sm font-medium relative">
      {/* Current Status */}
      <div className="mb-5">
        <div className="flex flex-row items-center gap-2">
          <span className="font-semibold ">الوضع الراهن</span>
          <span >
            <SearchIconPDF height={16} width={16} />

          </span>
        </div>
          <div className="w-full  mt-3 border border-[#FEFCFC]  border-t-[#03ABAF] border-t-2 border-tl-0 border-tr-0"></div>

        <div className="relative bg-[#F1F1F1] rounded-3xl overflow-hidden p-4 mt-3 h-[170px] z-10">
          {expertNotes.map((item, index) => (
            <p key={index} className="mb-1">{`${item.note} -`}</p>
          ))}
        </div>

      </div>

      {/* Recommendations */}
      <div>
        <div className="flex flex-row items-center gap-2">
          <span className="font-semibold ">أبرز التوصيات</span>
          <span className=" ">
            <CheckListIconPDF height={20} width={20} />
          </span>
        </div>
        <div className="w-full mt-3 border border-[#FEFCFC]  border-t-[#03ABAF] border-t-2 border-tl-0 border-tr-0"></div>

        <div className="relative bg-[#F1F1F1] rounded-3xl overflow-hidden p-4 mt-3 h-[170px] z-10">
          {expertNotes.map((item, index) => (
            <p key={index} className="mb-1">{`${item.recommendation} -`}</p>
          ))}
        </div>

      </div>
    </div>
  );
}
