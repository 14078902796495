import { Image, Page, View } from "@react-pdf/renderer";
import Slide36 from "../assets/images/Slide36.PNG";
import { Footer } from "../components/Footer";
export default function Page36({ styles, page }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Image src={Slide36} style={styles.imagePage} />
        <Footer light={true} />
      </View>

    </Page>
  );
}
