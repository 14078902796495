import { Page, Image, StyleSheet, View } from "@react-pdf/renderer";
import Slide10 from "../assets/images/Slide10WebpJpg.jpg";
import { Footer } from "../components/Footer";
export default function Page10({ styles, slidesUrl }) {
  return (
    <>
      <Page size="A4" style={styles.pageImage}>
        <View style={{ position: 'relative', width: '100%', height: '100%' }}>
          {
            slidesUrl?.Slide10 ?
              <Image src={slidesUrl?.Slide10} style={styles.imagePage} />
              :
              <Image src={Slide10} style={styles.imagePage} />
          }

          <Footer />
        </View>
      </Page>
    </>
  );
}
