import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import AppModal from "../../../../../../components/Models/appModal";
import DownloadButton from "../../../../../../components/DownloadButton";
import AppLoader from "../../../../../../components/loader";
import {
  agencyId,
  expertId,
  isAdminFunc,
  isAgency,
  isExpert,
  isReviewer,
  reviewerId,
  surveyId,
} from "../../../../../../helpers/isPermission";
import { errorToast } from "../../../../../../helpers/toast";
import environment from "../../../../../../services/environment";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../services/Request";
import { useGetAgencyAnswerReportData } from "../../../../../../features/agencyAnswerReport/useGetAgencyAnswerReportData";
import { DigitalTransformationReport } from "../../../../../../features/agencyAnswerReport/DigitalTransformationReport";

import FinalReportFiles from "./FinalReportFiles";
import { PerspectiveTable } from "./PerspectiveTable";
import { StandardTable } from "./StandardTable";
import { InterlocutorTable } from "./InterlocutorTable";
import HtmlToPdf from "../../../../htmlTopdf/htmlTopdf";

const FinalReportPage = () => {

  const [selectedAgency, setSelectedAgency] = useState(null);
  const selectedAgencyId = (agencyId() ?? selectedAgency?.value)

  const agencyAnswerReportData = useGetAgencyAnswerReportData(selectedAgencyId);
  const { answersAverageStatisics, isGettingAnswersAverageStatisics, GetAnswersAverageStatisics, isLoadingAttachments } = agencyAnswerReportData;

  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  const initialInputsState = {
    agency: null,
    attachmentId: "",
    reportTypeValue: '1'
  };
  const [inputs, setInputs] = useState(initialInputsState);
  const [descriptionFile, setDescriptionFile] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");
  const [reportType, setReportType] = useState(3);

  const handleChangeAgency = (selectedAgencyObj) => {
    selectedAgencyObj && setSelectedAgency(selectedAgencyObj || "");
  };

  // Get all agency data
  const { data: allAgenciesOptions, isLoading: isLoadingAgencies } = useQuery({
    queryKey: ["getAllAgencyWithoutPagination", surveyId()],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyWithoutPagination, {
        surveyId: surveyId(),
        expertId: expertId() || reviewerId(),
      }),
    select: (res) =>
      res?.result
        ?.filter((agency) => agency.id && agency.nameInArabic)
        .map((item) => {
          return {
            label: item.nameInArabic || "",
            value: item.id,
            name: item.name || "",
          }
        }),
    staleTime: (5 * 60 * 1000)  // Cache data for 5 minutes
  });

  const {
    data: isReportAtomatic,
    isFetching: isGettingReportStatusByAgency,
  } = useQuery({
    queryKey: [
      "GetReportStatusByAgency",
      selectedAgencyId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetReportStatusByAgency,
        {
          agencyId: selectedAgencyId,
          surveyId: surveyId(),
        },
        true,
        signal
      ),
    select: (data) => data?.result?.isAutomaticReport,
    enabled: !!(selectedAgencyId),
  });


  const {
    data: manualReportByAgency,
    isLoading: isGetttingManualReportByAgency,
    isFetching: isFetchingManualReportByAgency,
    refetch: refetchGetManualReportByAgency,
  } = useQuery({
    queryKey: [
      "GetManualReportByAgency",
      selectedAgencyId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetManualReportByAgency,
        {
          agencyId: selectedAgencyId,
          surveyId: surveyId(),
        },
        true,
        signal
      ),
    select: (data) => data?.result,
    enabled: !!(selectedAgencyId && !isReportAtomatic),
  });
  const attachmentId = manualReportByAgency?.attachmentId;



  useEffect(() => {
    if (selectedAgencyId) {
      GetAnswersAverageStatisics(selectedAgencyId);
    }
  }, [selectedAgencyId]);

  useEffect(() => {
    if (allAgenciesOptions?.length) {
      !selectedAgency?.value && setSelectedAgency(allAgenciesOptions[0])
    }
  }, [allAgenciesOptions]);

  useEffect(() => {
    if (answersAverageStatisics) {
      setInputs({ ...inputs, reportType: answersAverageStatisics?.reportType });
    }
  }, [answersAverageStatisics]);

  const resetInputsValues = () => {
    setDescriptionFile("");
    setDescriptionFileId("");
    setInputs({ ...initialInputsState });
  };

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.agency) {
      errors.agency = "هذا الحقل مطلوب";
    }
    if (!descriptionFileId) {
      errors.attachmentId = "هذا الحقل مطلوب";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const res = await axiosPostRequest(environment.createAdminFinalReport, {
      agencyId: inputs.agency?.value,
      attachmentId: descriptionFileId,
      surveyId: surveyId(),
    });
    if (res?.success) {
      toast.success("تم إضافة الملف بنجاح");
      setOpen(false);
      resetInputsValues();
      refetchGetManualReportByAgency();
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
  };

  const handleDownloadManualPdf = async () => {
    if (attachmentId) {
      const queryParams = new URLSearchParams();

      queryParams.append("id", attachmentId);

      const resp = await axiosPostRequest(
        environment.downloadFile,
        null,
        queryParams,
        true,
        true,
        true
      );

      if (resp) {
        const blob = new Blob([resp], { type: "application/octet-stream" });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = `التقرير النهائي لجهة ${isAgency() ?
          answersAverageStatisics?.agencyName
          :
          selectedAgency?.label
          } 2024.pdf`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }
    else {
      errorToast(
        'لا يوجد ملفات مرفوعة'
      )
    }
  }

  console.log("testData", {
    agencyAnswerReportData,
  });

  const isLoadingPage = !selectedAgencyId || isLoadingAgencies || isGettingAnswersAverageStatisics;

  return (<>
    <div className={`flex justify-center items-center min-h-[400px] ${!isLoadingPage ? 'hidden' : 'visible'}`}>
      <AppLoader />
    </div>

    <div className={`${isLoadingPage ? 'hidden' : 'visible'}`}>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <div className="flex-1 max-w-[500px]">
            {
              !isAgency() &&
              <DropdownMultiSelect
                placeholder={"اختر جهة حكومية"}
                isClearable={false}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgency}
                handleChange={handleChangeAgency}
                options={allAgenciesOptions || []}
                isMultiple={false}
                isRequired={false}
                label={"اختر جهة حكومية"}
                loading={isLoadingAgencies}
              />
            }
          </div>

          <div className="self-end">
            <div className=" flex gap-2">
              {
                isAdminFunc() &&
                <AppButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="purple"
                >
                  إضافة التقرير النهائي
                </AppButton>
              }

              {!(isReviewer() || isExpert()) &&
                (
                  (isGettingReportStatusByAgency || isGetttingManualReportByAgency || isFetchingManualReportByAgency || !selectedAgencyId || isLoadingAttachments) ?
                    <button
                      disabled={isGettingReportStatusByAgency}
                      className={`flex gap-3 pb-3 pt-1 px-5 text-emerald-400 items-center border-2 border-emerald-400 rounded-xl`}
                    >
                      <div className="flex flex-row  justify-center items-center">
                        <Spinner
                          aria-label="Info spinner example"
                          className="text-emerald-400"
                        />
                        <span className="pl-3 pr-3 text-sm font-semibold text-emerald-400">
                          جاري الفحص
                        </span>
                      </div>
                    </button>
                    :
                    (!isReportAtomatic) ?
                      <DownloadButton
                        loading={
                          isGetttingManualReportByAgency || isFetchingManualReportByAgency
                        }
                        onClick={handleDownloadManualPdf}
                      >
                        تحميل التقرير النهائي (ملف PDf)
                      </DownloadButton>
                      :
                      <>
                      <PDFDownloadLink
                        document={
                          <DigitalTransformationReport
                            {...agencyAnswerReportData}
                          />
                        }
                        fileName={`التقرير النهائي لجهة ${isAgency() ?
                          answersAverageStatisics?.agencyName
                          :
                          selectedAgency?.label} 2024.pdf`}
                      >
                        {({ loading }) => (
                          <DownloadButton
                            loading={
                              isGettingAnswersAverageStatisics || loading
                            }
                          >
                            تحميل التقرير النهائي (ملف PDf)
                          </DownloadButton>
                        )}
                      </PDFDownloadLink>
                      {/* <HtmlToPdf {...agencyAnswerReportData} fileName={`التقرير النهائي لجهة ${isAgency() ?
                          answersAverageStatisics?.agencyName
                          :
                          selectedAgency?.label} 2024.pdf`}/> */}
                      </>
                )
              }

            </div>
          </div>
        </div>

        <PerspectiveTable
          answersAverageStatisics={answersAverageStatisics}
          isGettingAnswersAverageStatisics={isGettingAnswersAverageStatisics}
          agencyName={(isAgency() ? answersAverageStatisics?.agencyName : selectedAgency?.label) || ""}
        />

        <InterlocutorTable
          answersAverageStatisics={answersAverageStatisics}
          isGettingAnswersAverageStatisics={isGettingAnswersAverageStatisics}
          agencyName={(isAgency() ? answersAverageStatisics?.agencyName : selectedAgency?.label) || ""}
        />

        <StandardTable
          answersAverageStatisics={answersAverageStatisics}
          isGettingAnswersAverageStatisics={isGettingAnswersAverageStatisics}
          agencyName={(isAgency() ? answersAverageStatisics?.agencyName : selectedAgency?.label) || ""}
        />
      </div>

      <AppModal
        loading={false}
        handleSubmit={handleSubmitAdd}
        type={"submit"}
        headerTitle={"إضافة التقرير النهائي"}
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
          resetInputsValues();
        }}
        submitLabel={"إضافة"}
      >
        <FinalReportFiles
          formRef={formRef}
          isAddMode={false}
          surveyId={surveyId()}
          initialInputsState={initialInputsState}
          inputs={inputs}
          setInputs={setInputs}
          setDescriptionFileId={setDescriptionFileId}
          setDescriptionFile={setDescriptionFile}
          descriptionFile={descriptionFile}
          handleSubmit={handleSubmit}
          validate={validate}
          agenciesOptions={allAgenciesOptions}
          isLoadingAgencies={isLoadingAgencies}
        />
      </AppModal>
    </div>
  </>
  );
};

export default FinalReportPage;
