import React, { useEffect, useMemo, useState } from "react";
import NewTable from "../../../../../../components/NewTable";
import useExcelExport from "../../../../../../helpers/customHooks/useExcelExport";
import { perspectiveColumns } from "./constants";
import { ifNotDefinedReturnZero } from "../../../../../../helpers/utils";

export const PerspectiveTable = ({
    answersAverageStatisics,
    isGettingAnswersAverageStatisics,
    agencyName,
}) => {
    const MemoizedTable = React.memo(NewTable, [
        answersAverageStatisics,
        isGettingAnswersAverageStatisics,
        agencyName,
    ]);

    const downloadExcel = useExcelExport();

    const [perspectiveLsts, setPerspectiveLsts] = useState([]);
    // const [reportType, setReportType] = useState(3);

    useEffect(() => {
        if (answersAverageStatisics && answersAverageStatisics?.perspectiveLsts) {
            setPerspectiveLsts(answersAverageStatisics?.perspectiveLsts);
        }

        // if (answersAverageStatisics?.reportType) {
        //     setReportType(answersAverageStatisics?.reportType)
        // }
    }, [answersAverageStatisics]);

    const handleFilterData = (value) => {
        if (answersAverageStatisics?.perspectiveLsts?.length) {
            const filtered = answersAverageStatisics?.perspectiveLsts?.filter(
                (item) => {
                    const { perspectiveTitle, perspectiveUserPercentagesAvg } = item;
                    return (
                        perspectiveTitle.includes(value) ||
                        perspectiveUserPercentagesAvg.toString().includes(value)
                    );
                }
            );

            setPerspectiveLsts([...filtered]);
        }
    };

    const memoizedTableData = useMemo(() => perspectiveLsts || [], [perspectiveLsts]);
    const memoizedColumns = useMemo(() => perspectiveColumns, []);

    return (
        [1, 2, 3].includes(answersAverageStatisics?.reportType) && (
            <div className="px-5 mb-5 ">
                <h2> القدرات </h2>
                <NewTable
                    withSearch
                    withExcel
                    onExportExcel={() => {
                        const dataForExcel = perspectiveLsts?.map((ele) => {
                            return {
                                القدرة: ele.perspectiveTitle,
                                النتيجة: Number(
                                    ifNotDefinedReturnZero(ele.perspectiveUserPercentagesAvg)
                                )?.toFixed(2),
                            };
                        });

                        downloadExcel(
                            `تقرير التقنيات الناشئة السابقة لجهة ${agencyName}`,
                            dataForExcel
                        );
                    }}
                    onSearch={(val) => handleFilterData(val, "1")}
                    loading={isGettingAnswersAverageStatisics}
                    columns={memoizedColumns}
                    data={memoizedTableData || []}
                    pagination={false}
                />
            </div>
        )
    );
};
