import React, { useEffect, useMemo, useState } from "react";
import NewTable from "../../../../../../components/NewTable";
import useExcelExport from "../../../../../../helpers/customHooks/useExcelExport";
import { standardColumns } from "./constants";
import { ifNotDefinedReturnZero } from "../../../../../../helpers/utils";


export const StandardTable = ({
    answersAverageStatisics,
    isGettingAnswersAverageStatisics,
    agencyName,
}) => {
    const MemoizedTable = React.memo(NewTable, [
        answersAverageStatisics,
        isGettingAnswersAverageStatisics,
        agencyName,
    ]);

    const downloadExcel = useExcelExport();

    const [standardLsts, setStandardLsts] = useState([]);
    // const [reportType, setReportType] = useState(3);

    useEffect(() => {
        if (answersAverageStatisics && answersAverageStatisics?.standardLsts) {
            setStandardLsts(answersAverageStatisics?.standardLsts);
        }
        // if (answersAverageStatisics?.reportType) {
        //     setReportType(answersAverageStatisics?.reportType)
        // }

    }, [answersAverageStatisics]);


    const handleFilterData = (value) => {
        if (answersAverageStatisics?.standardLsts?.length) {
            const filtered = answersAverageStatisics?.standardLsts?.filter(
                (item) => {
                    const { standardTitle, standardUserPercentagesAvg } = item;
                    return (
                        standardTitle.includes(value) ||
                        standardUserPercentagesAvg.toString().includes(value)
                    );
                }
            );

            setStandardLsts([...filtered]);
        }
    };


    const memoizedTableData = useMemo(() => standardLsts || [], [standardLsts]);
    const memoizedColumns = useMemo(() => standardColumns, []);

    return (
        answersAverageStatisics?.reportType === 3 &&
        <div className="px-5 mb-5 ">
            <h2> المعايير </h2>
            <NewTable
                withSearch
                withExcel
                onExportExcel={() => {
                    const dataForExcel = standardLsts?.map((ele) => {
                        return {
                            المعيار: ele.standardTitle,
                            النتيجة: Number(ifNotDefinedReturnZero(ele.standardUserPercentagesAvg))?.toFixed(2),
                        };
                    });

                    downloadExcel(
                        `تقرير التقنيات الناشئة السابقة لجهة ${agencyName}`,
                        dataForExcel
                    );
                }}
                onSearch={(val) => handleFilterData(val, "3")}
                loading={isGettingAnswersAverageStatisics}
                columns={memoizedColumns}
                data={memoizedTableData || []}
                pagination={false}
            />
        </div>
    )
}