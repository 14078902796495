import DiodrumArabic from "../../assets/fonts/DiodrumArabic-Medium.ttf";
import { Document, Font, StyleSheet } from "@react-pdf/renderer";
import Page1 from "./pages/page1.tsx";
import Page2 from "./pages/page2.tsx";
import Page3 from "./pages/page3.tsx";
import Page4 from "./pages/page4.tsx";
import Page5 from "./pages/page5.tsx";
import Page6 from "./pages/page6.tsx";
import Page7 from "./pages/page7.tsx";
import Page8 from "./pages/page8.tsx";
import Page9 from "./pages/page9.tsx";
import Page10 from "./pages/page10.tsx";
import Page11 from "./pages/page11.tsx";
import Page12 from "./pages/page12.tsx";
import Page13 from "./pages/page13.tsx";
import Page14 from "./pages/page14.tsx";
import Page15 from "./pages/page15.tsx";
import Page16 from "./pages/page16.tsx";
import Page17 from "./pages/page17.tsx";
import Page18 from "./pages/Page18";
import Page19 from "./pages/Page19.tsx";
import Page20 from "./pages/Page20";
import Page21 from "./pages/page21.tsx";
import Page22Perspective from "./pages/Page22Perspective.tsx";
import Page22Interlocutors from "./pages/Page22Interlocutors.tsx";
import Page34 from "./pages/page34.tsx";
import Page35 from "./pages/page35.tsx";
import Page36 from "./pages/page36.tsx";
import Page37 from "./pages/page37.tsx";
import Page38 from "./pages/page38.tsx";
import DiodrumArabicBold from "../../assets/fonts/DiodrumArabic-Bold.ttf";
import DiodrumArabicMedium from "../../assets/fonts/DiodrumArabic-Medium.ttf";
import DiodrumArabicSemibold from "../../assets/fonts/DiodrumArabic-Semibold-1.ttf";
import { agencyId } from "../../helpers/isPermission.js";
import { PageData } from "./PageData.js";
import { paginationLimit } from "../../components/appPagination.jsx";
import AppLoader from "../../components/loader.jsx";

// // Register font
Font.register({
  family: "DiodrumArabic",
  fonts: [
    {
      src: DiodrumArabicMedium,
    },
    {
      src: DiodrumArabicSemibold,
      fontWeight: "semibold",
    },
    {
      src: DiodrumArabicBold,
      fontWeight: "bold",
    },
  ],
});

export const DigitalTransformationReport = ({
  answersAverageStatisics,
  isGettingAnswersAverageStatisics,
  agencyTitle = "[الجهة]",
  agencySector = "[القطاع]",
  slidesUrl,
  agencyLogoUrl,
  surveyCommitmentLevelTitle,
  surveyPercentagesAvg,
  surveyPercentageCommitmentLevelColor,
  surveyReports,
  reportType,
  successStories,
  evaluationData,
  surveyDifference,
  SuggestedActivities,
}) => {

  const suggestedPlansPageNum = 22 + (answersAverageStatisics?.perspectiveLsts?.length + answersAverageStatisics?.interlocutorLsts?.length);

  const styles = StyleSheet.create({
    imagePage: {
      height: "100%",
    },
    indicatorArrowUp: {
      margin: "auto",
      borderStyle: "solid",
      borderColor: "#fff",
      borderWidth: 6,
      borderTopColor: "#7C32C9",
    },
    indicatorArrowDown: {
      margin: "auto",
      borderStyle: "solid",
      borderColor: "#fff",
      borderWidth: 6,
      borderBottomColor: "#7C32C9",
      marginBottom: 8,
    },
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
      // padding: 20,
    },
    pageImage: {
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    agencyTitle: {
      marginRight: 4,
      flexWrap: 'wrap',
    },
    sectionTitleNumber: {
      color: "#7C32C9",
    },
    sectionTitle: {
      color: "#2A2069",
      fontSize: 18,
      display: "flex",
      fontWeight: 500,
      flexDirection: "row-reverse",
      flexWrap: 'wrap',
      marginBottom: 10,
      textAlign: "right",
      direction: "rtl",
    },
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: 5,
      textAlign: "right",
    },
    mainSectionTitle: {
      color: "#2A2069",
      fontSize: 24,
      display: "flex",
      flexWrap: 'wrap',
      fontWeight: 500,
      flexDirection: "row-reverse",
      marginBottom: 10,
      textAlign: "right",
      direction: "rtl",
    },
    secondarySectionTitle: {
      color: "#1DC183",
      fontSize: 18,
      marginVertical: 5,
      textAlign: "right",
      flexDirection: "row-reverse",
      flexWrap: 'wrap',
      display: "flex",
    },
    subtitle: {
      color: "#555",
      fontSize: 10,
      marginVertical: 5,
      textAlign: "right",
      flexDirection: "row-reverse",
      display: "flex",
    },
    progressHeaderContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      paddingBottom: 2,
    },
    progressHeaderLabel: {
      width: "30%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
      color: "#7C32C9",
    },
    progressHeaderWeightLabel: {
      width: "10%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
      color: "#7C32C9",
    },
    progressContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      backgroundColor: "#7b32c90e",
      border: "1px solid #7C32C9 ",
      borderRadius: 5,
    },
    progressLabel: {
      width: "30%",
      fontSize: 10,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressWeightLabel: {
      width: "10%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressBarWrapper: {
      width: "55%",
      backgroundColor: "transparent",
      borderRadius: 10,
      height: 12,
      overflow: "hidden",
      transform: "scaleX(-1)",
    },
    progressBar: {
      height: "100%",
      borderRadius: 10,
      transform: "scaleY(-1)",
    },
    progressText: {
      fontSize: 10,
      marginRight: 5,
      width: "10%",
      textAlign: "center",
    },
    totalScore: {
      flexDirection: "row-reverse",
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      backgroundColor: "transparent",
      border: "1px solid #7C32C9",
      borderRadius: 5,
    },
    totalScoreLabel: {
      textAlign: "right",
      fontSize: 14,
      color: "#2A2069",
    },
    totalScoreTextWrapper: {
      width: "50%",
      border: "1px solid #7C32C9 ",
      alignItems: "center",
      backgroundColor: "#7b32c90e",
      justifyContent: "center",
      borderRadius: 5,
      height: 30,
      // paddingTop: "1px",
      marginRight: "auto",
    },
    totalScoreText: {
      fontSize: 14,
      color: "#2A2069",
      marginBottom: "-13px",
    },
    scaleContainer: {
      marginTop: 30,
    },
    scaleTitle: {
      textAlign: "right",
      fontSize: 14,
      marginBottom: 10,
    },
    scale: {
      flexDirection: "row",
      height: 30,
      marginTop: 5,
    },
    scaleItem: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "8px",
    },
    scaleItemText: {
      color: "white",
      fontSize: 10,
      fontWeight: "bold",
    },
    scaleItemFirst: {
      backgroundColor: "#B40002",
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    scaleItemSecond: {
      backgroundColor: "#1DC183",
    },
    scaleItemThird: {
      backgroundColor: "#FF9D00",
    },
    scaleItemFourth: {
      backgroundColor: "#FF6B02",
    },
    scaleItemLast: {
      backgroundColor: "#085133",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    footer: {
      fontSize: 8,
      textAlign: "left",
      color: "#555",
      marginTop: 100,
    },
    fullPage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    bottomInfo: {
      position: "absolute",
      bottom: 24,
      left: 40,
      right: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#666",
      fontSize: 6,
      fontWeight: 300,
    },
    bottomInfo2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 7,
    },
    waterMark: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-180 , -50%) rotate(-45deg)`,
      fontSize: 64,
      color: "gary",
      opacity: 0.2,
    },
    button: {
      backgroundColor: "#7C32C9",
      color: "#FFF",
      borderRadius: 8,
      fontSize: 20,
      textAlign: 'center',
      fontWeight: "bold",
      textDecoration: "none",
      width: '100%',
      height: '100%',
      paddingTop: 10,
      position: 'absolute',
      top: 0,
    },
    boxShadowParent: {
      position: 'relative',
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      zIndex: 1,
    },
    boxShadow: {
      position: "absolute",
      borderRadius: 10,
      bottom: 6,
      left: 1,
      width: "80%",
      height: "80%",
      backgroundColor: "#fff",
      zIndex: -1,
    },

  });

  return (
    isGettingAnswersAverageStatisics ?
      <div className="w-full h-[400px] flex justify-center items-center">
        <AppLoader />
      </div>
      :
      (<Document>
        <Page1 styles={styles} agencyTitle={agencyTitle} date={"فبراير 2025 م"} agencyLogoUrl={agencyLogoUrl} />
        <Page2 styles={styles} agencyTitle={agencyTitle} agencySector={agencySector} />
        <Page3 styles={styles} />
        <Page4 styles={styles} agencyTitle={agencyTitle} agencySector={agencySector} />
        <Page5 styles={styles} />
        <Page6 styles={styles} />
        <Page7 styles={styles} />
        <Page8 styles={styles} />
        <Page9 styles={styles} />
        <Page10 styles={styles} slidesUrl={slidesUrl} />
        <Page11 styles={styles} />
        <Page12 styles={styles} slidesUrl={slidesUrl} />
        <Page13 styles={styles} />
        <Page14 styles={styles} />
        <Page15 styles={styles} />
        <Page16 styles={styles} />
        <Page17 styles={styles} />
        
        <Page18 styles={styles} agencyTitle={agencyTitle} agencySector={agencySector} successStories={successStories} />
        <Page19 styles={styles} agencyTitle={agencyTitle} />
        <Page20 
          styles={styles} 
          agencyTitle={agencyTitle} 
          agencySector={agencySector} 
          evaluationData={evaluationData} 
          surveyDifference={surveyDifference}
        />
        {/* الاستبيان */}
        <Page21
          styles={styles}
          agencyTitle={agencyTitle}
          agencyLogoUrl={agencyLogoUrl}
          surveyCommitmentLevelTitle={surveyCommitmentLevelTitle}
          surveyPercentagesAvg={surveyPercentagesAvg}
          surveyPercentageCommitmentLevelColor={surveyPercentageCommitmentLevelColor}
          surveyReports={surveyReports}
        />


        {
          PageData(surveyReports, surveyCommitmentLevelTitle, agencyTitle)?.map((perspect) => {
            return (
              <>
                <Page22Perspective  // القدرات
                  styles={styles}
                  sectionNumber={perspect.sectionNumber}
                  pageTitle={perspect.pageTitle}
                  level={perspect.level}
                  levelColor={perspect.commitmentLevelColor}
                  percentage={perspect.percentage}
                  summary={perspect.summary}
                  perspectiveExpertNotes={perspect.perspectiveExpertNotes}
                  pageNumber={perspect.pageNumber}
                />

                {
                  [2, 3].includes(reportType) && perspect?.interlocutors?.map(item => {
                    return (
                      <Page22Interlocutors  // العناصر
                        styles={styles}
                        sectionNumber={item.sectionNumber}
                        agencyTitle={agencyTitle}
                        pageTitle={item.pageTitle}
                        level={item.level}
                        levelColor={item.commitmentLevelColor}
                        percentage={item.percentage}
                        property={item.property}
                        standardData={item.standardData}
                        summary={item.summary}
                        elementsCode={item.elementsCode}
                        elementsTitle={item.elementsTitle}
                        elementsSubject={item.elementsSubject}
                        interlocutorExpertNotes={item.interlocutorExpertNotes}
                        pageNumber={item.pageNumber}
                        reportType={reportType}
                      />
                    )
                  })
                }
              </>
            )
          })
        }
        <Page34 styles={styles} page={suggestedPlansPageNum} />
        <Page35 styles={styles} page={suggestedPlansPageNum + 1} SuggestedActivities={SuggestedActivities} />
        <Page36 styles={styles} page={suggestedPlansPageNum + 2} />
        <Page37 styles={styles} page={suggestedPlansPageNum + 3} />
        <Page38 styles={styles} page={suggestedPlansPageNum + 4} />

      </Document>)
  );
};
