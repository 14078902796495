import { useRef, useState } from "react";
import { faAsterisk, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "flowbite-react";
import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import cloud from "../../../../../../../assets/icons/cloud.svg";
import file from "../../../../../../../assets/icons/file.svg";
import NewTooltip from "../../../../../../../components/NewTooltip";
import { errorToast } from "../../../../../../../helpers/toast";
import environment from "../../../../../../../services/environment";
import { post } from "../../../../../../../services/Request";
import { getFileExtension, ifNotDefinedReturnZero } from "../../../../../../../helpers/utils";

const allowedExtensions = ["jpg", "png"];

const UploadOrganizationLogo = ({
    inputs,
    setInputs,
}) => {

    const { t } = useTranslation();

    const fileDescriptionInputRef = useRef(null);
    const [loadingImageDescription, setLoadingImageDescription] = useState(false);

    const handleFileSelect = (e) => {
        fileDescriptionInputRef.current.click();
    };

    const generateUpload = (file, e, values) => {
        setLoadingImageDescription(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        };
        const formData = new FormData();

        formData.append("Name", file.name);
        formData.append("FileType", "image/*");
        formData.append("EntityType", "");
        formData.append("File", file);

        post(environment.attachmentFile, formData, config, (res) => {
            setInputs({
                ...inputs,
                organizationLogo: res.data?.result?.id,
                descriptionFile: file,
            },
                setLoadingImageDescription(false)
            );
        });
    };

    const onChangeFile = (event, e) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0]; // Get the uploaded file
            if (file) {

                console.log('✌️getFileExtension(file?.name)?.toLowerCase() --->', allowedExtensions, getFileExtension(file?.name)?.toLowerCase());
                const incorrectExtensions = !allowedExtensions?.includes(getFileExtension(file?.name)?.toLowerCase());
                if (incorrectExtensions) {
                    errorToast(
                        ` غير مدعوم ${getFileExtension(file?.name)} الملف من نوع`
                    );
                    return false;
                }

                const fileSizeInBytes = file.size; // Get file size in bytes
                const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB
                if (fileSizeInMB > 30) {
                    errorToast("حجم الملف يجب أن لا يتجاوز 30 ميجا");
                    return;
                }
            }

            setInputs({
                ...inputs,
                descriptionFile: event.target.files[0],
            },
                generateUpload(event.target.files[0], 1)
            );
        }

        event.target.value = ""; // Reset the value
    };

    const resetUploadedFiles = () => {
        setInputs({
            ...inputs,
            descriptionFile: null,
            organizationLogo: null
        })
    };


    return (
        <div className="bg-white rounded-[30px] m-5">
            <div className="flex flex-col gap-3">
                {/* ارفاق ملف */}
                <>
                    <label className="label_input mb-2 block text-lg text-[#292069]">
                        شعار المنظمة
                        <span className="mr-1">
                            <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
                        </span>
                    </label>

                    <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9 mt-3">
                        <img src={cloud} alt="cloud" />
                        <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                            يمكنك إرفاق ملف من النوع jpg او png ولا يزيد حجم الملف عن 30 ميجا
                        </p>

                        <input
                            style={{ display: "none" }}
                            isImage={true}
                            ref={fileDescriptionInputRef}
                            type={"file"}
                            id={"fileUpload"}
                            label={t("modules.main.users.users.form.image")}
                            onChange={(event) => onChangeFile(event, 1)}
                            image={inputs?.descriptionFile?.name || ""}
                        />

                        <button
                            type="button"
                            onClick={() => handleFileSelect(1)}
                            className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                        >
                            إرفاق ملف
                        </button>
                    </div>
                    <ErrorMessage
                        className="text-red-700"
                        name="organizationLogo"
                        component="div"
                    />
                    {inputs?.descriptionFile && (
                        <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                            <div className="flex flex-row items-center">
                                <img
                                    src={file}
                                    alt="file"
                                    style={{ width: "15px", height: "15px" }}
                                />
                                {loadingImageDescription ? (
                                    <Spinner aria-label="Info Spinner example" color="info" />
                                ) : (
                                    <p className="text-sm text-[#161318] mr-3">
                                        {inputs?.descriptionFile?.name || ""}
                                    </p>
                                )}
                            </div>
                            <div className="flex flex-row items-center">
                                <p className="text-sm text-[#7D8BAB] ml-3">
                                    {inputs?.descriptionFile?.size ? ifNotDefinedReturnZero(Number(inputs?.descriptionFile?.size))?.toFixed(2) + "KB" : ""}
                                </p>
                            </div>

                            <NewTooltip content={"حذف"}>
                                <FontAwesomeIcon
                                    icon={faX}
                                    onClick={() => {
                                        resetUploadedFiles();
                                    }}
                                    className="text-red-700 bg-rose-50 mr-1 rounded-full p-2 cursor-pointer"
                                />
                            </NewTooltip>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default UploadOrganizationLogo;
