import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import { A4Height, A4Width } from "../PageData";

export default function Page18({ styles, agencyTitle, agencySector, page = '18', successStories }) {

  const cardHeight = '450px'
  const pageStyles = StyleSheet.create({
    description: {
      fontSize: 11,
      textAlign: 'right',
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    cardContainer: {
      borderWidth: 1,
      borderStyle: "dotted",
      borderColor: "#7C32C9",
      borderRadius: 8,
      padding: 8,
      paddingLeft: 0,
      display: "flex",
      flexDirection: "column",
      // alignItems: "stretch",
      justifyContent: "flex-end",
      textAlign: "center",
      marginRight: 10,
      marginTop: 10,
      marginBottom: 20,
      // overflow: 'hidden',
      maxHeight: '440px'
    },
    cardHeaderContainer: {
      backgroundColor: '#fff',
      position: 'absolute',
      top: -16,
      right: -13,
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      flexDirection: 'row-reverse',
      // gap: 10,
      // paddingBottom: 10,
    },
    cardFlag: {
      width: 60,
      height: 30,
      borderRadius: 4,
      padding: 1
    },
    cardHeaderTitle: {
      color: "#1ac082",
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'right',
      borderRadius: 0,
    },
    cardBodyContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: 5,
      fontSize: 10,
      margin: 10,
      marginTop: 15
    },
    cardItemContainer: {
      display: 'flex',
      borderRadius: 10,
      margin: 5,
      width: "47%",
      minHeight: '80px',
      height: 'auto',
      maxHeight: '190px',
      overflow: 'hidden',

    },
    cardItemBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      textAlign: 'right',
      gap: 4,
      padding: 10,
      // height: '90%',
    },
    organizationLogo: {
      width: 80,
      height: 40,
      // border: '1px solid #7C32C9',

    },
  });

  const StatusCard = ({ data }) => {
    return (
      <View wrap={false} style={{ ...pageStyles.cardContainer }}>

        <View style={pageStyles.cardHeaderContainer}>
          <View style={pageStyles.cardFlag}>
            {
              data?.country &&
              <Image style={{ ...pageStyles.cardFlag, width: '100%', height: '100%' }} src={`${window.location.origin}/flags/${data?.country?.toLowerCase()}.png`} />
            }
          </View>
        </View>

        <View style={pageStyles.cardBodyContainer}>

          <View style={{ ...pageStyles.cardItemContainer, ...pageStyles.cardHeaderTitle, width: '20%' }}>
            <Text>
              {data?.projectName}
            </Text>
          </View>

          <View style={{ ...pageStyles.cardItemContainer, ...styles.boxShadowParent, width: '25%' }}>
            <View style={{ ...styles.boxShadow, height: "95%", width: '98%' }}></View>

            <View style={{...pageStyles.cardItemBody, alignItems: 'center'}}>
              <View style={{ fontWeight: 'bold', display: 'flex', flexWrap: 'wrap', alignSelf: 'flex-end' }}>
                <Text> {data?.organizationName} </Text>
              </View>
              <View>
                <View style={pageStyles.organizationLogo}>
                  {
                    data?.attachmentUrl &&
                    <Image style={{ width: '100%', height: '100%' }} src={data?.attachmentUrl ?? ''} />
                  }
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...pageStyles.cardItemContainer, ...styles.boxShadowParent }}>
            <View style={{ ...styles.boxShadow, height: "95%", width: '98%' }}></View>

            <View style={pageStyles.cardItemBody}>
              <View style={{ fontWeight: 'bold' }}>
                <Text> التقنية </Text>
              </View>
              <View>
                <Text> {data.organizationTechnology} </Text>
              </View>
            </View>
          </View>
          <View style={{ ...pageStyles.cardItemContainer, ...styles.boxShadowParent }}>
            <View style={{ ...styles.boxShadow, height: "95%", width: '98%' }}></View>

            <View style={pageStyles.cardItemBody}>
              <View style={{ fontWeight: 'bold' }}>
                <Text> الأثر </Text>
              </View>
              <View>
                <Text> {data.organizationImpact} </Text>
              </View>
            </View>
          </View>
          <View style={{ ...pageStyles.cardItemContainer, ...styles.boxShadowParent }}>
            <View style={{ ...styles.boxShadow, height: "95%", width: '98%' }}></View>

            <View style={pageStyles.cardItemBody}>
              <View style={{ fontWeight: 'bold' }}>
                <Text> التطبيق​ </Text>
              </View>
              <View>
                <Text> {data.organizationApplication} </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  let pageHeight: string | number = 'auto';
  if (successStories?.length > 2) {
    pageHeight = A4Height + 150
  }

  return (
    <Page size={{ width: A4Width, height: pageHeight }} style={{ ...styles.page, minHeight: `${A4Height}px`, paddingBottom: 60 }}>
      <View style={styles.sectionTitle}>
        <Text style={styles.sectionTitleNumber}> 4.1</Text>
        <View style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Text>
            تطبيقات التقنيات الناشئة عالميا في قطاع {agencySector}
          </Text>
        </View>
      </View>
      <View style={pageStyles.description}>
        <Text>
          أبرز تطبيقات التقنيات الناشئة عالميًّا في قطاع {agencySector} 
        </Text>
        <Text> : </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'column', gap: 5 }} >
        {successStories?.length && successStories?.map((data) => (
          <StatusCard data={data} />
        ))}
      </View>
      <Footer />

    </Page>
  );
}
