import { Page, Image, StyleSheet, View } from "@react-pdf/renderer";
import Slide8 from "../assets/images/Slide8.jpg";
import { Footer } from "../components/Footer";
export default function Page8({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Image src={Slide8} style={styles.imagePage} />

        <Footer light={true} />
      </View>
    </Page>
  );
}
