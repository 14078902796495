import { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { AppInput, DropdownMultiSelect } from "../../../../../../../components";
import { NewForm } from "../../../../../../../components/form/NewForm";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import { ifNotDefinedReturnZero } from "../../../../../../../helpers/utils";

const AddEditSuggestedActivities = ({
  isEdit,
  formRef,
  initialInputsState,
  inputs,
  setInputs,
  selectedAgencyId,
  allAgenciesOptions,
  perspectiveList = [],
  handleSubmit,
  validate,
}) => {

  const agencyId = isEdit ? Number(ifNotDefinedReturnZero(inputs?.selectedRow?.agencyId)) : selectedAgencyId;
  const defaultSelectedAgency = allAgenciesOptions?.find(el => Number(el.value) === agencyId);
  const [selectedAgency, setSelectedAgency] = useState(defaultSelectedAgency || null);

  useEffect(() => {
    setInputs({
      ...inputs,
      agencyId: defaultSelectedAgency.value,
      selectedRow: isEdit ? {
        ...inputs.selectedRow,
        agencyId: defaultSelectedAgency.value,
        agencyTitle: defaultSelectedAgency.label
      } : null
    });

  }, [defaultSelectedAgency])

  const handleChangeAgency = (val) => {
    setSelectedAgency(val);
    setInputs({
      ...inputs,
      agencyId: val.value,
      selectedRow: isEdit ? {
        ...inputs.selectedRow,
        agencyId: val.value,
        agencyTitle: val.label
      } : null
    });
  }

  const handleChangeInputs = (e, item) => {
    if (isEdit) {
      setInputs({
        ...inputs,
        selectedRow: {
          ...inputs.selectedRow,
          note: e.target.value?.trimStart()
        },
        perspectiveList: inputs.perspectiveList.map(el => {
          if (el.id === inputs?.selectedRow?.id) {
            el.note = e.target.value?.trimStart()
          }
          return el
        })
      });
    }
    else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        perspectiveList: perspectiveList?.length && [...perspectiveList].map(el => {
          if (Number(el.id) === Number(item.id)) {
            el.note = e.target.value?.trimStart()
          }
          return el
        })
      });
    }
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <Formik
        initialValues={initialInputsState}
        validate={validate}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({
          values,
          errors,
        }) => {
          return (
            <Form>
              <div className="flex flex-col gap-3">
                <div>
                  <DropdownMultiSelect
                    placeholder={"اختر جهة حكومية"}
                    isClearable={true}
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedAgency || allAgenciesOptions?.[0]}
                    handleChange={handleChangeAgency}
                    options={allAgenciesOptions || []}
                    isMultiple={false}
                    isRequired={true}
                    label={"اختر جهة حكومية"}
                    errorMessage={errors['agencyId']}
                  />
                </div>

                {
                  isEdit ?
                    <div className="flex flex-col gap-3">
                      <AppTextarea
                        name={'note'}
                        value={inputs.selectedRow.note}
                        onChange={(val) => handleChangeInputs(val)}
                        label={`الأنشطة المقترحة للقدرة (${inputs.selectedRow?.perspectiveTitle})`}
                        isRequired={true}
                        errorMessage={errors['note']}
                        maxLength={1000}
                      />
                    </div>
                    :
                    perspectiveList?.map(item => {
                      return (
                        <div className="flex flex-col gap-3">
                          <AppTextarea
                            name={`activityNote_${item?.id}`}
                            value={item.note}
                            onChange={(val) => handleChangeInputs(val, item)}
                            label={`الأنشطة المقترحة للقدرة (${item?.title})`}
                            isRequired={true}
                            errorMessage={errors[`activityNote_${item?.id}`]}
                            maxLength={1000}
                          />
                        </div>
                      )
                    })
                }
              </div>
            </Form>
          )
        }
        }

      </Formik>
    </div>
  );
};

export default AddEditSuggestedActivities;
