import { Page, Image, View, Text, StyleSheet } from "@react-pdf/renderer";
import Slide1 from "../assets/images/Slide1.jpg";
import { Footer } from "../components/Footer";

export default function Page1({ styles, agencyTitle, date = "22 يناير ", agencyLogoUrl }) {

  const style = StyleSheet.create({
    logo: {
      position: "absolute",
      top: 30,
      left: 50,
      width: 60,
      height: 60,
      borderRadius: '50%',
      // border: "1px solid purple", // Circle border
      alignItems: "center",
      justifyContent: "center",
    },
  })

  return (
    <Page style={styles.pageImage} size="A4">
      <View
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Image src={Slide1} style={styles.imagePage} />
        <View style={style.logo}>
          {
            agencyLogoUrl && <Image style={{ borderRadius: '100%', width: '100%', height: '100%' }} src={agencyLogoUrl ?? ''} />
          }
        </View>
        <Text
          style={{
            position: "absolute",
            top: 508,
            right: 110,
            color: "#1DC183",
            fontSize: 12,
          }}
        >
          التقرير التفصيلي {agencyTitle}
        </Text>
        <Text
          style={{
            position: "absolute",
            top: 705,
            right: 470,
            color: "black",
            fontSize: 11,
          }}
        >
          {date}
        </Text>
      </View>

      <Footer />
    </Page>
  );
}
