export const Page18 = ({ styles, agencySector, successStories = [] }) => {
  const pageStyles = {
    page: { padding: "20px", backgroundColor: "#fff" },
    sectionTitle: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
    sectionTitleNumber: {
      fontSize: "20px",
      fontWeight: "bold",
      marginRight: "10px",
    },
    cardContainer: {
      border: "1px solid #7C32C9",
      borderRadius: "8px",
      padding: "8px",
      paddingTop: "1.25rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginTop: "40px",
      marginBottom: "20px",
      pageBreakInside: "avoid",
    },
    cardHeaderContainer: {
      backgroundColor: "#fff",
      position: "relative",
      top: "-30px",
      right: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
      paddingLeft: "20px",
      alignSelf: "flex-start",
    },
    cardFlag: {
      width: "60px",
      height: "40px",
      borderRadius: "8px",
    },
    cardHeaderTitle: {
      color: "#21C284",
      fontSize: "12px",
      fontWeight: "bold",
    },
    cardBodyContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "5px",
      fontSize: "10px",
      margin: "10px",
      marginTop: "15px",
      width: "100%",
    },
    cardItemContainer: {
      position: "relative",
      borderRadius: "10px",
      margin: "5px",
      // backgroundColor: '#fff',
      width: "47%",
      minHeight: "80px",
      lineHeight: 1.7,
    },
    cardItemBody: {
      width: "100%",
      height: "100%",

      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      textAlign: "right",
      gap: "10px",
      padding: "10px",
      fontSize: "12px",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      backgroundColor: "#fff",
    },
    organizationLogo: {
      width: "80px",
      height: "40px",
      border: "1px solid #7C32C9",
      alignSelf: "flex-end",
    },
  };

  return (
    <div style={pageStyles.page}>
      <div style={styles.mainSectionTitle}>
        <span style={styles.sectionTitleNumber}>4.1</span>
        <span style={{ paddingRight: "10px" }}>
          تطبيقات التقنيات الناشئة عالميا في قطاع {agencySector}
        </span>
      </div>

      {successStories?.length > 0 &&
        successStories.map((data, index) => (
          <StatusCard
            key={index}
            data={data}
            pageStyles={pageStyles}
            styles={styles}
          />
        ))}
    </div>
  );
};

const StatusCard = ({ data, pageStyles, styles }) => (
  <div style={pageStyles.cardContainer}>
    <div style={pageStyles.cardHeaderContainer}>
      <div style={pageStyles.cardFlag}>
        {data?.country && (
          <img
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            src={`/flags/${data?.country?.toLowerCase()}.png`}
            alt={`${data?.country} flag`}
          />
        )}
      </div>
      <div style={pageStyles.cardHeaderTitle}>
        <span>{data?.projectName}</span>
      </div>
    </div>

    <div style={pageStyles.cardBodyContainer}>
      <div className="cardItemContainer" style={pageStyles.cardItemContainer}>
        <div style={styles.boxShadow}></div>

        <div style={pageStyles.cardItemBody}>
          <div style={{ fontWeight: "bold" }}>
            <span>{data?.organizationName}</span>
          </div>
          <div style={pageStyles.organizationLogo}>
            {data?.attachmentUrl && (
              <img
                style={{ width: "100%", height: "100%" }}
                src={data?.attachmentUrl?.replace(
                  "application/octet-stream",
                  "image/png"
                )}
                alt="Organization logo"
              />
            )}
          </div>
        </div>
      </div>

      <div className="cardItemContainer" style={pageStyles.cardItemContainer}>
        <div style={styles.boxShadow}></div>

        <div style={pageStyles.cardItemBody}>
          <div style={{ fontWeight: "bold" }}>
            <span>التقنية</span>
          </div>
          <div>
            <span>{data?.organizationTechnology}</span>
          </div>
        </div>
      </div>

      <div className="cardItemContainer" style={pageStyles.cardItemContainer}>
        <div style={styles.boxShadow}></div>

        <div style={pageStyles.cardItemBody}>
          <div style={{ fontWeight: "bold" }}>
            <span>الأثر</span>
          </div>
          <div>
            <span>{data?.organizationImpact}</span>
          </div>
        </div>
      </div>

      <div className="cardItemContainer" style={pageStyles.cardItemContainer}>
        <div style={styles.boxShadow}></div>

        <div style={pageStyles.cardItemBody}>
          {" "}
          <div style={{ fontWeight: "bold" }}>
            <span>التطبيق</span>
          </div>
          <div>
            <span>{data?.organizationApplication}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
