import { getBgColorOrDefault } from "../../../features/utils";
import { NotesRecommendationTextArea } from "./componentes/NotesRecommendationTextArea";

export default function Page22Interlocutors({
  pageNumber,
  sectionNumber,
  pageTitle,
  agencyTitle,
  sectorTitle,
  agencyLevel,
  agencyPercentage = "80%",
  level,
  levelColor,
  percentage = 0,
  property,
  standardData,
  summary,
  elementsCode,
  elementsTitle,
  elementsSubject,
  interlocutorExpertNotes,
  reportType,
}) {
  return (
    <div
      className="p-5 font-diodrum-arabic rtl relative"
      style={{
        pageBreakInside: "avoid",
      }}
    >
      <div className=" mr-2">
        {/* Header */}
        <div className="flex flex-row items-center gap-1 text-lg mt-7 mb-7 font-bold">
          <span className="text-[#7C32C9]">{sectionNumber}</span>
          <span className="text-[#3F3578]">{pageTitle}</span>
        </div>

        {/* Main Content */}
        <div className="mt-2">
          {/* Elements Section */}
          <div className="text-right leading-[2.1]">
            <div className="flex flex-row items-center gap-1 font-bold mb-3">
              <span className="text-[#8341C9] text-base">عناصر قدرة</span>
              <span className="text-[#8341C9] text-base">"{property}"</span>
            </div>

            <div className="flex flex-row items-center gap-1 font-semibold ">
              <span className="text-[#3F3578] text-sm">{elementsCode}</span>
              <span className="text-[#3F3578] text-sm">{elementsTitle}</span>
            </div>

            <div className="p-2.5 flex flex-row items-center justify-between gap-4 border-2 border-[#7C32C9] rounded-lg leading-[2.5] mt-5 ">
              <p className="text-right text-xs text-[#3F3578] break-words">
                {elementsSubject}
              </p>
              <div className="flex flex-col items-center justify-center gap-1 text-[11px] w-[15%] text-center">
                <span className="text-[#C00000]">{level}</span>
                <div
                  className="flex items-center justify-center rounded-lg w-[90px] bg-[#8341C9] border-2 font-bold"
                  style={{
                    backgroundColor: getBgColorOrDefault(levelColor),
                    borderColor: getBgColorOrDefault(levelColor),
                    paddingBottom: '3px',
                  }}
                >
                  <span className="text-white ">{percentage}%</span>
                </div>
              </div>
            </div>
          </div>

          {/* Standard Results */}
          <div className="mt-5 relative w-full">
            <h2 className="text-right text-green-500 mb-4 text-base text-[#1DC183]">
              نتائج المعايير الفرعية
            </h2>
            <div className="flex flex-wrap justify-start gap-2 mt-3">
              {standardData?.map((data, index) => (
                <div
                  key={index}
                  className="w-[49%] min-h-[60px] flex flex-row items-center border-2 rounded-lg border-[#8341C9]"
                  // style={{ borderColor: data.commitmentLevelColor || "#2A206A" }}
                >
                  <div
                    className="flex gap-1 flex-col justify-center text-white w-24 rounded-md h-full font-semibold text-center"
                    style={{
                      backgroundColor: getBgColorOrDefault(data.commitmentLevelColor) || "#2A206A",
                      borderColor: getBgColorOrDefault(data.commitmentLevelColor) || "#2A206A",
                      paddingBottom: '5px'
                    }}
                  >
                    <span>
                      {Number(data.userPercentagesAvg || 0)?.toFixed(2)}%
                    </span>
                    <span className="text-xs pb-2">{data.commitmentLevelTitle}</span>
                  </div>
                  <div className="text-sm text-[#3F3578] p-2 flex flex-wrap items-center ">
                    {data.standardCode} {data.standardTitle}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Expert Notes */}
          <div className="mt-5">
            <NotesRecommendationTextArea
              expertNotes={interlocutorExpertNotes}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
