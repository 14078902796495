import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import { previousSurveysBreadcumbs } from "./contents";
import { programsIcon, settingGreen } from "../../../../../assets/icons/Index";
import FinalReportPage from "./FinalReportTabs/FinalReportPage";
import FinalReportSettingPage from "./FinalReportTabs/FinalReportSettingPage";
import { useState } from "react";
import { isAdminFunc } from "../../../../../helpers/isPermission";
import SuccessStories from "./FinalReportTabs/SuccessStories";
import SuggestedActivities from "./FinalReportTabs/SuggestedActivities";

const PreviousSurveys = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      icon: programsIcon,
      title: "التقرير النهائي",
      table: "finalReport",
      content: <FinalReportPage />,
    },
    {
      icon: settingGreen,
      title: "الإعدادات",
      table: "finalReportSetting",
      content: <FinalReportSettingPage />,
    },
    {
      title: "قصص نجاح عالمية",
      table: "SuccessStories",
      content: <SuccessStories />,
    },
    {
      title: "الأنشطة المقترحة",
      table: "SuggestedActivities",
      content: <SuggestedActivities />,
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={previousSurveysBreadcumbs}>
      {isAdminFunc() ? (
        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  "mr-5 cursor-pointer flex flex-row items-center justify-center px-2 min-w-[200px] h-12 rounded-t-[20px] " +
                  (activeTab === index
                    ? "bg-emerald-500 text-white"
                    : "text-slate-400 bg-slate-50")
                }
                onClick={() => setActiveTab(index)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={"24px"}
                  height={"24px"}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>{activeTab === index && tab.content}</div>
            ))}
          </div>
        </div>
      ) : (
        <FinalReportPage />
      )}
    </HeaderWithBreadcrumbLayout>
  );
};

export default PreviousSurveys;
