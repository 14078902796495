import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  // Parse the current query string into a URLSearchParams object
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Function to get a query parameter
  const getParam = (key) => searchParams.get(key);

  // Function to set or update a query parameter
  const setParam = (key, value) => {
    searchParams.set(key, value); // Add or update the key-value pair
    history.push({ search: searchParams.toString() }); // Update the URL
  };

  // Function to remove a query parameter
  const removeParam = (key) => {
    searchParams.delete(key); // Remove the key
    history.push({ search: searchParams.toString() }); // Update the URL
  };

  return { getParam, setParam, removeParam };
};

export default useQueryParams;